'use strict';

import _firebase    			from './_firebase.js';
import dataset      			from './dataset.js';
import helper       			from './helper.js';
import project     				from './project.js';

import lzstring     			from 'lz-string';
import { Response } 			from 'node-fetch';

import { getDownloadURL, ref }	from "firebase/storage";

import S3 						from 'aws-sdk/clients/s3.js';
import GreengrassClient 		from 'aws-sdk/clients/greengrass.js';
import LookoutVisionClient 		from 'aws-sdk/clients/lookoutvision.js';

const aws = {

	config: {},

	init: async function() {
		try {
			const awsConfig = await project.getSettings("aws");
			if (!awsConfig) {
				throw new Error("aws init failed");
			}

			this.config = awsConfig;
			const credentials = { accessKeyId: awsConfig.accessKeyId, secretAccessKey: awsConfig.secretAccessKey };
	
			const s3Config = {
				region: awsConfig.region,
				credentials,
				s3Uri: "s3://" + awsConfig.bucket + "/projects/" + awsConfig.projectId + "/upload" + "/manual/"
			}
			this.s3 = new S3(s3Config);
	
			const lookoutConfig = {
				region: "us-east-1",
				credentials
			}

			this.lookoutConfig = lookoutConfig;
			this.lookoutvision = new LookoutVisionClient(lookoutConfig);
	
			const greengrassConfig = {
				region: "us-east-1",
				credentials
			}

			this.greengrass = new GreengrassClient(greengrassConfig);
	
			return "aws init success";
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	create: async function(dsData, tagMap = false, uploadImageType = "image/png") {
		let resp = { status: "error", error: false, response: {} };

		if (dsData.id) {
            resp.dataset = dsData.id;

            if (dsData.type == "MULTICLASS" || dsData.type == "MULTILABEL") {
                let awsConfig       = this.config;
                let nowdate         = new Date();
                resp.projectName    = awsConfig.projectId + "-" + dsData.name.toString().replace(/\s+/g, '_') + "-" + nowdate.getTime();

                let newProject      = await this.createProject(resp.projectName);

                if (!newProject.response.ProjectMetadata) resp.error = "failed to create project"; 

				else {
                    console.log('Project ' + resp.projectName + ' created!');

                    let dsAws = dsData.aws ? dsData.aws : [];
                    dsAws.push(resp.projectName);
                    await dataset.update(resp.dataset, { aws: dsAws });

					const r = await this.uploadS3(dsData, tagMap, resp.projectName, false, uploadImageType);
                    resp.status = "success";

					console.log("-----> Upload S3 response", r);
                    console.log('-----> End Upload to S3');

                    if (!r.error) {
                        let newDatasetTest = await this.createDataset("test", resp.projectName, r.manifest.test);

                        if (newDatasetTest.error) {
                            resp.error = newDatasetTest.error;
                            resp.from = "createDataset test";
                        } else {
                            console.log("-----> End createDataset test")

                            resp.response.test = newDatasetTest.response;
                            let newDatasetTrain = await this.createDataset("train", resp.projectName, r.manifest.train);

                            if (newDatasetTrain.error) {
                                resp.error = newDatasetTrain.error
                                resp.from = "createDataset train";
                            } else {
                                console.log("-----> End createDataset train")
                                resp.response.train = newDatasetTrain.response;
                                resp.status = "success";
                            }
                        }
                    } else { resp.from = "then uploadS3 have error"; resp.error = r.error; resp.r = r; }
                }
            } else { resp.error = "only for classification type datasets, is " + dsData.type; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
	},

	getBucketCors: async function() {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket }
			this.s3.getBucketCors({ Bucket: resp.bucket }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data.CORSRules
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getBucketAcl: async function() {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket }
			this.s3.getBucketAcl({ Bucket: resp.bucket }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getBucketObjects: async function() {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket }
			this.s3.listObjects({ Bucket: resp.bucket }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getObject: async function(key, renderHtml = false) {
		let p = new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket, key: key } //, render: "TEST"
			if (key) {
				this.s3.getObject({ Bucket: resp.bucket, Key: key }, async function (err, data) {
					if (!err) {
						resp.status = "success"
						resp.response = data
						resp.extension = key.toString().split('.').pop()
						resolve(resp)
					}
					if (err) { //si no existe en el bucket principal del proyecto, miramos si lo ha creado en el secundario
						console.log('miramos en el otro bucket' + this.config.bucket2)
						resp.error = err;
						this.s3.getObject({ Bucket: this.config.bucket2, Key: key }, async function (err, data) {
							if (!err) {
								resp.status = "success"
								resp.response = data
								resp.extension = key.toString().split('.').pop()
								resolve(resp)
							}
							resp.error = err; reject
							resolve(resp)
						})
					}
					resp.error = err;
					//reject
					//resolve(resp)
				})
			} else {
				resp.error = "key object is required"; reject
				resolve(resp)
			}
		})
		let obj = await p
		if (renderHtml && obj.response && obj.response.Body) {
			let acceptedRender = ['jpg', 'gif', 'png', 'bmp'];
			if (obj.extension && acceptedRender.includes(obj.extension.toString().toLowerCase())) {
				let res = new Response(obj.response.Body)
				let buffer = await res.arrayBuffer()
				obj.renderSrc = "data:image/png;base64," + Buffer.from(buffer, 'binary').toString('base64')
				if (renderHtml == '64') obj.render = obj.renderSrc
				else obj.render = '<img width="250" height="250" src="' + obj.renderSrc + '" />'
			}
			delete obj.response.Body
		}
		return obj
	},

	getObjectAttributes: async function(key) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket, key: key }
			if (key) {
				this.s3.getObjectAttributes({ Bucket: resp.bucket, Key: key, ObjectAttributes: ["ETag", "ObjectSize", "Checksum", "StorageClass", "ObjectParts"] }, function (err, data) {
					if (!err) {
						resp.status = "success"
						resp.response = data
						resolve(resp)
					}
					resp.error = err; reject
					resolve(resp)
				})
			} else {
				resp.error = "key object is required"; reject
				resolve(resp)
			}
		})
	},

	deleteObject: async function(objKey) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, bucket: this.config.bucket }
			if (objKey) {
				resp.objKey = objKey
				let delParams = {
					Bucket: resp.bucket,
					Key: objKey,
				};
				this.s3.deleteObject(delParams, function (err, data) {
					if (!err) {
						resp.status = "success"
						resp.response = data
						resolve(resp)
					}
					resp.error = err; reject
					resolve(resp)
				})
			} else { resp.error = "object Key is required"; resolve(resp) }
		})
	},

	dataURLtoBlob: function(dataURL) {
		let byteString = atob(dataURL.split(',')[1]);
		let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
		let arrayBuffer = new ArrayBuffer(byteString.length);
		let uint8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) { uint8Array[i] = byteString.charCodeAt(i); }
		return new Blob([arrayBuffer], { type: mimeString });
	},

	compressImage: async function(imageUrl, quality = 0.8, mimeType = 'image/jpeg', reSize = false) {
		const img 		= await this.loadCompressImage(imageUrl);
		const canvas 	= document.createElement('canvas');
		canvas.width 	= img.width;
		canvas.height 	= img.height;

		if (reSize) {
			canvas.width 	= reSize.width;
			canvas.height 	= reSize.height;
		}

		const ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0, img.width, img.height);

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						reject(new Error('Image compression failed.'));
					}
					resolve(blob);
				},
				mimeType,
				quality
			);
		});
	},

	loadCompressImage: async function(url) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.crossOrigin = 'Anonymous';
			img.onload = () => resolve(img);
			img.onerror = () => reject(new Error('Failed to load image.'));
			img.src = url;
		});
	},


	//------- UPLOAD AWS SECTION -------//

	uploadS3: async function(dsData, tagMap = false, projectName = false, unifySize = false, uploadImageType = "image/png") {
		let resp 	= { status: "error", error: false, bucket: this.config.bucket, dataset: dsData.id };
		let images 	= await dataset.getImages(dsData);
	
		const batchSize = 20;
	
		if (tagMap) resp.tagMap = tagMap;
	
		if (images.media && images.media.length) {
			console.log('-----> Init Upload to S3, dataset images for check:', images.media.length);
	
			let nowdate 	= new Date();
			let configS3 	= this.config;
			let _dataset 	= dsData;
			let datasetTags = await dataset.getTags(dsData.id);
	
			resp.projectName 		= configS3.projectId;
			resp.datasetPathName 	= projectName ? projectName : _dataset.name.toString() + "_" + nowdate.getTime();
			resp.path 				= "projects/" + resp.projectName + '/datasets/' + resp.datasetPathName;
			resp.uploadedFiles 		= { count: 0, normal: 0, anomaly: 0, files: [] };
			resp.manifest 			= { test: "", train: "" };
	
			const processBatch = async (batch) => {
				await Promise.all(batch.map(async (image) => {
					let uploadFile 			= true;
					let excludeUnclassified = false;
					let tagId 				= image.tag ? image.tag.toString().split('/').pop() : false;
					let imagePath 			= resp.path;
					let tagName 			= datasetTags[tagId].name.toString().toUpperCase();
	
					if (!datasetTags[tagId].unclassified) 	excludeUnclassified = true;
					if (_dataset.type == 'MULTILABEL') 		excludeUnclassified = true;
	
					if (tagId && datasetTags[tagId] && excludeUnclassified) {
						if (resp.tagMap && image.tag) {
							let notIncluded = true;

							if (resp.tagMap.normal.includes(tagName)) 	{ tagName = "normal"; resp.uploadedFiles.normal++; notIncluded = false; }
							if (resp.tagMap.anomaly.includes(tagName)) 	{ tagName = "anomaly"; resp.uploadedFiles.anomaly++; notIncluded = false; }

							imagePath += '/' + tagName;

							if (notIncluded) { uploadFile = false; }
						} else { uploadFile = false; }
					} else { uploadFile = false; }
	
					if (uploadFile) {
						try {
							let imgRef 		= await getDownloadURL(ref(_firebase.storage, image.uri));
							let fileKey 	= imagePath + "/" + image.uri.split("/").pop();
							let fileKeyExt 	= fileKey.split('.').pop();
	
							if (!fileKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i)) fileKey += '.png';
	
							if (!unifySize) {
								const firstImageSize = await this.loadCompressImage(imgRef);
								unifySize = { width: firstImageSize.width, height: firstImageSize.height };
							}
	
							resp.uploadedFiles.files.push(fileKey);
	
							const compressedImage = await this.compressImage(imgRef, 0.8, uploadImageType, unifySize);
							let bucketParams = {
								Bucket:	resp.bucket,
								Key: 	fileKey,
								Body: 	compressedImage,
							};
	
							let requestPut = this.s3.putObject(bucketParams);
							await requestPut.promise();
	
							resp.uploadedFiles.count++;
							let imgSet = "test";
							if (image.set == "TRAIN") imgSet = "train";
							if (!image.set || image.set.toString().toLowerCase() == "predetermined") imgSet = "train";
	
							let mJSON = '"source-ref" : "s3://' + this.config.bucket + "/" + fileKey + '",';
							mJSON += '"anomaly-label": ' + (tagName == "anomaly" ? '1' : '0') + ',';
							mJSON += '"anomaly-label-metadata": {"job-name": "labeling-job/classification-job", "class-name": "' + tagName + '", "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/image-classification" }';
	
							if (tagName == "anomaly" && image.mask && image.mask.imageJson && tagMap.useMask) {
								let defectName	= tagId.toString();
								let defectColor = image.mask.color || helper.StringtoHex(defectName);
	
								if (tagMap.anomalyLabel) {
									if (Object.keys(tagMap.anomalyLabel).length) {
										for (let key in tagMap.anomalyLabel) {
											if (tagMap.anomalyLabel[key]) {
												if (tagMap.anomalyLabel[key].includes(tagId.toString().toUpperCase())) {
													defectName = key.toString();
													defectColor = helper.StringtoHex(key);
												}
											}
										}
									}
								}
	
								let maskPathKey		= resp.path + "/mask/" + image.uri.split("/").pop().replace('.jpg', '.png');
								maskPathKey 		= maskPathKey.replace('.bmp', '.png');
								let maskPathKeyExt 	= maskPathKey.split('.').pop();
								
								if (!maskPathKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i)) maskPathKey += '.png';
	
								let multiLabelTagList = false;
								if (_dataset && _dataset.type == 'MULTILABEL') {
									let saveMaskJson = false;
									try {
										saveMaskJson = JSON.parse(image.mask.imageJson);
									} catch (error) {
										saveMaskJson = JSON.parse(lzstring.decompressFromUint8Array(image.mask.imageJson.toUint8Array()));
									}
									multiLabelTagList = saveMaskJson.objects.reduce((obj, item) => { obj[item.name] = item.stroke; return obj; }, {});
									defectColor = multiLabelTagList;
								}
	
								await this.uploadMaskS3(image.mask, resp.bucket, maskPathKey, defectColor, _dataset, unifySize);
	
								mJSON += ',"anomaly-mask-ref" : "s3://' + this.config.bucket + "/" + maskPathKey + '",';
	
								let internalColorMap = '{ "0": {"class-name": "BACKGROUND", "hex-color": "#ffffff", "confidence": 0.0 }';
	
								if (multiLabelTagList && Object.keys(multiLabelTagList).length) {
									let _i = 1;
									for (let key in multiLabelTagList) {
										if (multiLabelTagList[key]) {
											internalColorMap += ',"' + _i + '": {"class-name": "' + key.toString().toUpperCase() + '", "hex-color": "' + multiLabelTagList[key] + '", "confidence": 0.0 }';
											_i++;
										}
									}
								} else {
									internalColorMap += ',"1": {"class-name": "' + defectName + '", "hex-color": "' + defectColor + '", "confidence": 0.0 }';
								}
	
								internalColorMap += ' }';
								mJSON += '"anomaly-mask-ref-metadata": { "job-name": "labeling-job/segmentation-job", "internal-color-map": ' + internalColorMap + ', "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/semantic-segmentation" }';
	
								//console.log('Action ----> Mask Added: ' + image.id);
							}
	
							resp.manifest[imgSet] += '{ ' + mJSON + ' }\n';

							//console.log('Action --------> Image Added: ' + fileKey + ' to set ' + imgSet + ' (' + resp.uploadedFiles.count + ')');
						} catch (error) {
							console.log('-----------------------> Error Put Image: ' + error);
						}
					}
				}));
			};
	
			for (let i = 0; i < images.media.length; i += batchSize) {
				const batch = images.media.slice(i, i + batchSize);
				await processBatch(batch);
				console.log('-----> Upload to S3, dataset images processed:', i + batchSize);
			}
	
			resp.status = "success";
			resp.manifest = await this.createManifest(resp);
			return resp;
		} else {
			resp.error = "The dataset " + resp.dataset + " does not have images";
			return resp;
		}
	},

	uploadMaskS3: async function(mask, bucket, fileKey, color = false, dataset = false, reSize = false) {
		let canvasElement = document.createElement('canvas');

		if (reSize) {
			mask.width = reSize.width
			mask.height = reSize.height
		}

		canvasElement.setAttribute('id', 'canvas');
		canvasElement.setAttribute('width', mask.width);
		canvasElement.setAttribute('height', mask.height);

		let saveCanvas = new fabric.Canvas(canvasElement, {});
		var saveJson = false;

		try {
			saveJson = JSON.parse(mask.imageJson)
		} catch (error) {
			saveJson = JSON.parse(lzstring.decompressFromUint8Array(mask.imageJson.toUint8Array()));
		}

		if (dataset && dataset.type == 'MULTILABEL') {
			saveCanvas.loadFromJSON(saveJson,
				function () {
					saveCanvas.setWidth(mask.width);
					saveCanvas.setHeight(mask.height);
					saveCanvas.backgroundImage = null;
					saveCanvas.backgroundColor = '#FFFFFF';
					saveCanvas.forEachObject(function (object) {
						if (object.type === 'image' && object.backgroundImage) object.backgroundImage = null;
						object.set({ shadow: null });
					});
					saveCanvas.renderAll();
				},
				function (o, object) {
					object.shadow = null
					saveCanvas.backgroundColor = '#FFFFFF';
					saveCanvas.renderAll();
				});

			setTimeout(() => {
				let colorValues = Object.values(color);
				let tagRGBs = colorValues.map(_color => helper.getRgbValues(helper.hexToRgb(_color)));
				let ctx = canvasElement.getContext('2d');
				let pixelData = ctx.getImageData(0, 0, mask.width, mask.height).data;
				for (let i = 0; i < pixelData.length; i += 4) {
					let currentColor = pixelData.slice(i, i + 3).join(',');
					if (!tagRGBs.includes(currentColor)) {
						pixelData[i] = 255;
						pixelData[i + 1] = 255;
						pixelData[i + 2] = 255;
						pixelData[i + 3] = 255;
					}
				}
				ctx.putImageData(new ImageData(pixelData, mask.width, mask.height), 0, 0);
				let canvasURL = canvasElement.toDataURL({ format: 'png', quality: 1 });
				canvasElement.remove();
				let bucketParams = {
					Bucket: bucket,
					Key: fileKey,
					Body: this.dataURLtoBlob(canvasURL)
				};
				this.s3.putObject(bucketParams, function (err) { if (err) console.log('save mask error', err) })
			}, 500);

		} else {
			saveCanvas.loadFromJSON(saveJson,
				function () {
					let maskColor = color ? color : mask.color
					saveCanvas.setWidth(mask.width);
					saveCanvas.setHeight(mask.height);
					saveCanvas.backgroundImage = null;
					saveCanvas.backgroundColor = '#FFFFFF';
					saveCanvas.forEachObject(function (object) {
						if (object.type === 'image' && object.backgroundImage) object.backgroundImage = null;
						object.set({ stroke: maskColor, color: maskColor, shadow: null });
					});
					saveCanvas.renderAll();
				},
				function (o, object) {
					let maskColor = color ? color : mask.color
					object.shadow = null
					object.set({ stroke: maskColor, color: maskColor, shadow: null });
					saveCanvas.backgroundColor = '#FFFFFF';
					saveCanvas.renderAll();
				});

			setTimeout(() => {
				let tagRGB = helper.getRgbValues(helper.hexToRgb(color));
				let ctx = canvasElement.getContext('2d');
				let pixelData = ctx.getImageData(0, 0, mask.width, mask.height).data;
				for (let i = 0; i < pixelData.length; i += 4) {
					let color = pixelData.slice(i, i + 3).join(',');
					if (color != "255,255,255" && color != tagRGB) {
						pixelData[i] = 255;
						pixelData[i + 1] = 255;
						pixelData[i + 2] = 255;
						pixelData[i + 3] = 255;
					}
				}
				ctx.putImageData(new ImageData(pixelData, mask.width, mask.height), 0, 0);
				let canvasURL = canvasElement.toDataURL({ format: 'png', quality: 1 });
				canvasElement.remove();
				let bucketParams = {
					Bucket: bucket,
					Key: fileKey,
					Body: this.dataURLtoBlob(canvasURL)
				};
				this.s3.putObject(bucketParams, function (err) { if (err) console.log('save mask error', err) })
			}, 500);
		}
	},


	//------- MANIFEST SECTION -------//

	generateClassificationManifest: async function(resp, images) {
		await Promise.all(images.map(async (img) => {
			let tagId 	= false;
			let imgSet	= img.set && img.set.toString().toLowerCase() == "train" ? "train" : "test";

			if (img.tag) {
				if (typeof img.tag === 'string') { tagId = img.tag.split('/').pop(); } 
				else { tagId = img.tag.id; }
			}

			if (tagId !== '0') {
				try {
					let fileKey		= img.name;
					let fileKeyExt	= fileKey.split('.').pop();
	
					if (!fileKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i)) fileKey += '.png';
	
					let parts = fileKey.split('/');
					fileKey = parts.pop();
	
					let mJSON = {
						"imageGcsUri" : tagId + "/" + fileKey,
						"classificationAnnotation": {
							"displayName": tagId,
						},
						"dataItemResourceLabels": {
							"aiplatform.googleapis.com/ml_use": imgSet
						}
					};

					resp.manifest[imgSet] += JSON.stringify(mJSON) + '\n';
					return resp;
				} catch (error) { console.log('-----------------------> Error Put Image: ' + error); }
			}
		}));
	},

	generateSegmentationManifest: async function(resp, images, dsData, nowdate) {
		await Promise.all(images.map(async (img) => {
			let tagId = false;

			if (img.tag) {
				if (typeof img.tag === 'string') { tagId = img.tag.split('/').pop(); } 
				else { tagId = img.tag.id; }
			}

			let tagName = dsData.tags[tagId].name.toString().toLowerCase();

			try {
				let fileKey		= img.name;
				let fileKeyExt	= fileKey.split('.').pop();

				if (!fileKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i)) fileKey += '.png';

				let parts = fileKey.split('/');
				fileKey = parts.pop();

				let imgSet = img.set && img.set.toString().toLowerCase() == "train" ? "train" : "test";

				let mJSON = {
					"source-ref" : tagId + "/" + fileKey,
					"anomaly-label": (tagName == "anomaly" ? '1' : '0'),
					"anomaly-label-metadata": {
						"class-name": tagName,
						"job-name": "labeling-job/classification-job",
						"type": "groundtruth/image-classification",
						"human-annotated": "yes",
						"creation-date": nowdate.toISOString()
					}
				};

				if (tagName == "anomaly") {
					mJSON["anomaly-mask-objects"] = {};
					let fileName = fileKey.split('.')[0];
					let fileExtension = fileKey.split('.')[1];

					if (img.type === 'masks' && img.data.objects) {
						img.data.objects.forEach((mask) => {
							let internalColorMap = {
								"0": {
									"class-name": "BACKGROUND",
									"hex-color": "#ffffff",
									"confidence": 0.0
								}
							};

							mask.name = mask.name == '0' ? 'Anomaly' : mask.name;

							internalColorMap["1"] = {
								"class-name": 	mask.name,
								"hex-color": 	mask.stroke,
								"confidence":	0.0
							};

							let maskId = "anomaly-mask-" + mask.name + "-metadata";
							mJSON["anomaly-mask-objects"][maskId] = {
								"source-ref": tagId + "/masks/" + fileName + "-" + mask.name + "." + fileExtension,
								"internal-color-map": internalColorMap,
								"job-name": "labeling-job/segmentation-job",
								"type": "groundtruth/semantic-segmentation",
								"human-annotated": "yes",
								"creation-date": nowdate.toISOString(),
							}
						});
					}
					
					else if (img.type === 'mask' && img.data.imageJson) {
						let defectName 	= tagId.toString();
						let defectColor = img.data.color;

						let multiLabelTagList = false;
						if (dsData && dsData.type == 'MULTILABEL') {
							let saveMaskJson = false;
							try {
								saveMaskJson = JSON.parse(img.data.imageJson);
							} catch (error) {
								saveMaskJson = JSON.parse(lzstring.decompressFromUint8Array(img.data.imageJson.toUint8Array()));
							}
							multiLabelTagList = saveMaskJson.objects.reduce((obj, item) => { obj[item.name] = item.stroke; return obj; }, {});
							defectColor = multiLabelTagList;
						}

						let internalColorMap = {
							"0": {
								"class-name": "BACKGROUND",
								"hex-color": "#ffffff",
								"confidence": 0.0
							}
						};

						if (multiLabelTagList && Object.keys(multiLabelTagList).length) {
							let tagIndex = 1;
							for (let key in multiLabelTagList) {
								if (multiLabelTagList[key]) {
									internalColorMap[tagIndex.toString()] = {
										"class-name": key.toString().toUpperCase(),
										"hex-color": multiLabelTagList[key],
										"confidence": 0.0
									};
									tagIndex++;
								}
							}
						} else {
							internalColorMap["1"] = {
								"class-name": defectName,
								"hex-color": defectColor,
								"confidence": 0.0
							};
						}

						mJSON["anomaly-mask-objects"]["anomaly-mask-metadata"] = {
							"source-ref": tagId + "/masks/" + fileKey,
							"internal-color-map": internalColorMap,
							"job-name": "labeling-job/segmentation-job",
							"type": "groundtruth/semantic-segmentation",
							"human-annotated": "yes",
							"creation-date": nowdate.toISOString(),
						};
					}
				}

				resp.manifest[imgSet] += JSON.stringify(mJSON) + '\n';
				return resp;
			} catch (error) { console.log('-----------------------> Error Put Image: ' + error); }
		}));
	},

	generateDetectionManifest: async function(resp, images) {
		await Promise.all(images.map(async (img) => {
			const tags = img.data;

			if (tags) {
				let tagId 	= false;
				let imgSet	= img.set && img.set.toString().toLowerCase() == "train" ? "train" : "test";

				if (img.tag) {
					if (typeof img.tag === 'string') { tagId = img.tag.split('/').pop(); } 
					else { tagId = img.tag.id; }
				}

				try {
					let fileKey		= img.name;
					let fileKeyExt	= fileKey.split('.').pop();
	
					if (!fileKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i)) fileKey += '.png';
	
					let parts = fileKey.split('/');
					fileKey = parts.pop();
	
					let mJSON = {
						"imageGcsUri" : tagId + "/" + fileKey,
						"boundingBoxAnnotations": [],
						"dataItemResourceLabels": {
							"aiplatform.googleapis.com/ml_use": imgSet
						}
					};

					tags.forEach((tag) => {
						mJSON['boundingBoxAnnotations'].push({
							"displayName": tag.tag.id,
							"xMin":	tag.x,
							"yMin":	tag.y,
							"xMax":	tag.x + tag.w,
							"yMax":	tag.y + tag.h,
						})
					});
	
					resp.manifest[imgSet] += JSON.stringify(mJSON) + '\n';
					return resp;
				} catch (error) { console.log('-----------------------> Error Put Image: ' + error); }
			}
		}));
	},

	//New version
	generateManifest: async function(dsData, images) {
		let resp = { status: "error", error: false, bucket: this.config.bucket, dataset: dsData.id, manifest: { test: "", train: "" } };

		if (images.length) {
			let nowdate 	= new Date();
			let configS3 	= this.config;

			resp.projectName 		= configS3.projectId;
			resp.datasetPathName	= dsData.name.toString() + "_" + nowdate.getTime();
			resp.path 				= "projects/" + resp.projectName + '/datasets/' + resp.datasetPathName;

			try {
				switch (dsData.type) {
					case 'MULTICLASS':
						await this.generateClassificationManifest(resp, images);
						break;
		
					case 'MULTILABEL':
						await this.generateSegmentationManifest(resp, images, dsData, nowdate);
						break;
		
					case 'imageObjectDetection':
						await this.generateDetectionManifest(resp, images);
						break;
					
					default:
						console.log("Wrong dataset type");
						break;
				}

				resp.status = "success";
			} catch (error) {
				console.log('-----------------------> Error Put Image: ' + error);
			}

			return resp.manifest;
		} else { resp.error = "The dataset " + resp.dataset + " does not have images"; return resp; }
	},

	//Old version
	createManifest: async function(opt) {
		console.log("Uploading manifest files...");

		return new Promise(async (resolve, reject) => {
			let resp = { status: "success", error: false, manifest: { test: false, train: false } }

			if (opt.manifest) {
				let datasetTypes = ["test", "train"];

				for (let t = 0; t < datasetTypes.length; t++) {
					if (opt.manifest[datasetTypes[t]]) {
						let fileKey = opt.path + '/manifests/' + opt.datasetPathName + "-" + datasetTypes[t] + ".manifest";
						let bucketParams = {
							Bucket:	opt.bucket,
							Key: 	fileKey,
							Body: 	opt.manifest[datasetTypes[t]],
						};
						let requestPut = this.s3.putObject(bucketParams);
						await requestPut.promise();
						resp.manifest[datasetTypes[t]] = fileKey;
					}
				}

				resolve(resp.manifest);
			} else { 
				resp.error = "error";
				resp.error = "manifest content is required";
				reject(resp);
			}
		})
	},

	//----------------------------------//


	createProject: async function(projectName) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false };

			this.lookoutvision.createProject({ ProjectName: projectName }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				} else {
					resp.error = err;
					reject;
					resolve(resp);
				}
			});
		})
	},

	getProject: async function(projectName) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName }
			this.lookoutvision.describeProject({ ProjectName: projectName }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	listProjects: async function() {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false }
			resp.region = this.lookoutConfig.region
			this.lookoutvision.listProjects({}, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	createDataset: async function(datasetType, projectName, manifest = false) {
		return new Promise((resolve, reject) => {
			let resp 			= { status: "error", error: false, bucket: this.config.bucket }
			let datasetParams 	= { DatasetType: datasetType, ProjectName: projectName };

			if (manifest) {
				datasetParams.DatasetSource = {
					GroundTruthManifest: {
						S3Object: {
							Bucket:	resp.bucket,
							Key: 	manifest,
						}
					}
				}
			}

			this.lookoutvision.createDataset(datasetParams, function (err, data) {
				if (!err && err === null) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				} else {
					resp.error = err;
					reject;
					resolve(resp);
				}
			})
		})
	},

	getDataset: async function(projectName) {
		let resp = { status: "error", error: false, projectName: projectName, response: { dataset: {} } }
		let datasetTypes = ["test", "train"]
		for (var t = 0; t < datasetTypes.length; t++) {
			if (datasetTypes[t]) {
				let describe = await this.describeDataset({ DatasetType: datasetTypes[t], ProjectName: projectName })
				if (!describe.error) resp.response.dataset[datasetTypes[t]] = describe.response
				else resp.error = describe.error;
			}
		}
		if (!resp.error) resp.status = "success"
		return resp
	},

	getDatasetEntries: async function(projectName) {
		let resp = { status: "error", error: false, projectName: projectName, response: { counter: { test: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 }, train: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 } } } }
		let datasetTypes = ["test", "train"]
		for (var t = 0; t < datasetTypes.length; t++) {
			if (datasetTypes[t]) {
				let endList = false
				let entries = { normal: [], anomaly: [], nolabeled: [] }
				var lastToken = false
				while (!endList) {
					let qry = { DatasetType: datasetTypes[t], ProjectName: projectName }
					if (lastToken) qry.NextToken = lastToken
					let list = await this.listDatasetEntries(qry)
					if (!list.error) {
						if (list.response.NextToken) lastToken = list.response.NextToken
						else endList = true
						for (let e in list.response.DatasetEntries) {
							let entry = JSON.parse(list.response.DatasetEntries[e])
							if (!("anomaly-label" in entry)) {
								entries.nolabeled.push(entry); resp.response.counter[datasetTypes[t]].nolabeled++
							} else {
								if (entry["anomaly-label"]) {
									entries.anomaly.push(entry); resp.response.counter[datasetTypes[t]].anomaly++
								} else { entries.normal.push(entry); resp.response.counter[datasetTypes[t]].normal++ }
							}
							resp.response.counter[datasetTypes[t]].count++
						}
						resp.response[datasetTypes[t]] = entries
					} else { resp.error = list.error; endList = true }
				}
			}
		}
		if (!resp.error) resp.status = "success"
		return resp
	},

	listDatasetEntries: async function(datasetParams) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false }
			this.lookoutvision.listDatasetEntries(datasetParams, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	describeDataset: async function(datasetParams) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false }
			this.lookoutvision.describeDataset(datasetParams, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getModel: async function(projectName, modelVersion = false) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName, modelVersion: "latest" }
			if (modelVersion) resp.modelVersion = modelVersion
			resp.region = this.lookoutConfig.region
			this.lookoutvision.describeModel({ ProjectName: projectName, ModelVersion: resp.modelVersion }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					if (resp.response.ModelDescription) {
						resp.response.evaluation = {
							resultKey: resp.response.ModelDescription.EvaluationResult ? resp.response.ModelDescription.EvaluationResult.Key : false,
							manifestKey: resp.response.ModelDescription.EvaluationManifest ? resp.response.ModelDescription.EvaluationManifest.Key : false,
						}
					}
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	createModel: async function(projectName) {
		console.log("AWS Creating model for project: " + projectName);

		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName };
			resp.region = this.lookoutConfig.region;

			let model = {
				ProjectName: projectName,
				OutputConfig: {
					S3Location: {
						Bucket: this.config.bucket,
						Prefix: "components/" + projectName.toString().replace(/\s+/g, '_')
					}
				}
			}

			this.lookoutvision.createModel(model, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				} else {
					resp.error = err;
					reject;
					resolve(resp);
				}
			})
		})
	},

	listModels: async function(projectName) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName }
			resp.region = this.lookoutConfig.region
			this.lookoutvision.listModels({ ProjectName: projectName }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resp.response.count = data.Models ? data.Models.length : 0
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getEvaluation: async function(projectName, modelVersion = false) {
		let resp = { status: "error", error: false, projectName: projectName, modelVersion: modelVersion ? modelVersion : "latest" };
		let model = await this.getModel(resp.projectName, resp.modelVersion);

		if (model.response && model.response.evaluation) {
			let resultEvaluation = await this.getObject(model.response.evaluation.resultKey);

			if (!resultEvaluation.response || !resultEvaluation.response.Body) {
				resp.error = "model evaluation results not found"
			}
			
			let manifestEvaluation = await this.getObject(model.response.evaluation.manifestKey)
			
			if (manifestEvaluation.response && manifestEvaluation.response.Body) {
				const res = new Response(manifestEvaluation.response.Body);
				let manifest = await res.text();
				manifest = JSON.parse("[" + manifest.replace(/\n/g, ",").slice(0, -1) + "]");

				if (Object.keys(manifest).length) {
					resp.manifestResume = {
						totalTestImages: 	Object.keys(manifest).length,
						normal:				{ total: 0, trueNegative: 0, falseNegative: 0, predictions: 0 },
						anomaly: 			{ total: 0, truePositive: 0, falsePositive: 0, predictions: 0 },
					}

					resp.manifest = manifest;

					for (let i = 0; i < Object.keys(resp.manifest).length; i++) {
						let objKey = resp.manifest[i]["source-ref"].replace(/s3:/g, "").replace(/\/\//g, "").replace(new RegExp(this.config.bucket + "/", "g"), "");
						resp.manifest[i]["key"] = objKey;

						if (resp.manifest[i]["anomaly-label"]) {
							if (resp.manifest[i]["anomaly-label-detected"]) {
								resp.manifestResume.anomaly.truePositive++;
								resp.manifestResume.anomaly.total++;
							} else {
								resp.manifestResume.normal.falseNegative++;
								resp.manifestResume.normal.total++;
							}
							resp.manifestResume.anomaly.predictions++;
						} 
						
						else {
							if (!resp.manifest[i]["anomaly-label-detected"]) {
								resp.manifestResume.normal.trueNegative++;
								resp.manifestResume.normal.total++;
							} else {
								resp.manifestResume.anomaly.falsePositive++;
								resp.manifestResume.anomaly.total++;
							}
							resp.manifestResume.normal.predictions++;
						}
					}
				}
			}
		} else { resp.error = "model evaluation results not found"; }

		return new Promise((resolve, reject) => {
			if (resp.error) reject;
			else resp.status = "success";
			resolve(resp);
		})
	},

	getModelPackagingJobs: async function(projectName, modelVersion = false) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName }
			resp.region = this.lookoutConfig.region
			let opt = { ProjectName: projectName }
			this.lookoutvision.listModelPackagingJobs(opt, function (err, data) {
				if (modelVersion) {
					let packages = []
					for (var i = 0; i < data.ModelPackagingJobs.length; i++) {
						if (data.ModelPackagingJobs[i].ModelVersion == modelVersion) {
							console.log('model version', data.ModelPackagingJobs[i].ModelVersion)
							packages.push(data.ModelPackagingJobs[i])
						}
					}
					data.ModelPackagingJobs = packages
				}
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	},

	getModelPackagingJob: async function(projectName, jobName) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName, jobName: jobName }
			resp.region = this.lookoutConfig.region
			this.lookoutvision.describeModelPackagingJob({ ProjectName: projectName, JobName: jobName }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
				this.greengrass.listDeployments
			})
		})
	},

	listDeployments: async function(projectName) {
		return new Promise((resolve, reject) => {
			let resp = { status: "error", error: false, projectName: projectName }
			resp.region = this.lookoutConfig.region
			this.greengrass.listDeployments({ GroupId: projectName }, function (err, data) {
				if (!err) {
					resp.status = "success"
					resp.response = data
					resolve(resp)
				}
				resp.error = err; reject
				resolve(resp)
			})
		})
	}
}

export default aws;