"use strict";

import axios from "axios";

import _firebase from "./_firebase.js";

import {
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  deleteDoc,
  Timestamp,
  where,
  updateDoc,
} from "firebase/firestore";

const meta = {
  getEditableFields: async function () {
    const metadataCol = collection(_firebase.firestore, "metadata");
    const editableFields = await getDoc(
      doc(metadataCol, "meta-editable-fields")
    );
    return editableFields.data();
  },

  setEditedFields: async function (fieldsToEdit, id) {
    for (const field of fieldsToEdit) {
      const { name, text, location } = field;
      let newLocation = location + ".";
      let docRef = doc(_firebase.firestore, "configurations", id);

      // Regex para verificar el formato "dd/mm/yyyy, HH:MM:SS"
      const regex = /^\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2}$/;
      let valueToUpdate = text;

      if (regex.test(text)) {
        const [date, time] = text.split(", ");
        const [day, month, year] = date.split("/").map(Number);
        const [hour, minutes, seconds] = time.split(":").map(Number);
        const newDate = new Date(year, month - 1, day, hour, minutes, seconds);
        valueToUpdate = Timestamp.fromDate(newDate);
      }

      if (location === "metadata") {
        docRef = doc(_firebase.firestore, "metadata", id);

        await updateDoc(docRef, {
          [`${name}`]: valueToUpdate,
        });
        return;
      }

      if (location === "Profile") {
        newLocation = "";
      }
      await updateDoc(docRef, {
        [`${newLocation}${name}`]: valueToUpdate,
      });
    }
  },

  setNewFields: async function (newField, id) {
    let { name, text, location, editable, type } = newField;
    let docRef = doc(_firebase.firestore, "metadata", id);
    let newLocation = location + ".";
    if (location === "metadata") {
      newLocation = "";
    } else {
      if (location === "Profile") {
        newLocation = "";
      }
      docRef = doc(_firebase.firestore, "configurations", id);
    }
    if (type === "Date") {
      Timestamp.fromDate(text);
    }
    await updateDoc(doc(_firebase.firestore, "metadata", id), {
      [`${"editableFields."}${name}`]: editable,
    });

    await updateDoc(docRef, {
      [`${newLocation}${name}`]: text,
    });
  },

  getProjectData: async function (projectId) {
    const configurationsCol = collection(_firebase.firestore, "configurations");
    const configurationsQuery = query(
      configurationsCol,
      where("firebaseConfig.projectId", "==", `rosepetal-${projectId}`)
    );
    const project = await getDocs(configurationsQuery);
    const metadataQuery = await getDocs(
      collection(_firebase.firestore, "metadata")
    );
    const metadata = metadataQuery.docs
      .map((doc) => {
        return {
          metadataId: doc.id,
          ...doc.data(),
        };
      })
      .find((doc) => doc.metadataId === projectId);
    return { project, metadata };
  },

  getProjects: async function () {
    const configurations = collection(_firebase.firestore, "configurations");
    const configurationsQuery = query(configurations);
    const configurationsDocs = await getDocs(configurationsQuery);
    return configurationsDocs;
  },

  getMetadata: async function () {
    const metadataDocs = await getDocs(
      collection(_firebase.firestore, "metadata")
    );
    return metadataDocs;
  },

  getPeers: async function () {
    const peersFunctionUrl =
      "https://us-central1-rosepetal-dep.cloudfunctions.net/getPeers";
    const response = await axios.get(peersFunctionUrl);

    let peers = await Promise.all(
      response.data.map(async (peer) => {
        return {
          name: peer.name,
          ip: peer.ip,
          physicalAddress: peer.physicalAddress,
          online: peer.online,
          lastConnection: peer.lastConnection
        };
      })
    );

    peers = peers.filter((peer) => peer.name.includes("rosepetal-"));
    return peers.sort((a, b) => a.name.localeCompare(b.name));
  },

  getPeerIPByName: function (name, peers) {
    const peer = peers.find((peer) => peer.name === name);
    if (peer) {
      return peer;
    } else {
      throw new Error(`Peer with name ${name} not found.`);
    }
  },

  addClient: async function (newClient) {
    const newConfigRef = doc(
      _firebase.firestore,
      "configurations",
      newClient.clientId
    );
    const newMetadataRef = doc(
      _firebase.firestore,
      "metadata",
      newClient.clientId
    );

    await setDoc(newConfigRef, {
      firebaseConfig: newClient.firebaseConfig,
      functions: newClient.functions,
      localhost: newClient.localhost,
      modelBucket: newClient.modelBucket,
      nodeConfig: newClient.nodeConfig,
      zerotier: newClient.zerotier,
    });

    await setDoc(newMetadataRef, {
      active: newClient.active,
      createdAt: Timestamp.now(),
      logo: newClient.logo,
      updatedAt: Timestamp.now(),
    });
  },

  deleteProject: async function (projectId) {
    await deleteDoc(doc(_firebase.firestore, "configurations", projectId));
    await deleteDoc(doc(_firebase.firestore, "metadata", projectId));
  },
};

export default meta;
