'use strict';

import _firebase    from './_firebase.js';
import aws          from './aws.js';
import event        from './event.js';
import helper       from './helper.js';
import image        from './image.js';
import model        from './model.js';
import other        from './other.js';

import JSZip        from 'jszip';
import lzstring     from 'lz-string';
import saveAs       from 'file-saver';
import moment       from 'moment';

import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    getCountFromServer,
    query,
    where,
    orderBy,
    limit,
    startAt,
    startAfter,
    limitToLast,
    endBefore,
    serverTimestamp,
    Timestamp,
    writeBatch,
    or
} from 'firebase/firestore';

import { getFunctions }          from 'firebase/functions';
import { ref, deleteObject, getDownloadURL }    from 'firebase/storage';

const dataset = {

    get: async function(datasetID, opt = {}) {
        let docRef      = doc(_firebase.firestore, 'dataset', datasetID);

        let snapshot    = await getDoc(docRef);
        let dataset     = snapshot.data();

        if (dataset) {
            dataset['id']           = snapshot.id;
            dataset['name']         = dataset.name ? helper.capitalize(dataset.name) : null;
            dataset['createdAt']    = dataset.createdAt ? helper.getTimestampDate(dataset.createdAt.toDate(), 'full') : null;
            dataset['tags']         = await this.getTags(datasetID);

            switch (dataset.type) {
                case "MULTICLASS":              dataset['typeName'] = "Classification";     break;
                case "MULTILABEL":              dataset['typeName'] = "Segmentation";       break;
                case "imageObjectDetection":    dataset['typeName'] = "Object detection";   break;
                default:                        dataset['typeName'] = null;                 break;
            }
        }

		if (opt && dataset && Object.keys(dataset).length) {
            const tasks = [];
            if (opt.models) {
                tasks.push(this.getModels(dataset).then(result => {
                    dataset['models'] = result;
                }));
            }
            if (opt.tagStats) {
                tasks.push(this.getTagStats(dataset).then(result => {
                    dataset['tagStats'] = result;
                }));
            }
            if (opt.tagsCounter) {
                tasks.push(this.getTagStats_o(dataset).then(result => {
                    dataset['tagsCounter'] = result;
                }));
            }
            if (opt.divisionsCounter) {
                tasks.push(this.getDivisionStats(dataset).then(result => {
                    dataset['dataDivision'] = result;
                }));
            }
            await Promise.all(tasks);
        }

        return dataset;
    },

    create: async function(opt) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false }

        opt.name = opt.name.replace(/\s/g, "_").replace(/[^\w\s]/gi, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(0, 32).toLowerCase();

        let saveData = { name: opt.name, type: opt.type, createdAt: serverTimestamp(), description: "" }
        if (opt.description) saveData.description = opt.description;

        let type;
        switch (opt.type) { 
            case "MULTICLASS":              type = "class"; break;
            case "MULTILABEL":              type = "segm"; break;
            case "imageObjectDetection":    type = "obj"; break;
        }

        let docId   = 'rp-' + type + '-' + opt.name;
        let docRef  = doc(db, 'dataset', docId);

        await setDoc(docRef, saveData)
            .then(async function() {
                resp.datasetID  = docId;
                resp.dataset    = opt.name.toString();
                resp.status     = "success";
            }).catch(function(error) { resp.error = error });

        if (resp.status == "success") {
            await event.saveEvent('dataset.create', { dataset: opt.name.toString(), qry: saveData }, false);

            if (opt.type == "MULTILABEL") {
                await this.createTag(docId, { tag: "0", name: "Anomaly", unclassified: true, anomaly: true, color: "#BC2B5F" });
                await this.createTag(docId, { tag: "OK", name: "Normal", normal: true, color: "#2BBC33" });
            } else {
                await this.createTag(docId, { tag: "0", name: "Unclassified", unclassified: true });
            }
        }

        console.log("Status Creation dataset", resp);
        return resp;
    },

    list: async function(opt = {}) {
        let colRef		= collection(_firebase.firestore, 'dataset');
		let queryRef 	= query(colRef);

        if (opt.type) 		queryRef = query(queryRef, where('type', '==', opt.type));
        if (opt.trained)    queryRef = query(queryRef, where('trained', '==', true));
        if (opt.order)      queryRef = query(queryRef, orderBy('createdAt', opt.order));

		const snapshot = await getDocs(queryRef);
		let datasets = snapshot.docs.map(doc => {
			let item = doc.data();
			if (item.deleted || !item.type) return null;

			item.id             = doc.id;
            item.name           = item.name ? helper.capitalize(item.name.toUpperCase()) : null;
			item.createdDate    = item.createdAt ? helper.getTimestampDate(item.createdAt.toDate(), 'full') : null;
			item.updatedDate    = item.updatedAt ? helper.getTimestampDate(item.updatedAt.toDate(), 'full') : null;
			
			switch (item.type) {
				case "MULTICLASS": 				item.typeName = "Classification";   break;
				case "MULTILABEL": 				item.typeName = "Segmentation";     break;
				case "imageObjectDetection": 	item.typeName = "Object detection"; break;
				default: 						item.typeName = false;              break;
			}

			return item;
		}).filter(item => item != null);

        return datasets;
    },

    hide: async function() {
        const colRef = collection(_firebase.firestore, 'dataset');
    
        try {
            const snapshot = await getDocs(colRef);
    
            snapshot.forEach(async (doc) => {
                const data = doc.data();
    
                if (!data.deleted) {
                    console.log("Hiding dataset:", doc.id);

                    await updateDoc(doc.ref, {
                        deleted: true,
                        hidden: true
                    });
                }
            });
        } catch (error) {
            console.error('Error al ocultar documentos:', error);
        }
    },
    
    unhide: async function() {
        const colRef = collection(_firebase.firestore, 'dataset');
    
        try {
            const snapshot = await getDocs(colRef);
    
            snapshot.forEach(async (doc) => {
                const data = doc.data();
    
                if (data.deleted === true && data.hided === true) {
                    console.log("Unhiding dataset:", doc.id);

                    await updateDoc(doc.ref, {
                        deleted: false,
                        hidden: false
                    });
                }
            });
        } catch (error) {
            console.error('Error al desocultar documentos:', error);
        }
    },

    update: async function(datasetID, data) {
        let resp = { status: "error", error: false };

        if (datasetID) {
            data["updatedAt"] = serverTimestamp();
            let docRef = doc(_firebase.firestore, 'dataset', datasetID);

            await setDoc(docRef, data, { merge: true })
                .then(async function() {
                    await event.saveEvent('dataset.update', { dataset: datasetID, data: data }, false);
                    resp.status = "success";
                })
                .catch(async () => { resp.error = "dataset not found" });
        }

        return resp;
    },

    updateCsv: async function(opt = {}) {
        let csvQuery;

        if (opt.dataset.id) {
            csvQuery = 'api/dataset/' + opt.dataset.id + '/csv';

            if (opt.dataDivision && (opt.dataDivision.test || opt.dataDivision.validation)) {
                csvQuery += opt.dataDivision.test ? "?test=" + opt.dataDivision.test : "";
                csvQuery += opt.dataDivision.validation && opt.dataDivision.test ? "&" : "";
                csvQuery += opt.dataDivision.validation ? "validation=" + opt.dataDivision.validation : "";
            }

            if (opt.tagMap) {
                if (opt.dataDivision.test || opt.dataDivision.validation) csvQuery  += '&tagmap=';
                else csvQuery  += '?tagmap=';
                Object.keys(opt.tagMap.tags).forEach(key => {
                    Object.keys(opt.tagMap.tags[key].tags).forEach(k => {
                        if (k != "count") csvQuery += key.replace(/\s/g, "!!-") + "-|-" + k.toString().replace(/\s/g, "!!-") + '--||--';
                    });
                });
            } else {
                csvQuery += '?tagmap=';
                Object.keys(opt.dataset.tags).forEach(key => {
                    csvQuery += key.replace(/\s/g, "!!-") + "-|-" + key.toString().replace(/\s/g, "!!-") + '--||--';
                })
            }

            const csv = await other.httpsCallable(csvQuery);
            if (csv.data && csv.data.training && csv.data.training.dataset) {
                await this.update(opt.datasetID, { importCsv: csv.data.training.dataset });
                csv['url'] = await getDownloadURL(ref(_firebase.storage, csv.data.training.dataset));
                return csv;
            }
        }
    },

    delete: async function(datasetID) {
        const db = _firebase.firestore;
        const storage = _firebase.storage;
        
        const datasetRef = doc(db, 'dataset', datasetID);

        const batch = writeBatch(db);

        /* const imagesSnap = await getDocs(query(collection(db, 'image'), where('dataset', '==', datasetRef)));
        imagesSnap.forEach(docSnap => {
            batch.delete(docSnap.ref);
        }); */

        const imagesQuery = query(collection(db, 'image'), where('dataset', '==', datasetRef));
        const imagesSnap = await getDocs(imagesQuery);

        const deletionPromises = imagesSnap.docs.map(async (imageDoc) => {
            const imageData = imageDoc.data();
            if (imageData.uri) {
                const fileRef = ref(storage, imageData.uri);
                try {
                    await deleteObject(fileRef);
                } catch (error) {
                    console.error("Error eliminando archivo de Storage:", error);
                }
            }
            batch.delete(imageDoc.ref);
        });

        await Promise.all(deletionPromises);

        const modelsSnap = await getDocs(query(collection(db, 'model'), where('dataset', '==', datasetRef)));
        modelsSnap.forEach(docSnap => {
            batch.delete(docSnap.ref);
        });

        batch.delete(datasetRef);

        await batch.commit();
    },

	getModels: async function(dataset) {
		let resp = { status: "error", error: false, count: 0, models: [] };

		const colRef = collection(_firebase.firestore, 'model');
		const docRef = doc(_firebase.firestore, 'dataset', dataset.id);

		let queryRef = query(colRef, where('dataset', '==', docRef), orderBy('createdAt', 'desc'));

		const snapshot = await getDocs(queryRef);
		snapshot.forEach(doc => {
			let model = doc.data();
			model.id = doc.id;

			if (model.createdAt) model.createdAt = helper.getTimestampDate(model.createdAt.toDate(), 'full');
			if (!model.deleted) resp.models.push(model);
		})

		resp.count = resp.models.length;

		resp.status	= resp.count > 0 ? "success" : "error";
    	resp.error 	= resp.count > 0 ? false : "no found models linked to this dataset";

		return resp;
	},

    createTag: async function(datasetID, tagOpt) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false, dataset: datasetID };
    
        if (tagOpt.tag && datasetID) {
            let tag = {
                name:           tagOpt.name ? tagOpt.name : tagOpt.tag,
                color:          tagOpt.color ? tagOpt.color : helper.StringtoHex(tagOpt.tag),
                createdAt:      serverTimestamp(),
                unclassified:   tagOpt.unclassified ? Boolean(true) : Boolean(false)
            }
    
            if (tagOpt.anomaly) tag.anomaly = Boolean(true);
            if (tagOpt.normal) tag.normal = Boolean(true);
    
            let docRef = doc(db, 'dataset', datasetID.toString(), 'tag', tagOpt.tag);
            await setDoc(docRef, tag).then(async function() { 
                tag.id          = tagOpt.tag;
                resp.tag        = tag;
                resp.status     = "success"; 
                resp.message    = "tag created"; 
                await event.saveEvent('dataset.tag.create', { datasetID: datasetID, tag: tag }, false); 
            }).catch(function(error) { resp.error = error });
        } else { resp.error = "new tag id and dataset id is required"; }
    
        return resp;
    },

    updateTag: async function(dataset, tag) {
        tag["updatedAt"] = serverTimestamp();
        let resp = { status: "success", error: false, message: "tag updated" };
    
        const db = _firebase.firestore;
        const batch = writeBatch(db);
        const docRef = doc(db, 'dataset', dataset.id, 'tag', tag.id);

        const newTagOpt = {
            tag:        tag.name,
            anomaly:    tag.anomaly || undefined,
            normal:     tag.normal || undefined,
        }
        await this.createTag(dataset.id, newTagOpt);
        const newTagRef = doc(db, 'dataset', dataset.id, 'tag', newTagOpt.tag);

        const imgsWithTag = await this.getImagesTagmap(dataset, [tag]);
        imgsWithTag.forEach(img => {
            const imgDocRef = doc(db, "image", img.id);
    
            let updateData = {};
    
            if (img.tags) {
                updateData.tags = img.tags.map(tagData => {
                    if (tagData.tag.id === tag.id) {
                        tagData.tag = newTagRef;
                    }
                    return tagData;
                });
            }
    
            if (img.tagsContained) {
                updateData.tagsContained = img.tagsContained.map(tagRef => {
                    if (tagRef.id === tag.id) {
                        return newTagRef;
                    }
                    return tagRef;
                });
            }
    
            if (Object.keys(updateData).length > 0) {
                batch.update(imgDocRef, updateData);
            }
        });
    
        await batch.commit();
        await deleteDoc(docRef);
    
        return resp;
    },

    deleteTag: async function(dataset, tag) {
        let resp = { status: "success", error: false, message: "tag deleted" };

        const db = _firebase.firestore;
        const batch = writeBatch(db);
        let docRef = doc(db, 'dataset', dataset.id, 'tag', tag.id);
    
        const imgsWithTag = await this.getImagesTagmap(dataset, [tag]);
        imgsWithTag.forEach(img => {
            const imgDocRef = doc(db, "image", img.id);
    
            let updateData = {};
    
            if (img.tags) {
                updateData.tags = img.tags.filter(tagData => tagData.tag.id !== tag.id);
            }
    
            if (img.tagsContained) {
                updateData.tagsContained = img.tagsContained.filter(tagRef => tagRef.id !== tag.id);
            }
    
            if (Object.keys(updateData).length > 0) {
                batch.update(imgDocRef, updateData);
            }
        });
    
        await batch.commit();
        await deleteDoc(docRef);

        return resp;
    },

    combineTags: async function (dataset, mainTag, tags) {
        const imgsToCombine = await this.getImagesTagmap(dataset, tags);

        if (typeof(mainTag) === 'string') {
            const { tag } = await this.createTag(dataset.id, { tag: mainTag });
            mainTag = tag;
        }

        const db = _firebase.firestore;
        const batch = writeBatch(db);
        const mainTagRef = doc(db, "dataset", dataset.id, "tag", mainTag.id);

        imgsToCombine.forEach(img => {
            const imgDocRef = doc(db, "image", img.id);
            let updateData = {};
    
            switch (dataset.type) {
                case "MULTICLASS":
                    updateData = { tag: mainTagRef };
                    break;
    
                case "imageObjectDetection":
                    updateData = {
                        tagsContained: (img.tagsContained || []).map(tagRef =>
                            tags.includes(tagRef.id) ? mainTagRef : tagRef
                        ),
                        tags: (img.tags || []).map(tagData => {
                            if (tags.includes(tagData.tag.id)) {
                                tagData.tag = mainTagRef;
                            }
                            return tagData;
                        })
                    };
                    break;
    
                case "MULTILABEL":
                    updateData = {
                        tagsContained: (img.tagsContained || []).map(tagRef =>
                            tags.includes(tagRef.id) ? mainTagRef : tagRef
                        ),
                        tags: (img.tags || []).map(tagData => {
                            if (tags.includes(tagData.tag.id)) {
                                tagData.tag = mainTagRef;
                                tagData.name = mainTag.name;
                            }
                            return tagData;
                        }),
                        masks: img.masks && img.masks.objects.length ? {
                            objects: img.masks.objects.map(maskData => {
                                if (tags.includes(maskData.name)) {
                                    maskData.name = mainTag.id;
                                    maskData.stroke = mainTag.color;
                                }
                                return maskData;
                            })
                        } : { objects: [] }
                    }
                    break;
            }

            batch.update(imgDocRef, updateData);
        });
    
        await batch.commit();

        tags.forEach(async tag => {
            if (tag.id !== mainTag.id) {
                await this.deleteTag(dataset.id, tag.id);
            }
        });

        return { status: "success", updated: imgsToCombine.length };
    },

	getTag: async function(datasetID, tagId) {
        let tag = {};
        let docRef = doc(_firebase.firestore, 'dataset', datasetID.toString(), 'tag', tagId);
        let snapshot = await getDoc(docRef);

        if (snapshot.data()) {
            tag = snapshot.data();
            tag.id = snapshot.id;

            if (tag.createdAt)
                tag.createdAt = helper.getTimestampDate(tag.createdAt.toDate(), 'full');
        }

        return tag;
    },

	getTags: async function(datasetID, unclassified = true) {
        let tags = {};

        if (datasetID) {
            let colRef = collection(_firebase.firestore, 'dataset', datasetID.toString(), 'tag');
            let querySnapshot = await getDocs(query(colRef, orderBy('name', 'asc')));

            const promises = querySnapshot.docs.map(async (snapDoc) => {
                let item = snapDoc.data();

                item.id     = snapDoc.id;
                item.name   = item.name ? helper.capitalize(item.name) : item.name;
                item.color  = item.color || helper.StringtoHex(snapDoc.id);

                if (!item.color) {
                    this.updateTag({ id: snapDoc.id, dataset: datasetID.toString(), data: { color: item.color } });
                }

                if (unclassified || item.id !== "0") { 
                    tags[item.id] = item;
                }
            });

            await Promise.all(promises);
        }

        return tags;
    },

    getTagStats: async function(dataset, tagMap = {}) {
        const db = _firebase.firestore;

        const colRef = collection(db, 'image');
		const docRef = doc(db, 'dataset', dataset.id);

        const tagStats = { tags: {}, total: 0 };

        const totalImagesCount = await getCountFromServer(query(colRef, where('dataset', '==', docRef)));
        tagStats.total = totalImagesCount.data().count;

        let tagsDocs = await getDocs(collection(db, 'dataset', dataset.id, 'tag'));
        
        if (tagMap && Object.keys(tagMap).length) {
            const allowedTags = new Set(Object.keys(tagMap.tags));
            tagsDocs = tagsDocs.docs.filter(tagDoc => allowedTags.has(tagDoc.id));
        } else {
            tagsDocs = tagsDocs.docs;
        }

        if (dataset.type === 'MULTICLASS') {
            await Promise.all(
                tagsDocs.map(async (tagDoc) => {
                    const tagQueryRef = query(
                        colRef,
                        where("dataset", "==", docRef),
                        where("tag", "==", tagDoc.ref)
                    );
        
                    const tagCount = await getCountFromServer(tagQueryRef);
                    tagStats.tags[tagDoc.id] = tagCount.data().count;
                })
            );

            const unclassifiedCount = tagStats.tags['0'] ? tagStats.tags['0'] : 0;

            tagStats.labeled        = tagStats.total - unclassifiedCount;
            tagStats.notLabeled     = unclassifiedCount;
            
        } else if (dataset.type === 'MULTILABEL') {
            await Promise.all(
                tagsDocs.map(async (tagDoc) => {
                    const specialTag = tagDoc.id === "0" || tagDoc.id === "OK";
        
                    const tagQueryRef = query(
                        colRef,
                        where("dataset", "==", docRef),
                        specialTag
                            ? where("tag", "==", tagDoc.ref)
                            : where("tagsContained", "array-contains", tagDoc.ref)
                    );
        
                    const tagCount = await getCountFromServer(tagQueryRef);
                    tagStats.tags[tagDoc.id] = tagCount.data().count;
                })
            );

            const anomalyRef    = doc(db, 'dataset', dataset.id, 'tag', '0');
            const anomalyCount  = await getCountFromServer(query(colRef, where('dataset', '==', docRef), where('tag', '==', anomalyRef)));

            tagStats.anomaly    = anomalyCount.data().count;
            tagStats.normal     = tagStats.total - tagStats.anomaly;


        } else if (dataset.type === 'imageObjectDetection') {
            await Promise.all(
                tagsDocs.map(async (tagDoc) => {
                    const specialTag = tagDoc.id === "0";

                    const tagQueryRef = query(
                        colRef,
                        where("dataset", "==", docRef),
                        specialTag
                            ? where("tagsContained", "==", [])
                            : where("tagsContained", "array-contains", tagDoc.ref)
                    );
        
                    const tagCount = await getCountFromServer(tagQueryRef);
                    tagStats.tags[tagDoc.id] = tagCount.data().count;
                })
            );

            const labeledCount = await getCountFromServer(query(colRef, where('dataset', '==', docRef), where('tagsContained', '!=', [])));

            tagStats.labeled    = labeledCount.data().count;
            tagStats.notLabeled = tagStats.total - tagStats.labeled;
        }

        return tagStats;
    },

    getTagStats_o: async function(dataset, tagMap) {
		const db 		= _firebase.firestore;
		const colRef	= collection(db, 'image');
		const docRef    = doc(db, 'dataset', dataset.id); 

		const tagStats 	= {};

		if (dataset.type === 'MULTICLASS') {
			tagStats.tags = await this.getTagsQueries(dataset, colRef, docRef, tagMap);
			tagStats.labeled = Object.values(tagStats.tags).reduce((total, valor) => total + valor, 0);
		}
        
        else if (dataset.type === 'MULTILABEL' || dataset.type === 'imageObjectDetection') {
			const result = await this.getTagsQueries(dataset, colRef, docRef, tagMap);
            
			tagStats.tagsLabeled    = result.tagsLabeled;
			tagStats.tagsLabeledImg = result.tagsLabeledImg;

            if (dataset.type === 'MULTILABEL') {
                tagStats.anomaly = result.anomalyValue;
            } else {
                tagStats.labeled = result.ids.length;
            }
		}

		await this.parseTagsCounterObj(dataset, tagStats);
		
        const total = await getCountFromServer(query(colRef, where('dataset', '==', docRef)));
		tagStats.count = total.data().count;

		if (dataset.type === 'MULTILABEL') { 
            tagStats.normal = total.data().count - tagStats.anomaly;
        }
        else {
            tagStats.notLabeled = total.data().count - tagStats.labeled;
        }

		return tagStats;
	},

    getTagsQueries: async function(dataset, colRef, docRef, tagMap = false) {
		const db = _firebase.firestore;
		const snapDocs  = await getDocs(collection(db, 'dataset', dataset.id, 'tag'));

        let tagMapTags = [];
        if (tagMap) {
            tagMapTags = Object.values(tagMap.tags).flatMap(tagObj => Object.keys(tagObj.tags).filter(tag => tag !== 'count'));
        }

		if (dataset.type === 'MULTICLASS') {
			const tags = {};
			
			const queries = snapDocs.docs.map(async (tagDoc) => {
                if (!tagMapTags.length || tagMapTags.includes(tagDoc.id)) {
                    const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tag", "==", tagDoc.ref));
	
                    const nTags = await getCountFromServer(tagQueryRef);
                    tags[tagDoc.id] = nTags.data().count;
                }
			});
			
			await Promise.all(queries);
			return tags;
		}

        else if (dataset.type === 'MULTILABEL') {
			const ids = [], tagsLabeled = {}, tagsLabeledImg = {};

            const anomalyRef    = doc(db, 'dataset', dataset.id, 'tag', '0');
            const anomalyCount  = await getCountFromServer(query(colRef, where("dataset", "==", docRef), where("tag", "==", anomalyRef)));
            const anomalyValue  = anomalyCount.data().count;
           
			const queries = snapDocs.docs.map(async (tagDoc) => {
				const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tagsContained", "array-contains", tagDoc.ref));
				
				const snapshot = await getDocs(tagQueryRef);
				const labeledCount = snapshot.size > 0 ? snapshot.docs.reduce((count, doc) => { 
					if (!ids.includes(doc.id)) 
						ids.push(doc.id);
					return count + doc.data().tagsContained.filter(tag => tag.id === tagDoc.id).length;
				}, 0) : 0;

				tagsLabeled[tagDoc.id] = labeledCount;
				tagsLabeledImg[tagDoc.id] = snapshot.size;
			});

			await Promise.all(queries);
			return { ids, tagsLabeled, tagsLabeledImg, anomalyValue };
		}
        
        else if (dataset.type === 'imageObjectDetection') {
            const ids               = new Set();
            const tagsLabeled       = {};
            const tagsLabeledImg    = {};

            const queries = snapDocs.docs.filter(tagDoc => !tagMapTags.length || tagMapTags.includes(tagDoc.id)).map(async (tagDoc) => {
                const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tagsContained", "array-contains", tagDoc.ref));
                const snapshot = await getDocs(tagQueryRef);
                const labeledCount = snapshot.docs.reduce((count, doc) => {
                    ids.add(doc.id);
                    return count + doc.data().tagsContained.filter(tag => tag.id === tagDoc.id).length;
                }, 0);

                tagsLabeled[tagDoc.id]      = labeledCount;
                tagsLabeledImg[tagDoc.id]   = snapshot.size;
            });

            await Promise.all(queries);

            delete tagsLabeled['0'];
            delete tagsLabeledImg['0'];

            return { ids: Array.from(ids), tagsLabeled, tagsLabeledImg };
        }
	},

    parseTagsCounterObj: async function(dataset, tagsCounter) {
		const tags = await this.getTags(dataset.id);
        dataset['tags'] = tags;
        
        if (dataset.type === 'imageObjectDetection') {
            delete(dataset['tags']['0']);
        }

		tagsCounter.names	= {};
		tagsCounter.colors 	= {};

		for (const tag of Object.keys(tags)) {
			tagsCounter.names[tag]	= tags[tag].name;
			tagsCounter.colors[tag]	= (tags[tag] && tags[tag].color ? tags[tag].color : helper.StringtoHex(tag));
		}

		tagsCounter.chart = { labels: ["Labeled"], datasets: [{ data: [tagsCounter.labeled], backgroundColor: [helper.StringtoHex("LabeledGreen")], hoverBackgroundColor: [helper.StringtoHex("LabeledGreen")], borderWidth: 5, borderColor: "#fff"}]}

		if (tagsCounter.notLabeled) {
			tagsCounter.chart.labels.push("Unclassified");
			tagsCounter.chart.datasets[0].data.push(tagsCounter.notLabeled);
			tagsCounter.chart.datasets[0].backgroundColor.push(helper.StringtoHex("UnclassifiedRed"));
			tagsCounter.chart.datasets[0].hoverBackgroundColor.push(helper.StringtoHex("UnclassifiedRed"));
		}

		return tagsCounter;
	},

    getDivisionStats: async function(dataset, tagsType = false, byTag = false, tagMap = false) {
        const divisions = {
            total:          0,
            train:          0,
            test:           0,
            validation:     0,
            predetermined:  0,
        }

        let total = 0;
        let queryRef;
        let queryPromises;
        
        const datasetType = dataset.type;

        const colRef = collection(_firebase.firestore, 'image');
        const docRef = doc(_firebase.firestore, 'dataset', dataset.id);

        let imgsId = [];

        if (tagMap) {
            for (let key in tagMap.tags) {
                const tags = tagMap.tags[key].tags;
                let tagImgsId = [];
                for (let tag in tags) {
                    if (tag !== 'count') {
                        const tagRef = doc(_firebase.firestore, 'dataset', dataset.id, 'tag', tag);
                        queryPromises = Object.keys(divisions).map(async (division) => {
                            if (tag === 'OK')
                                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                            else 
                                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                            const querySnapshot = await getDocs(queryRef);
                            querySnapshot.forEach((doc) => {
                                if (!imgsId.includes(doc.id)) {
                                    divisions[division]++;
                                    imgsId.push(doc.id);
                                }
                                if (!tagImgsId.includes(doc.id)) {
                                    tagImgsId.push(doc.id);
                                }
                            });
                            total = imgsId.length;
                            tagMap.tags[key].total = tagImgsId.length;
                        });
                    }
                }
            }
        } else if (!tagsType && !byTag) {
            queryPromises = Object.keys(divisions).map(async (division) => {
                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()));
                return getCountFromServer(queryRef).then((count) => { 
                    divisions[division] = count.data().count;
                    total += count.data().count;
                })
            });
        } else if (tagsType && !byTag) {
            if (tagsType == 'labeled' || tagsType == 'anomaly') {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tags', '!=', []));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            } else if (tagsType == 'notLabeled' || tagsType == 'normal') { 
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tags', '==', []));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            }
        } else if (byTag) {
            const tagRef = doc(_firebase.firestore, 'dataset', dataset.id, 'tag', byTag);
            if (datasetType === 'imageObjectDetection') {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            } else if (datasetType === 'MULTILABEL') {
                if (byTag === '0' || byTag === 'OK') { 
                    queryPromises = Object.keys(divisions).map(async (division) => {
                        queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                        return getCountFromServer(queryRef).then((count) => { 
                            divisions[division] = count.data().count;
                            total += count.data().count;
                        })
                    });
                } else {
                    queryPromises = Object.keys(divisions).map(async (division) => {
                        queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                        return getCountFromServer(queryRef).then((count) => { 
                            divisions[division] = count.data().count;
                            total += count.data().count;
                        })
                    });
                }
            } else {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            }
        }

        try {
            await Promise.all(queryPromises);
            divisions['total'] = total;
        } catch (error) {
            console.log("Error:", error);
        }

        return divisions;
    },

	getStatus: async function(dataset) {
        let lastImportEvent     = dataset.automl ? await event.get({ type: "dataset.import", dataset: dataset.automl, last: true }) : {}
        let lastTrainingEvent   = dataset.automl ? await event.get({ type: "dataset.training", dataset: dataset.automl, last: true }) : {}
        let lastImport		    = { inProgress: false }
        let lastTraining 	    = { inProgress: false }
        let lastUploadZip 	    = { inProgress: false }

        Object.assign(lastUploadZip, {
            uploadRef:          dataset.uploadRef,
            uploadStatus:       dataset.uploadStatus,
            uploadStatusMsg:    dataset.uploadStatusMsg,
            inProgress:         dataset.uploadStatus === "processing"
        });

        if (Object.keys(lastImportEvent).length) {
            if (lastImportEvent.name)               lastImport.name         = lastImportEvent.name;
            if (lastImportEvent.payload.dataset)    lastImport.datasetID    = lastImportEvent.payload.dataset;
            if (lastImportEvent.createdAt)          lastImport.created      = helper.getTimestampDate(lastImportEvent.createdAt.toDate(), 'full');
            if (lastImportEvent.payload.uid)        lastImport.uid          = lastImportEvent.payload.uid;

            if (lastImportEvent.payload && lastImportEvent.payload.operation) {
                let operationName   = lastImportEvent.payload.operationName ? lastImportEvent.payload.operationName : lastImportEvent.payload.operation;
                let lastImportOper  = await other.httpsCallable('api/model/operation/status/' + operationName.replace(/\//g, "--"));

                if (lastImportOper.data) lastImport.operation = { name: lastImportOper.data.name, result: lastImportOper.data.result, done: lastImportOper.data.done };
                if (lastImport.operation && !lastImport.operation.done) lastImport.inProgress = true;
            }
        }

        if (Object.keys(lastTrainingEvent).length) {
            if (lastTrainingEvent.name)                 lastTraining.name       = lastTrainingEvent.name;
            if (lastTrainingEvent.payload.dataset)      lastTraining.datasetID  = lastTrainingEvent.payload.dataset;
            if (lastTrainingEvent.payload.displayname)  lastTraining.model      = lastTrainingEvent.payload.displayname;
            if (lastTrainingEvent.createdAt)            lastTraining.created    = helper.getTimestampDate(lastTrainingEvent.createdAt.toDate(), 'full');
            if (lastTrainingEvent.payload.uid)          lastTraining.uid        = lastTrainingEvent.payload.uid;

            if (lastTrainingEvent.payload && lastTrainingEvent.payload.operationID) {
                let pipName = lastTrainingEvent.payload.operationID.name ? lastTrainingEvent.payload.operationID.name : lastTrainingEvent.payload.operationID;
                let lastTrainingPip = await other.httpsCallable('api/model/trainingpipeline/status/' + pipName.replace(/\//g, "--"));

                if (lastTrainingPip.data) {
                    lastTraining.pipeline = {
                        name:           lastTrainingPip.data.name,
                        displayName:    lastTrainingPip.data.displayName,
                        startTime:      helper.getFbDate(lastTrainingPip.data.startTime),
                        trainBudget:    lastTrainingEvent.payload.trainBudget,
                        state:          lastTrainingPip.data.state,
                        error:          lastTrainingPip.data.error,
                        modelToUpload:  lastTrainingPip.data.modelToUpload ? lastTrainingPip.data.modelToUpload.name : false,
                        done:           lastTrainingEvent.status && lastTrainingEvent.status == "done" ? true : false
                    }

                    if (lastTrainingPip.data.state == "PIPELINE_STATE_SUCCEEDED" || lastTrainingPip.data.state == "PIPELINE_STATE_FAILED") lastTraining.pipeline.done = true;

                    if (lastTraining.model && lastTraining.pipeline.modelToUpload) {
                        let trainedModel = await model.get(lastTraining.model);
                        if (!trainedModel.automl) model.update(lastTraining.model, { automl: lastTraining.pipeline.modelToUpload.toString().split('/').pop() });
                    }
                } else { lastTraining.pipeline = { name: lastTrainingEvent.payload.operationID, error: "not found", trainBudget: lastTrainingEvent.payload.trainBudget, state: "PIPELINE_STATE_FAILED", done: true } }
                
                if (lastTraining.pipeline && !lastTraining.pipeline.done) lastTraining.inProgress = true
            }
            if (!dataset.trained) await this.update(dataset.id, { trained: Boolean(true) })
        }

        let resp = {
            dataset:    dataset.id,
            trained:    Object.keys(lastTrainingEvent).length ? true : false,
            inProgress: lastImport.inProgress || lastTraining.inProgress || lastUploadZip.inProgress ? true : false,
            action:     lastImport.inProgress ? 'importing' : lastTraining.inProgress ? 'training' : lastUploadZip.inProgress ? 'Uploading' : false,
            import:     lastImport,
            training:   lastTraining,
            uploadZip:  lastUploadZip,
        };

        //AWS Models
        let datasetModels = dataset.models;
        if (datasetModels.models.length) {
            for (let index in datasetModels.models) {
                if (datasetModels.models[index].aws) {
                    let model = await aws.getModel(datasetModels.models[index].aws);
                    if (model.response && model.response.ModelDescription && model.response.ModelDescription.Status && model.response.ModelDescription.Status == "TRAINING") {
                        resp.inProgress             = true;
                        resp.action                 = 'training';
                        resp.training               = model.response.ModelDescription;
                        resp.training.modelName     = datasetModels.models[index].id;
                        resp.training.projectName   = datasetModels.models[index].aws;
                    }
                }
            }
        }

        return resp;
    },

	getImages: async function(dataset, opt = {}) {
        const db = _firebase.firestore;
        
        let imgsQuery = collection(db, 'image');

        let media = { media: [] };

        let pagination  = { 
            currentPage:    0, 
            perPage:        opt.perPage || 12, 
            pages:          1, 
            total:          0, 
            init:           null, 
            first:          null, 
            last:           null, 
            prev:           false, 
            next:           true 
        };

        if (opt.perPage) opt.pagination = true;
        if (opt.paginationQry) pagination = opt.paginationQry;

        if (!dataset.id) return media;

        const datasetID     = dataset.id;
        const datasetType   = dataset.type || false;

        opt.datasetID   = datasetID;
        opt.datasetType = datasetType;
        media.type      = datasetType;

        imgsQuery = await this.filterImages(imgsQuery, opt);
        let baseQuery = imgsQuery;

        const totalImages = await getCountFromServer(baseQuery);

        const orderField    = opt.order     || "date";
        const orderDir      = opt.direction || "desc";

        imgsQuery = query(imgsQuery, orderBy(orderField, orderDir));

        const limitValue = opt.limit || opt.perPage || 10000;
        imgsQuery = query(imgsQuery, limit(limitValue));

        if (opt.pagination) {
            imgsQuery = await this.applyPagination(imgsQuery, pagination, opt.action);
        }

        const imgsSnapshot = await getDocs(imgsQuery);

        const images = imgsSnapshot.docs.map(doc => this.processImageDoc(doc));
        media.media = await Promise.all(images);
        media.count = media.media.length;

        if (opt.pagination) {
            const optPagination = {
                action:         opt.action,
                docs:           imgsSnapshot.docs,
                baseQuery:      baseQuery,
                orderField:     orderField,
                orderDir:       orderDir,
                orderDirInv:    orderDir == "asc" ? "desc" : "asc",
                totalImages:    totalImages.data().count
            }

            await this.processPagination(pagination, optPagination);
            if (media.count) media.pagination = pagination;
        }

        return media;
    },

    getImagesIds: async function(dataset, opt = {}) {
        const db = _firebase.firestore;
        
        let imgsQuery = collection(db, 'image');

        if (!dataset.id) return [];

        const datasetID     = dataset.id;
        const datasetType   = dataset.type || false;

        opt.datasetID   = datasetID;
        opt.datasetType = datasetType;

        imgsQuery = await this.filterImages(imgsQuery, opt);

        const orderField    = opt.order     || "date";
        const orderDir      = opt.direction || "desc";

        imgsQuery = query(imgsQuery, orderBy(orderField, orderDir));

        const limitValue = opt.limit || opt.perPage || 10000;
        imgsQuery = query(imgsQuery, limit(limitValue));

        const imgsSnapshot = await getDocs(imgsQuery);

        return imgsSnapshot.docs.map(doc => doc.id);
    },

    filterImages: async function(imgsQuery, opt = false) {
        const db = _firebase.firestore;

        const datasetID     = opt.datasetID || false;
        const datasetType   = opt.datasetType || false;

        const objtagsType   = opt.objtagsType   ? opt.objtagsType.toString()    : false;
        const objByTag      = opt.objByTag      ? opt.objByTag.toString()       : false;
        const tagMap        = opt.tagMap        ? opt.tagMap                    : false;
        const objDivision   = opt.objDivision   ? opt.objDivision.toString()    : false;

        let tagRef = doc(db, "dataset", datasetID);

        if (datasetType) {
            imgsQuery = query(imgsQuery, where('dataset', '==', tagRef));

            /* Filtrar por objTagsType */
            if (objtagsType && datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                if (objtagsType == 'notLabeled') {
                    imgsQuery = query(imgsQuery, where('tags', '==', []));
    
                } else if (objtagsType == 'labeled') {
                    imgsQuery = query(imgsQuery, where('tags', '!=', []));
    
                } else if (objtagsType == 'normal') {
                    let normalRef = doc(db, "dataset", datasetID, "tag", 'OK');
                    imgsQuery = query(imgsQuery, where('tag', '==', normalRef));
    
                } else if (objtagsType == 'anomaly') {
                    let anomalyRef = doc(db, "dataset", datasetID, "tag", '0');
                    imgsQuery = query(imgsQuery, where('tag', '==', anomalyRef));
                }
            }
    
            /* Filtrar por objByTag */
            if (objByTag && objByTag != 'all') {
                if (datasetType == 'MULTICLASS') {
                    tagRef = doc(db, "dataset", datasetID, "tag", objByTag);
                    imgsQuery = query(imgsQuery, where('tag', '==', tagRef));
                } else if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                    tagRef = doc(db, "dataset", datasetID, "tag", objByTag);
                    imgsQuery = query(imgsQuery, where('tagsContained', 'array-contains', tagRef));
                }
            }
    
            /* Filtrar usando tagMap */
            if (tagMap) {
                let tagRefs = [];
                for (let key in tagMap.tags) {
                    const tags = tagMap.tags[key].tags;
                    for (let tag in tags) if (tag !== 'count') tagRefs.push(doc(db, "dataset", datasetID, "tag", tag));
                }

                if (datasetType == 'MULTICLASS') {
                    imgsQuery = query(imgsQuery, where('tag', 'in', tagRefs));
                } else if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                    imgsQuery = query(imgsQuery, or(where('tagsContained', 'array-contains-any', tagRefs), where('tag', 'in', tagRefs)));
                }
            }
    
            /* Filtrar por objDivision */
            if (objDivision && objDivision != 'all') {
                imgsQuery = query(imgsQuery, where('set', '==', objDivision.toUpperCase()));
            }
        }

        return imgsQuery;
    },

    applyPagination: async function(imgsQuery, pagination, action) {
        switch (action) {
            case "firstPage":
                if (pagination.init) {
                    return query(imgsQuery, startAt(pagination.init), limit(pagination.perPage));
                }

            case "previousPage":
                if (pagination.first) {
                    return query(imgsQuery, endBefore(pagination.first), limitToLast(pagination.perPage));
                }

            case "nextPage":
                if (pagination.last) {
                    return query(imgsQuery, startAfter(pagination.last), limit(pagination.perPage));
                }

            case "lastPage":
                if (pagination.end) {
                    return query(imgsQuery, startAt(pagination.end), limitToLast(pagination.perPage));
                }
                break;

            case "selectedPage":
                if (pagination.currentPage === 0) {
                    return query(imgsQuery, startAt(pagination.init), limit(pagination.perPage))
                } else {
                    const skip = pagination.currentPage * pagination.perPage;
                    const skipSnap = await getDocs(query(imgsQuery, limit(skip)));
                    const lastDoc = skipSnap.docs[skipSnap.docs.length - 1];
                    return query(imgsQuery, startAfter(lastDoc), limit(pagination.perPage));
                }
                
            default:
                break;
        }

        if (pagination.perPage) {
            if (!action && pagination.first) {
                return query(imgsQuery, startAt(pagination.first), limit(pagination.perPage));
            } else {
                return query(imgsQuery, limit(pagination.perPage));
            }
        }
        
        return imgsQuery;
    },

    processImageDoc: async function (doc) {
        const img = doc.data();
        img.id = doc.id;
        img.tag = img.tag.path;
        
        if (img.tag) {
            img.tagName = img.tag.split('/');
        }
    
        img.fileName = img.name.split('/').pop();
    
        img.createdDate = img.date ? helper.getTimestampDate(img.date, 'full') : null;
        img.updatedDate = img.updatedAt ? helper.getTimestampDate(img.updatedAt.toDate(), 'full') : img.createdDate;
    
        if (img.imageData) {
            const { _byteString, previewImg } = img.imageData;
            if (_byteString && _byteString.binaryString) {
                img.img_base64_val = "data:image/jpeg;base64," + btoa(_byteString.binaryString);
            } else if (previewImg) {
                img.img_base64_val = previewImg;
            }
        }
    
        return img;
    },
    
    processPagination: async function (pagination, opt) {
        const { perPage } = pagination;
    
        if (!pagination.init || !pagination.end) {
            pagination.total = opt.totalImages;
            pagination.pages = Math.max(Math.ceil(pagination.total / perPage), 1);

            const firstImageQuery = query(opt.baseQuery, orderBy(opt.orderField, opt.orderDir), limit(1));
            const firstImageSnap = await getDocs(firstImageQuery);

            pagination.init = firstImageSnap.docs[0];

            const skip = (pagination.pages - 1) * perPage;
            if (skip > 0) {
                const skipQuery = query(opt.baseQuery, orderBy(opt.orderField, opt.orderDirInv), limit(perPage));
                const skipSnap = await getDocs(skipQuery);
                pagination.end = skipSnap.docs[perPage - 1];
            }
        }

        if (opt.docs.length) {
            pagination.first = opt.docs[0];
            pagination.last = opt.docs[opt.docs.length - 1];
        }

        switch (opt.action) {
            case 'firstPage':
                pagination.currentPage = 0;
                break;

            case 'previousPage':
                pagination.currentPage--;
                break;

            case 'nextPage':
                pagination.currentPage++;
                break;

            case 'lastPage':
                pagination.currentPage = pagination.pages - 1;
                break;
        }
    
        pagination.next = (pagination.currentPage + 1) < pagination.pages;
        pagination.prev = pagination.currentPage > 0;
    },

    getImagesTagmap: async function (dataset, tagMap) {
        const db = _firebase.firestore;

        const colRef = collection(db, 'image');
        const docRef = doc(db, 'dataset', dataset.id);

        let tagArray;
        if (Array.isArray(tagMap)) {
            tagArray = tagMap;
        } else if (tagMap && typeof tagMap === 'object') {
            tagArray = Object.keys(tagMap);
        }

        const tagRefs = tagArray.map(tag => {
            return doc(db, "dataset", dataset.id, "tag", (typeof tag === 'object' && tag.id) ? tag.id : tag);
        });
    
        if (dataset.type === 'MULTICLASS') {
            const imgsQuery = query(colRef, where('dataset', '==', docRef), where('tag', 'in', tagRefs));
            const snapshot = await getDocs(imgsQuery);
    
            const images = snapshot.docs.map(doc => {
                const img = doc.data();
                img.id = doc.id;
                return img;
            });
    
            return images;
        } else if (dataset.type === 'MULTILABEL' || dataset.type === 'imageObjectDetection') {
            const imgsQuery = query(colRef, where('dataset', '==', docRef), where('tagsContained', 'array-contains-any', tagRefs));
            const snapshot = await getDocs(imgsQuery);
        
            const images = snapshot.docs.map(doc => {
                const img = doc.data();
                img.id = doc.id;
                return img;
            });
        
            return images;
        }
    },

    getImagesData: async function(opt, onProgressUpdate) {
        let imagesToProcess = [];
        
        if (!opt.completeDataset) imagesToProcess = opt.images.filter(img => opt.selectedIds.has(img.id));
        else imagesToProcess = opt.images;

        if (!imagesToProcess.length) return;

        const chunkSize = 20;
        let images = [];

        for (let i = 0; i < imagesToProcess.length; i += chunkSize) {
            const chunkFiles = imagesToProcess.slice(i, i + chunkSize);

            const imagePromises = chunkFiles.map(async img => {
                let filename = img.name.substr(img.name.lastIndexOf("/") + 1);
                return await image.getStorageUrl(img.uri)
                    .then(imageUrl => 
                        fetch(imageUrl.url)
                        .then(response => response.blob())
                        .then(imageBlob => ({ imageBlob, imageUrl }))
                    )
                    .then(({ imageBlob, imageUrl }) => {
                        opt.zipCounter++;
                        onProgressUpdate(opt.zipCounter);

                        let data = false;
                        let type = null;

                        if (img.masks && img.masks.objects) {
                            data = img.masks;
                            type = 'masks';
                        } else if (img.mask) {
                            data = img.mask;
                            type = 'mask';
                        } else if (img.tags && img.tags.length) {
                            data = img.tags;
                            type = 'box';
                        }

                        return {
                            name:           filename,
                            tag:            img.tag,
                            set:            img.set,
                            file:           imageBlob,
                            data:           data,
                            type:           type,
                            imageUrl:       imageUrl.url,
                            createdDate:    img.createdDate,
                        };
                    });
            });

            try {
                const chunkResults = await Promise.all(imagePromises);
                images.push(...chunkResults);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        return images;
    },

    getImageAdjacents: async function(image, dataset, filters = false, opt = false) {
        const db = _firebase.firestore;

        const resp = {
            prevMedia: [],
            nextMedia: [],
        }

        let imgsQuery = collection(db, 'image');
        let mainImageDoc = image.id ? await getDoc(doc(db, 'image', image.id)) : false;
    
        if (dataset.id) {
            const datasetType = dataset.type ? dataset.type : false;

            filters.datasetID = dataset.id;
            filters.datasetType = datasetType;

            imgsQuery = await this.filterImages(imgsQuery, filters);

            if (opt.total) { 
                const totalImages = await getCountFromServer(imgsQuery);
                resp.total = totalImages.data().count;

                const indexQuery = query(imgsQuery, orderBy(filters.order, filters.direction));
                const docs = await getDocs(indexQuery);
                resp.index = docs.docs.findIndex(doc => doc.id === image.id);
                resp.index = resp.index + 1;
            }
    
            const imageDate = filters.order === 'date' ? image.date : image.updatedAt;

            if (opt.prevLimit) {
                const prevOperator  = filters.direction === 'asc' ? '<=' : '>=';
                const prevDirection = filters.direction === 'asc' ? 'desc' : 'asc';
    
                const prevQuery = query(imgsQuery, where(filters.order, prevOperator, imageDate), orderBy(filters.order, prevDirection), startAfter(mainImageDoc), limit(opt.prevLimit));
                const prevSnap = await getDocs(prevQuery);
            
                prevSnap.forEach(docSnap => {
                    let data = docSnap.data();
                    data.id = docSnap.id;
                    data.createdDate = helper.getTimestampDate(data.date, 'full');
                    data.updatedDate = data["updatedAt"] ? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') : helper.getTimestampDate(data.date, 'full');
                    resp.prevMedia.push(data);
                });
            }

            if (opt.nextLimit) {
                const nextOperator  = filters.direction === 'asc' ? '>=' : '<=';
                const nextDirection = filters.direction === 'asc' ? 'asc' : 'desc';
    
                const nextQuery = query(imgsQuery, where(filters.order, nextOperator, imageDate), orderBy(filters.order, nextDirection), startAfter(mainImageDoc), limit(opt.nextLimit));
                const nextSnap = await getDocs(nextQuery);

                nextSnap.forEach(docSnap => {
                    let data = docSnap.data();
                    data.id = docSnap.id;
                    data.createdDate = helper.getTimestampDate(data.date, 'full');
                    data.updatedDate = data["updatedAt"] ? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') : helper.getTimestampDate(data.date, 'full');
                    resp.nextMedia.push(data);
                });
            }

            return resp;
        }
    
        return false;
    },

	getPreview: async function(datasetID) {
        let resp = { status: "error", error: false, preview: false };

        if (datasetID) {
            let colRef  = collection(_firebase.firestore, 'image');
            let docRef  = doc(_firebase.firestore, 'dataset', datasetID.toString());
            let images  = query(colRef, where('dataset', '==', docRef), orderBy('date', 'desc'), limit(1));
            let snap    = await getDocs(images);

			if (!snap.empty) {
				let doc = snap.docs[0];
				let p = doc.data();
				p.id = doc.id;

				if (p.imageData && p.imageData._byteString && p.imageData._byteString.binaryString) {
					resp.preview = "data:image/jpeg;base64," + btoa(p.imageData._byteString.binaryString);
					resp.status = "success";
				} else if (p.imageData && p.imageData.previewImg) {
                    resp.preview = p.imageData.previewImg;
					resp.status = "success";
                }
			} else { resp.error = "The dataset does not contain images"; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    generateResume: async function(dsData) {
        let resp = { status: "error", error: false, resume: "" };
        if (dsData.id) {
            if (!dsData.tagStats) {
                dsData.tagStats = await this.getTagStats(dsData);
            }
    
            let typeResumes = {
                'MULTICLASS': 'This dataset is based on the Image classification with a single label.',
                'MULTILABEL': 'This dataset is based on the Image classification with multiple labels.',
                'imageObjectDetection': 'This data set is based on the classification of image by object detection.'
            };
        
            resp.resume = typeResumes[dsData.type] || "";

            const filteredTags = Object.keys(dsData.tagStats.tags).filter(tag => tag !== '0' && tag !== 'OK');
            if (filteredTags.length) {
                const labeledValue      = dsData.tagStats.labeled || dsData.tagStats.anomaly;
                const notLabeledValue   = dsData.tagStats.notLabeled || dsData.tagStats.normal;

                resp.resume += ' Contains ' + filteredTags.length + ' classification tags and ' + dsData.tagStats.total + ' images, of which ' + labeledValue + ' are labeled.';
                resp.resume += ' and ' + notLabeledValue + ' images are unclassified. Remember that unclassified images will not be used for training.';
            }
    
            if (dsData.models.count) { 
                resp.resume += ' The set is trained and ' + dsData.models.count + ' prediction models have been generated.';
            }
    
            resp.status = "success";
        } else { resp.error = "dataset Id is required"; }
        
        return resp;
    },

    createOperation: async function(datasetID, opt = false) {
        let resp = { status: "error", error: false };

        const operation = opt.type === 'move' ? 'moveOperation' : 'copyOperation';
        const fName     = 'api/dataset/' + datasetID + '/' + operation;
        const fData     = { body: JSON.stringify({ images: opt.images }) };
    
        const data = await other.httpsCallable(fName, fData);
        console.log("Response obtained: ", data);

        return resp;
    },

    deleteImagesBatch: async function(datasetID, images) {
        let resp = { status: "error", error: false };
        await other.httpsCallable('api/dataset/' + datasetID + '/deleteBulk/' + encodeURIComponent(images));
        resp.status = "success";
        return resp;
    },

    manageImages: async function(datasetID) {
        const shouldDelete  = false; //Seleccionar entre borrar o actualizar

        const datasetRef    = doc(_firebase.firestore, 'dataset', datasetID);
        const imagesRef     = collection(_firebase.firestore, 'image');
        const query         = query(imagesRef, where('dataset', '==', datasetRef));
        const querySnapshot = await getDocs(query);
        
        const batch = writeBatch(_firebase.firestore);
        
        querySnapshot.forEach((doc) => {
            if (shouldDelete) {
                batch.delete(doc.ref);
            } else {
                const newName = `${datasetID}/${doc.data().name}.jpg`;
                batch.update(doc.ref, {name: newName});
            }
        });
        
        await batch.commit();
        if (shouldDelete) {
            console.log(`Todas las imágenes del dataset ${datasetID} han sido eliminadas.`);
        } else {
            console.log(`Todos los nombres de las imágenes del dataset ${datasetID} han sido actualizados.`);
        }
    },

    updateAutomlId: async function(datasetID) {
        const db = _firebase.firestore;

        let dsRef = doc(db, 'dataset', datasetID);
        let queryRef = query(collection(db, 'model'), where('dataset', '==', dsRef), orderBy('createdAt', 'desc'));

        await getDocs(queryRef).then(async snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data();
                item.id = doc.id;

                if (!item.deleted && item.trainOperation && item.trainOperation.operationName && !item.automl) {
                    let trainingPipelineOperation = await other.httpsCallable('api/model/trainingpipeline/status/' + item.trainOperation.operationName.replace(/\//g, "--"));

                    console.log('Model training: ' + item.id, trainingPipelineOperation.data);

                    if (trainingPipelineOperation.data) {
                        if (trainingPipelineOperation.data.state == "PIPELINE_STATE_SUCCEEDED" || trainingPipelineOperation.data.state == "PIPELINE_STATE_FAILED") {
                            model.update(item.id, { automl: trainingPipelineOperation.data.modelToUpload.name.toString().split('/').pop() });
                        }
                    }
                }
            });
        })
    },

    refreshCounters: async function(datasetID, usapi) {
        let resp = { status: "error", error: false };
        const functionsUsApi = getFunctions(_firebase.firebase, usapi);
        const action = functionsUsApi.httpsCallable('dataset/' + datasetID + '/refreshCounters');
        await action({}).then(() => { resp.status = "success"; }).catch(async (error) => { resp.error = error });
        return resp;
    },

    getMaskCounter: async function(datasetID, Tag = false) {
        const db = _firebase.firestore;
        let countMaskImages = 0;

        let colRef = collection(db, 'image');
        let datasetRef = doc(db, 'dataset', datasetID.toString());
        let tagRef = doc(db, 'dataset', datasetID.toString(), 'tag', Tag.toString());
        let queryRef = query(colRef, where('dataset', '==', datasetRef), where('tag', '==', tagRef));

        let snapshot = await getDocs(queryRef);
        snapshot.forEach(async (doc) => {
            let p = doc.data();
            if (p.mask && p.mask.imageJson)
                countMaskImages++;
        });

        return countMaskImages;
    },

    getDatasetAnnotationSetList: async function(datasetID) {
        let resp = { status: "error", error: false, last: false };

        if (datasetID) {
            resp.apiQry = 'api/model/annotationset/dataset_id/' + datasetID;
            let annotationsgResp = await other.httpsCallable(resp.apiQry);

            if (annotationsgResp.data) {
                resp.annotationSet = annotationsgResp.data;
                if (annotationsgResp.data[0] && annotationsgResp.data[0].name) {
                    resp.annotationSet.sort(function(a, b) { return b.createTime.seconds - a.createTime.seconds; })
                    resp.last = resp.annotationSet[0].name.toString().split('/').pop();
                }
                resp.status = "success";
            } else { resp.error = "failed to get annotation set list"; }
        } else { resp.error = "Automl dataset Id is required"; }

        return resp;
    },

    createZip: async function(dataset, images, opt) {
        let imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp'];

        let mimeToExt = {
            'image/jpeg':   '.jpeg',
            'image/png':    '.png',
            'image/gif':    '.gif',
            'image/webp':   '.webp',
        };

        if (images.length === 1 && opt.rawImg) {
            let img = images[0];

            let hasImageExtension = imageExtensions.some(ext => img.name.toLowerCase().endsWith('.' + ext));
            let filename = hasImageExtension ? img.name : img.name + '.png';

            let url = URL.createObjectURL(img.file);
            let link = document.createElement('a');

            link.href = url;
            link.download = filename;
            link.click();

            URL.revokeObjectURL(url);

        } else {
            let filenames 	= {};
            let folders 	= [];				
            let zip			= new JSZip();
            let nowdate		= new Date();
            let zipFilename	= dataset.id + "_(" + images.length + ")_" + nowdate.getTime() + ".zip";

            let promises = images.map(async (img) => {
                let tagID   = img.tag.substring(img.tag.lastIndexOf('/') + 1);

                if (!folders[tagID]) folders[tagID] = zip.folder(tagID);

                let hasImageExtension = imageExtensions.some(ext => img.name.toLowerCase().endsWith('.' + ext));
                let filename = hasImageExtension ? img.name : img.name + '.png';

                filenames[img.name] = (filenames[img.name] || 0) + 1;
                if (filenames[img.name] > 1) {
                    let dotIndex = filename.lastIndexOf('.');
                    filename = `${filename.substring(0, dotIndex)}_${filenames[img.name] - 1}${filename.substring(dotIndex)}`;
                }

                if (img.data && Object.keys(img.data).length && img.type !== 'box') {
                    folders[tagID]['masks'] = zip.folder(tagID + '/masks');

                    if (img.type === 'masks') {
                        await Promise.all(img.data.objects.map(async (mask) => {
                            mask.name = mask.name === '0' ? 'Anomaly' : mask.name;
                            
                            let maskName, maskFile;
                            if (mask.src) {
                                let mimeString  = mask.src.split(',')[0].split(':')[1].split(';')[0];
                                maskName = filename.substring(0, filename.lastIndexOf('.')) + '-' + mask.name + mimeToExt[mimeString];
                                maskFile = await fetch(mask.src).then(res => res.blob());
                            } else if (mask.url) {
                                maskName = filename.substring(0, filename.lastIndexOf('.')) + '-' + mask.name + '.webp';
                                maskFile = await fetch(mask.url).then(res => res.blob());
                            }

                            folders[tagID]['masks'].file(maskName, maskFile, { base64: true });
                        }));
                    } else if (img.type === 'mask') {
                        let maskJSON = false;

                        try { maskJSON = JSON.parse(img.data.imageJson); }
                        catch (error) { maskJSON = JSON.parse(lzstring.decompressFromUint8Array(img.data.imageJson.toUint8Array())); }

                        if (maskJSON) {
                            let canvas = new fabric.Canvas('canvas');
                            canvas.setWidth(img.data.width);
                            canvas.setHeight(img.data.height);
                            canvas.loadFromJSON(maskJSON);
                            let maskFile = canvas.toDataURL();
                            maskFile = await fetch(maskFile).then(res => res.blob());
                            folders[tagID]['masks'].file(filename, maskFile, { base64: true });
                        }
                    }
                }

                const createdDate = moment.utc(img.createdDate, "DD/MM/YYYY HH:mm:ss").toDate();

                folders[tagID].file(filename, img.file, { base64: true, date: createdDate });
                return folders;
            });

            await Promise.all(promises);

            const manifest = await aws.generateManifest(dataset, images);
            zip.file("test.manifest", 	manifest['test']);
            zip.file("train.manifest", 	manifest['train']);

            await zip.generateAsync({ type: "blob", compression: "STORE" }).then(async function (blob) { saveAs(blob, zipFilename) });
        }
    },

    downloadZip: async function(datasetID) {
        let resp = { status: "error", error: false };

        let media = await this.getImages({ datasetID: datasetID });
        let tags = await this.getTags(datasetID);
        let zip = { name: "zip", count: 0, files: [], folders: [] };
        var nowDate = new Date();

        if (media.count) {
            for (var i = 0; i < Object.keys(media.media).length; i++) {
                if (media.media[i].uri) {
                    let storageUrl = await image.getStorageUrl(media.media[i].uri);
                    if (storageUrl.url) {
                        zip.files.push({
                            name: media.media[i].name.substr(media.media[i].uri.lastIndexOf("/") + 1).replace(/\s+/g, '_'),
                            tag: media.media[i].tagName && media.media[i].tagName[3] ? media.media[i].tagName[3] : false,
                            blob: fetch(storageUrl.url).then(response => response.blob()),
                        })
                        zip.count++;
                    }
                }
            }

            var z = new JSZip();
            if (media.type && (media.type === 'MULTICLASS' || media.type === 'MULTILABEL')) {
                //create tags forlder
                if (Object.keys(tags).length) {
                    for (const k of Object.keys(tags)) {
                        zip.folders[tags[k].id] = z.folder(tags[k].id);
                    }
                }
                //insert folder images
                for (let i = 0; i < zip.files.length; i++) {
                    if (zip.files[i].tag) zip.folders[zip.files[i].tag].file(zip.files[i].name, zip.files[i].blob, { base64: true });
                }
            } else {
                zip.folders[datasetID.replace(/\s+/g, '_')] = z.folder(datasetID.replace(/\s+/g, '_'));
                //insert images
                for (let i = 0; i < zip.files.length; i++) {
                    zip.folders[datasetID.replace(/\s+/g, '_')].file(zip.files[i].name, zip.files[i].blob, { base64: true })
                }
            }

            zip.name = datasetID.replace(/\s+/g, '_') + "_media_" + nowDate.getTime() + "_" + zip.count + ".zip";
            await z.generateAsync({ type: "blob" }).then(async function(blob) { saveAs(blob, zip.name); });
            await event.saveEvent('dataset.download', { dataset: datasetID, filename: zip.name, format: "zip", size: zip.files.length }, false); //uid: useStore().state.main.User.uid,

            resp.status = "success";
            resp.name = zip.name;
            resp.images = zip.count;
            resp.message = "The download will start automatically";
        } else { resp.error = "Dataset has no images"; }

        return resp;
    },

    uploadZip: async function (dataset, zip) {
        console.log("Dataset obtained: ", dataset);
        console.log("Zip obtained: ", zip);

        const zipData = {
            images:         zip.images      ? zip.images        : [],
            annotations:    zip.annotations ? zip.annotations   : [],
        }

        const resp = other.httpsCallable('api/dataset/' + dataset.id + '/zip', zipData);
        console.log("Resp: ", resp);
        return resp;
    },

    setImagesUpdatedDate: async function(datasetID) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false, processingCount: 0, updateCount: 0 };

        if (datasetID) {
            let media = await this.getImages({ datasetID: datasetID });
            if (media.count) {
                resp.processingCount = media.count;
                for (var i = 0; i < Object.keys(media.media).length; i++) {
                    if (media.media[i] && !media.media[i].updatedAt) {
                        resp.updateCount++;
                        let docRef = doc(db, 'image', media.media[i].id);
                        await setDoc(docRef, { updatedAt: Timestamp.fromMillis(media.media[i].date) }, { merge: true });
                    }
                }
                resp.status = "success";
            } else { resp.error = "dataset not have images"; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    resetDatasets: async function() {
        let resp               = { status: "error", error: false }
        resp.status            = "success";
        await other.httpsCallable('api/dataset/resetDatasets');
        return resp;
    },

    trackZipUpload: async function (datasetID, status = "uploading", totalImages = 0) {
        const db = _firebase.firestore;
        const datasetRef = doc(db, 'dataset', datasetID);

        const updateData = {
            uploadResume: {
                status:             status,
                totalImages:        totalImages,
                processedImages:    0,
            }
        };

        await setDoc(datasetRef, updateData, { merge: true });
        return { status: "success", message: "uploadResume updated", uploadResume: updateData.uploadResume };
    },

    updateProcessedImages: async function (datasetID, processedImages) {
        const db = _firebase.firestore;
        const datasetRef = doc(db, 'dataset', datasetID);

        await updateDoc(datasetRef, {
            "uploadResume.processedImages": processedImages
        });
    }
}

export default dataset;