'use strict';

import _firebase from './_firebase.js';

import axios from 'axios';

const addres = "https://jupyter.rosepetal.ai/sam"

const ml = {

    initPredictor: async function (key) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(addres + '/init_predictor', null, {
                    headers: {
                        'key': key
                    }
                });
                console.log("Predictor initialized");
                resolve(response.data);
            } catch (error) {
                console.error('Error loading predictor:', error);
                reject(error);
            }
        });
    },

    setImage: async function (img, key) {
        if (img.objApi == "sam") {
            return new Promise((resolve, reject) => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;

                context.drawImage(img, 0, 0);
                canvas.toBlob(async (blob) => {
                    const formData = new FormData();
                    formData.append('file', blob, 'image.png');

                    try {
                        const response = await axios.post(addres + '/set_image', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'key': key
                            }
                        });
                        ("Predictor ready");
                        resolve(response.data);
                    } catch (error) {
                        console.error('Error fetching predictions:', error);
                        reject(error);
                    }
                }, 'image/png');
            });
        }
    },

    predict: async function (predictedPoints, predictedBoundingBoxes, key, mode) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(addres + '/predict', {
                    predictedPoints: predictedPoints,
                    predictedBoundingBoxes: predictedBoundingBoxes,
                    mode: mode
                }, {
                    responseType: 'json',
                    headers: {
                        'key': key
                    }
                });
    
                const data = response.data;
                //console.log("Predictions obtained");

                if (mode === 'detection') {
                    resolve(data.bounding_box);
                } else if (mode === 'segmentation') {
                    resolve(data.path);
                } else {
                    reject(new Error("Unknown mode received"));
                }
            } catch (error) {
                console.error('Error fetching predictions:', error);
                reject(error);
            }
        });
    },

    exit: async function (key) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(addres + '/exit', null, {
                    headers: {
                        'key': key
                    }
                });
                //console.log("Predictor exited");
                resolve(response.data);
            } catch (error) {
                console.error('Error exiting:', error);
                reject(error);
            }
        });
    }
}

export default ml;