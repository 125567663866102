<template>
	<div class="w-full text-gray-900 labelingBox" v-if="layout.dataset" style="min-width: 1200px; z-index: 9999999;height: 100%;background-color: #a0aec0;">

		<!-- MAIN -->
		<div class="grid grid-cols-12 h-full">
			
			<!-- COLUMNA IMÁGENES -->
			<div id="imagesColumn" class="col-span-12 sm:col-span-7 lg:col-span-9 xxl:col-span-10 flex flex-col h-full" 
				:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/back-labeling.png`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100vh;`">
				
				<!-- BARRA DE FILTROS -->
				<div id="rpFiltersLabeling" class="h-12 box rounded-none px-3 py-1 pb-2.5 flex flex-row items-center border-b bg-gray-200 border-gray-400" style="overflow-x: hidden; overflow-y: hidden; scrollbar-width: none; -ms-overflow-style: none;">
					
					<!-- LOGO -->
					<div class="inline-block mr-2" v-if="!showResponsive">
						<img style="height: 36px; width: 36px;" :src="require(`@/assets/images/rosepetal/icon/logoLoading.png`)"/>
					</div>

					<!-- MENÚ RESPONSIVE -->
					<div class="intro-x dropdown w-7 h-7 flex" v-if="showResponsive">
						
						<div class="dropdown-toggle w-7 h-7 image-fit zoom-in scale-110 text-center mt-1" role="button" aria-expanded="true">
							<MoreVerticalIcon class="w-4 h-4" />
						</div>

						<div class="dropdown-menu rpLabelingTopMenu">
							<div class="dropdown-menu__content box bg-gray-200 flex">
								
								<div class="form-check p-4 flex">
									<input id="mediaName" type="checkbox" :checked="layout.optImages.mediaName" class="form-check-input bg-white" @change="layout.optImages.mediaName = layout.optImages.mediaName ? false : true"  />
									<div class="text-sm px-3 ">{{ $t('Name') }}</div>
								</div>

								<div class="form-check p-4 flex" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
									<input id="mediaLabel" type="checkbox" :checked="layout.optImages.mediaLabel" class="form-check-input bg-white" @change="layout.optImages.mediaLabel = layout.optImages.mediaLabel ? false : true"  />
									<div class="text-sm px-3 ">{{ $t('Tags') }}</div>
								</div>

								<div class="form-check p-4 flex" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
									<input id="mediaMask" type="checkbox" :checked="layout.optImages.mediaMask" class="form-check-input bg-white" @change="layout.optImages.mediaMask = layout.optImages.mediaMask ? false : true; reload()"  />
									<div class="text-sm px-3 ">{{ $t('Mask') }}</div>
								</div>

								<div class="form-check p-4 flex" v-if="layout.dataset.type=='imageObjectDetection'">
									<input id="mediaBounding" type="checkbox" :checked="layout.optImages.mediaBounding" class="form-check-input bg-white" @change="layout.optImages.mediaBounding = layout.optImages.mediaBounding ? false : true; reload()"  />
									<div class="text-sm px-3 ">{{ $t('Tags') }}</div>
								</div>

								<div class="form-check p-4 flex">
									<input id="mediaSet" type="checkbox" :checked="layout.optImages.mediaSet" class="form-check-input bg-white" @change="layout.optImages.mediaSet = layout.optImages.mediaSet ? false : true"  />
									<div class="text-sm px-3 ">{{ $t('Set') }}</div>
								</div>

								<div class="form-check p-4 flex">
									<input id="mediaDate" type="checkbox" :checked="layout.optImages.mediaDate" class="form-check-input bg-white" @change="layout.optImages.mediaDate = layout.optImages.mediaDate ? false : true"  />
									<div class="text-sm px-3 ">{{ $t('Date') }}</div>
								</div>

							</div>
						</div>

					</div>

					<div id="responsive-group" class="flex" v-if="!showResponsive">

						<!-- SHOW NAME -->
						<div class="responsive-element form-check pr-5 ml-3 mt-1">
							<input id="mediaName" type="checkbox" :checked="layout.optImages.mediaName" class="form-check-input bg-white" @change="layout.optImages.mediaName = layout.optImages.mediaName ? false : true"  />
							<label class="form-check-label pt-0.5" for="mediaName">{{ $t('Name') }}</label>
						</div>

						<!-- SHOW TAGS -->
						<div class="responsive-element form-check px-5 border-l mt-1" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
							<input id="mediaLabel" type="checkbox" :checked="layout.optImages.mediaLabel" class="form-check-input bg-white" @change="layout.optImages.mediaLabel = layout.optImages.mediaLabel ? false : true;" />
							<label class="form-check-label pt-0.5" for="mediaLabel">{{ $t('Tags') }}</label>
						</div>

						<!-- SHOW MASK -->
						<div class="responsive-element form-check px-5 border-l mt-1" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
							<input id="mediaMask" type="checkbox" :checked="layout.optImages.mediaMask" class="form-check-input bg-white" @change="layout.optImages.mediaMask = layout.optImages.mediaMask ? false : true; reload()"  />
							<label class="form-check-label pt-0.5" for="mediaMask">{{ $t('Mask') }}</label>
							<div class="intro-x dropdown w-7 h-7 flex">
								<div class="dropdown-toggle w-7 h-7 image-fit zoom-in scale-110 text-center mt-1" role="button" aria-expanded="true">
									<MoreVerticalIcon class="w-4 h-4 ml-2" />
								</div>
								<div class="dropdown-menu rpLabelingTopMenu">
									<div class="dropdown-menu__content box bg-gray-200 flex">
										<div class="form-check p-4 flex">
											<div class="text-sm pr-3 ">{{ $t('Opacity') }}:</div>
											<input type="range" name="quantity" min="0" max="1" step="0.1" v-model="layout.optImages.maskOpacity" class="cursor-pointer mt-1.5" 
											:disabled="!layout.dataset?.images?.media || !layout.dataset?.images?.media.length ? true : false">
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- SHOW BOUNDING BOXES -->
						<div class="responsive-element form-check px-5 border-l mt-1" v-if="layout.dataset.type=='imageObjectDetection'">
							<input id="mediaBounding" type="checkbox" :checked="layout.optImages.mediaBounding" class="form-check-input bg-white" @change="layout.optImages.mediaBounding = layout.optImages.mediaBounding ? false : true; reload()"  />
							<label class="form-check-label pt-0.5" for="mediaBounding">{{ $t('Tags') }}</label>
						</div>

						<!-- SHOW SET -->
						<div class="responsive-element form-check px-5 border-l mt-1">
							<input id="mediaSet" type="checkbox" :checked="layout.optImages.mediaSet" class="form-check-input bg-white" @change="layout.optImages.mediaSet = layout.optImages.mediaSet ? false : true"  />
							<label class="form-check-label pt-0.5" for="mediaSet">{{ $t('Set') }}</label>
						</div>

						<!-- SHOW DATE -->
						<div class="responsive-element form-check px-5 border-l mt-1">
							<input id="mediaDate" type="checkbox" :checked="layout.optImages.mediaDate" class="form-check-input bg-white" @change="layout.optImages.mediaDate = layout.optImages.mediaDate ? false : true"  />
							<label class="form-check-label pt-0.5" for="mediaDate">{{ $t('Date') }}</label>
						</div>

					</div>

					<template v-if="layout.dataset?.images?.media && layout.dataset?.images?.media.length">

						<!-- SELECTOR SIZE -->
						<div class="fixed-element form-check border-l pl-2 mt-1 mr-4">
							<span class="text-sm pl-2 pr-3" v-if="!showResponsive">{{ $t('Size') }}:</span>
							<span class="inline-block align-middle">
								<input type="range" name="quantity" min="2" max="4" v-model="layout.optImages.mediaSize" class="cursor-pointer mt-1.5" :disabled="!layout.dataset?.images?.media || !layout.dataset?.images?.media.length ? true : false">
							</span>
						</div>

						<!-- SELECTOR ORDER -->
						<div class="fixed-element form-check border-l pl-2 mt-1 mr-4">
							
							<span class="text-sm pl-2 pr-3" v-if="!showResponsive">{{ $t('Order') }}:</span>
							
							<select class="w-32 form-select p-1 pl-3 cursor-pointer bg-white" v-model="layout.optImages.order" >
								<option value="date">{{$t('Created')}}</option>
								<option value="updatedAt">{{$t('Updated')}}</option>
							</select>

							<select class="w-20 form-select p-1 pl-3 cursor-pointer bg-white" v-model="layout.optImages.direction" >
								<option value="asc">{{$t('Asc')}}</option>
								<option value="desc">{{$t('Desc')}}</option>
							</select>

						</div>

						<!-- SELECTOR PERPAGE -->
						<div class="fixed-element form-check border-l pl-2 mt-1 mr-4">
							<span class="text-sm pl-2 pr-3" v-if="!showResponsive">{{ $t('Per page') }}:</span>
							<select class="w-20 form-select p-1 pl-3 cursor-pointer bg-white" v-model="layout.optImages.perPage" >
								<option v-bind:value="12">12</option>
								<option v-bind:value="50">50</option>
								<option v-bind:value="125">125</option>
								<option v-bind:value="250">250</option>
								<!-- <option v-bind:value="1000">1000</option> -->
							</select>
						</div>

						<!-- PAGINATION -->
						<div class="fixed-element form-check float-right items-center ml-auto" v-if="layout.dataset?.images?.pagination">
							<ul class="pagination items-center float-right mt-2 mr-5">
								<li v-if="layout.dataset.images.pagination.prev && (layout.dataset.images.pagination.currentPage + 1) > 2" class="w-10">
									<button class="pagination__link" @click="layout.optImages.action='firstPage'; reload()">
										<ChevronsLeftIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" />
									</button>
								</li>
								
								<li v-if="layout.dataset.images.pagination.prev" class="w-10">
									<button class="pagination__link" @click="layout.optImages.action='previousPage'; reload()">
										<ChevronLeftIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" />
									</button>
								</li>
								<li>
									<span class="pagination__link" style="cursor: default !important" v-if="(layout.dataset.images.pagination.pages > 2) && ((layout.dataset.images.pagination.currentPage + 1) > 2)">
										<span class="text-gray-500">...</span>
									</span>
								</li>								
								<li v-for="n in (layout.dataset.images.pagination.pages ?? 0)" :key="n">
									<button class="h-7 pagination__link pl-0 pr-0" :class="n==(layout.dataset.images.pagination.currentPage + 1) ? 'bg-gray-400 text-theme 5' : 'text-blue-500'" style="cursor: default !important"
										v-if="(n < ((layout.dataset.images.pagination.currentPage + 1) + 2)) && (n > ((layout.dataset.images.pagination.currentPage + 1) - 2))">{{ n }}
									</button>
								</li>							
								<li>
									<span class="pagination__link" style="cursor: default !important" v-if="(layout.dataset.images.pagination.pages > 2) && ((layout.dataset.images.pagination.currentPage + 1) < (layout.dataset.images.pagination.pages - 1))">
										<span class="text-gray-500">...</span>
									</span>
								</li>	
								<li v-if="layout.dataset.images.pagination.next" class="w-10">
									<button class="pagination__link" @click="layout.optImages.action='nextPage'; reload()">
										<ChevronRightIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" />
									</button>
								</li>

								<li v-if="layout.dataset.images.pagination.next && (layout.dataset.images.pagination.currentPage + 1) < (layout.dataset.images.pagination.pages - 1)" class="w-10">
									<button class="pagination__link" @click="layout.optImages.action='lastPage'; reload()">
										<ChevronsRightIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" />
									</button>
								</li>
							</ul>
						</div>

					</template>

				</div>
				
				<!-- SELECTION AND ACTIONS -->
				<div id="selectionSection" v-if="!completeDataset && countCheck() && !layout.loading" class="grid grid-cols-12">
					<div class="col-span-12 bg-white">
						<div class="flex rounded px-5 py-1 mx-auto bg-white">
							<div class="flex items-center pl-3">
								<span class="text-blue-500 mr-5 cursor-pointer" @click="check()">{{ $t('Select all') }}</span>
								<span class="text-blue-500 cursor-pointer" @click="unCheck()">{{ $t('Deselect all') }}</span>
							</div> 
							<div class="flex pl-12 py-1 items-center">
								<span class="mr-10"><span class="text-3xl font-bold align-middle pr-2">{{countCheck()}}</span><span class="align-middle text-sm">{{ $t('media selected') }}</span></span>
								<button v-if="layout.dataset.type=='MULTICLASS'" class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-blue-500 hover:text-white" @click="modal('modal-labeling')">{{ $t('Assign tag') }}</button>
								<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-blue-500 hover:text-white" @click="modal('modal-change-set')">{{ $t('Data division') }}</button>
								<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="modal('modal-download-selected')">{{ $t('Download') }}</button>
								<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="layout.move.success = false; layout.move.toDataset = false; modal('modal-move-selected'); ">{{ $t('Move') }}</button>
								<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="layout.copy.success = false; layout.copy.toDataset = false; modal('modal-copy-selected')">{{ $t('Copy') }}</button>
								<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-red-500 hover:text-white" @click="modal('modal-delete')"><TrashIcon class="w-5 h-5 cursor-pointer" /></button>
							</div>
						</div>
					</div>
				</div>

				<!-- IMAGES SECTION -->
				<div id="imagesSection" class="h-full flex-grow chat grid grid-cols-12 gap-6 overflow-x-auto overflow-y-auto" style="-ms-overflow-style: none; scrollbar-width: none;">
					
					<!-- LOADING -->
					<div id="loadingSection" class="col-span-12 flex flex-grow items-center bg-white" v-if="layout.loading">
						<div class="flex items-center justify-center w-full h-full"
							:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train7.gif`)}); background-position: top center; background-repeat: no-repeat;`"></div>
					</div>

					<!-- LISTADO IMAGES -->
					<div id="imagesList" class="col-span-12 text-left text-xs w-full h-full" v-else>
						
						<!-- IMAGES -->
						<div class="intro-y grid grid-cols-12 gap-3 p-5" v-if="layout.dataset?.images?.media && layout.dataset?.images?.media.length">
							<div v-for="(image, idx) in layout.dataset?.images?.media" v-bind:key="image" class="intro-y col-span-2" :class="'sm:col-span-' + layout.optImages.mediaSize + ' md:col-span-' + layout.optImages.mediaSize + ' lg:col-span-' + layout.optImages.mediaSize + ' xxl:col-span-' + layout.optImages.mediaSize">
								<div class="file box rounded-md px-2 pb-2" :class="Object.values(selectedImages).includes(image.id) ? 'border-2  border-blue-600 bg-gray-200' : ''">
									
									<!-- BOX DATA -->
									<div class="relative intro-y flex items-center py-4">

										<!-- CHECK SELECTOR IMG -->
										<input class="form-check-input border border-gray-900" v-model="selectedImages" :value="image.id" type="checkbox" /> 
										
										<!-- COMMENTS ICON -->
										<div v-if="image?.comments" class="image-tags ml-2">
											<div class="bg-gray-400 rounded py-0.5" title="Comments">
												<AlignLeftIcon class="w-4 h-4 mx-1 text-white" title="Comments" />
											</div>
										</div>

										<!-- TYPE -->
										<div class="image-tags absolute ml-6" v-if="layout.optImages.mediaLabel" :class="image?.comments ? 'pl-7' : ''">
											<span class="py-1 px-3 rounded-md cursor-pointer font-medium text-center text-xs text-truncate" :class="!layout.dataset?.tagsCounter?.colors[image.tagName[3]] ? 'bg-gray-300 text-gray-600' : 'text-white'"
												:style="layout.dataset?.tagsCounter?.colors[image.tagName[3]] ? 'background-color: '+layout.dataset?.tagsCounter?.colors[image.tagName[3]]+' !important' : ''" v-if="image?.tagName && image.tagName[3] && (layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL')">
												{{ 
													image.tagName[3]=="0" ? 
													layout.dataset.type=='MULTILABEL' ? 'Anomaly' : 'Unclassified'
													: layout.dataset?.tagsCounter?.names[image.tagName[3]]
												}}
											</span>
										</div>

										<!-- EYE EDIT IMAGE -->
										<div class="w-6 inline-flex absolute right-0 cursor-pointer" @click="goEditImage(image.id)">
											<EyeIcon class="w-12 h-12 mr-1" title="View" />
										</div>

									</div>

									<!-- IMAGE DATA -->
									<div class="relative block items-center">

										<!-- IMAGE -->
										<div :id="'ThumbcanvasBox_' + image.id" class="w-full h-full labelingListCanvas overflow-hidden" @click="checkItem(image)">
											<canvas :id="'Thumbcanvas_' + image.id"></canvas>
										</div>

										<!-- DIVISION -->
										<div class="image-tags absolute top-0 mt-2 ml-1 " v-if="image.set && layout.optImages.mediaSet">
											<span class="py-0.5 px-2 rounded-md cursor-pointer font-medium text-center text-xs text-truncate bg-gray-300 text-gray-600 border border-gray-500" >
												{{ image.set }}
											</span>
										</div>

										<!-- INDEX -->
										<div class="image-number absolute top-0 right-0 mt-2 mr-1 ">
											<span class="py-1 px-3 rounded-md cursor-pointer font-medium text-right text-xs text-truncate text-white">
												{{ idx + 1 }} 
											</span>
										</div>

										<!-- NAME -->
										<div class="text-sm mt-2 ml-1 font-medium  text-left truncate cursor-default" v-if="image?.name && layout.optImages.mediaName">{{ image.name.toString().split('/').pop() }}</div>

										<!-- CREATION DATE -->
										<div class="text-sm mt-1 ml-1 text-left cursor-default" v-if=" layout.optImages.order == 'date' && image?.createdDate && layout.optImages.mediaDate">{{ image.createdDate }}</div>
										<div class="text-sm mt-1 ml-1 text-left cursor-default" v-if=" layout.optImages.order == 'updatedAt' && image?.updatedDate && layout.optImages.mediaDate">{{ image.updatedDate }}</div>

									</div>
									
								</div>
							</div>
						</div>

						<!-- NO IMAGES -->
						<div v-else class="text-center w-full h-full">
							<div v-if="layout.dataset?.tagsCounter && layout.dataset?.images?.media" class="p-20 h-full bg-white text-center text-gray-600;"> 
								<div class="flex items-center justify-center w-full bg-white"><img :src="require(`@/assets/images/rosepetal/icon/noResults.jpg`)" /></div>
								<div>{{ $t('No images found for the selected filters') }}.</div>
								<div>{{ $t('Please try adjusting your filter criteria') }}.</div>
							</div>
							<div class="col-span-12 lg:col-span-12 flex flex-grow items-center" v-else>
								<div class="flex items-center justify-center w-full h-full bg-white" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train7.gif`)}); background-position: top center; background-repeat: no-repeat; height: 100%; height: 100vh;`"></div> 
							</div>
						</div>

					</div>

				</div>

			</div>

			<!-- COLUMNA STATS -->
			<div id="statsColumn" class="col-span-12 sm:col-span-5 lg:col-span-3 xxl:col-span-2 flex flex-col h-full bg-white" style="overflow-y: auto; border-left: 1px solid #ddd;">
				
				<!-- BARRA DE EXIT -->
				<div id="barraExit" class="h-15 editorSaveTools flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center bg-theme-15">
					<button id="BtnLabelingReload" type="button" class="btn w-1/3 hover:bg-green-700 hover:text-white flex items-center text-sm py-3 text-ms editorSaveBtn" 
						style="border-radius: 0 !important; font-weight: normal" @click="reload();">
						<RefreshCwIcon class="w-6 h-5" />
					</button>

					<button id="BtnLabelingExit" type="button" data-dismiss="modal" class="btn w-full text-gray-900 hover:bg-blue-500 hover:text-white flex items-center text-sm py-3 editorSaveBtn"
						style="border-radius: 0 !important" @click="hideLabeling()">
						<Log-outIcon class="w-8 h-5" />
					</button>
				</div>

				<!-- STATS SECTION-->
				<div id="statsSection" class="h-full flex-grow rpLabelingMenu overflow-y-auto" style="scrollbar-width: none; -ms-overflow-style: none;">
					<ul>

						<li id="eMdataset" class="selected nav" @click="labelingMenu('eMdataset')">
							<span>{{ $t('Dataset') }}</span><span class="arrow"></span>
						</li>
						
						<!-- DATASET INFO -->
						<li class="menu-content eMdataset sel">
							<div class="font-medium text-sm" v-if="layout.dataset?.name">{{ layout.dataset.name }}</div>
							<div class="font-normal text-gray-600" v-if="layout.dataset?.typeName">{{ layout.dataset.typeName }}</div>
							<div class="pt-2 mr-5 text-gray-600" v-if="layout.dataset?.description">{{ layout.dataset?.description.toString() }}</div>
							<div class="flex justify-center mt-2">
								<button class="btn w-auto bg-gray-100 text-gray-600 zoom-in font-normal py-1" type="button" v-if="layout.dataset?.name" @click="downloadDataset();">
									<DownloadIcon class="w-6 h-6 mr-3" /> <span>{{$t('Dataset')}}</span>
								</button>
								<button class="btn w-auto bg-gray-100 text-gray-600 zoom-in font-normal py-1" type="button" v-if="layout.dataset?.name" @click="downloadCSV();">
									<DownloadIcon class="w-6 h-6 mr-3" /> <span>{{$t('CSV')}}</span>
								</button>
							</div>
						</li>

						<!-- IMG OBJECT DETECTION -->
						<template v-if="layout.dataset.type == 'imageObjectDetection'">
							
							<li id="eMobjImages" class="selected nav" @click="labelingMenu('eMobjImages')"><span>{{ $t('Images') }}</span><span class="arrow"></span></li>

							<li class="menu-content eMobjImages sel">
								<div v-for="tagType in ['all', 'labeled', 'notLabeled']" :key="tagType" 
									@click="!layout.loading ? layout.optImages.objtagsType = tagType : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
									class="cursor-pointer border p-2 pb-3 rounded" :class="layout.optImages.objtagsType==tagType ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">

									<div v-if="tagType == 'all'">
										<span class="text-sm inline-block mt-0.5">{{ $t('All images') }}</span>
										<div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType == tagType ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : layout.dataset?.tagsCounter['count'] }}
										</div>
									</div>

									<div v-else> <!-- labeled, notLabeled -->
										<span class="text-sm inline-block mt-0.5">{{ $t(tagType.charAt(0).toUpperCase() + tagType.slice(1).replace(/([A-Z])/g, ' $1')) }}</span>
										<div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType == tagType ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : layout.dataset?.tagsCounter[tagType] }}
										</div>
									</div>

								</div>
							</li>

							<template v-if="layout.dataset?.tagsCounter && layout.optImages.objtagsType == 'labeled'">
								<li id="eMobjTags" class="selected nav" @click="labelingMenu('eMobjTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>
								<li class="menu-content eMobjTags sel">
									
									<template v-if="Object.keys(labeledTags).length <= 50">
										<div @click="!layout.loading ? layout.optImages.objByTag = 'all' : '';" class="cursor-pointer border p-3 py-2 rounded mb-2" :style="layout.loading ? 'cursor: not-allowed' : ''"
											:class="layout.optImages.objByTag=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
											<span class="text-sm inline-block mt-0.5">{{ $t('All tags') }}</span> 
										</div>
										<div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" v-for="(t,idx) in labeledTags" :key="idx" :style="layout.loading ? 'cursor: not-allowed' : ''"
											class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
											<div class="inline-block align-middle mr-2"><div class="mr-1 rounded display-inline roundTagIcon h-5 w-5" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
											<div class="text-sm inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
											<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-50 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
												{{ t ? this.helper.formatScore(t) : "0" }} {{ $t('annotations') }}  {{ layout.dataset?.tagsCounter?.tagsLabeledImg && layout.dataset?.tagsCounter?.tagsLabeledImg[idx] ? 'in ' + layout.dataset?.tagsCounter?.tagsLabeledImg[idx]  + ' images': '' }}
											</div>
										</div>
									</template>

									<template v-else>
										<select class="form-select bg-blue-500 text-white cursor-pointer w-full mr-5 " v-model="layout.optImages.objByTag">
											<option value="all">{{ $t('All tags') }}</option>
											<option :value="idx" v-for="(t,idx) in labeledTags" :key="idx">
											{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t }} {{ $t('annotations') }} 
											{{ layout.dataset?.tagsCounter && layout.dataset?.tagsCounter?.tagsLabeledImg && layout.dataset?.tagsCounter?.tagsLabeledImg[idx] ? 'in ' + layout.dataset?.tagsCounter?.tagsLabeledImg[idx] + ' images' : '' }})</option>
										</select>
									</template>
									
								</li>
							</template>

						</template>

						<!-- MULTILABEL -->
						<template v-if="layout.dataset.type == 'MULTILABEL'">
							
							<li id="eMobjImages" class="selected nav" @click="labelingMenu('eMobjImages')"><span>{{ $t('Images') }}</span><span class="arrow"></span></li>

							<li class="menu-content eMobjImages sel">
								<div v-for="tagType in ['all', 'normal', 'anomaly']" :key="tagType" 
									@click="!layout.loading ? layout.optImages.objtagsType = tagType : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
									class="cursor-pointer border p-2 pb-3 rounded" :class="layout.optImages.objtagsType==tagType ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">

									<div v-if="tagType == 'all'">
										<span class="text-sm inline-block mt-0.5">{{ $t('All images') }}</span>
										<div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType == tagType ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : layout.dataset?.tagsCounter['count'] }}
										</div>
									</div>

									<div v-else> <!-- normal, anomaly -->
										<span class="text-sm inline-block mt-0.5">{{ $t(tagType.charAt(0).toUpperCase() + tagType.slice(1).replace(/([A-Z])/g, ' $1')) }}</span>
										<div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType == tagType ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : layout.dataset?.tagsCounter[tagType] }}
										</div>
									</div>

								</div>
							</li>

							<template v-if="layout.dataset?.tagsCounter && layout.optImages.objtagsType == 'anomaly'">
								<li id="eMobjTags" class="selected nav" @click="labelingMenu('eMobjTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>
								<li class="menu-content eMobjTags sel">

									<template v-if="Object.keys(labeledTags).length <= 50">
										<div @click="!layout.loading ? layout.optImages.objByTag = 'all' : '';" class="cursor-pointer border p-3 py-2 rounded mb-2" :style="layout.loading ? 'cursor: not-allowed' : ''"
											:class="layout.optImages.objByTag=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
											<span class="text-sm inline-block mt-0.5">{{ $t('All tags') }}</span>
										</div>
										<div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" v-for="(t,idx) in labeledTags" :key="idx" :style="layout.loading ? 'cursor: not-allowed' : ''"
											class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
											<div class="inline-block align-middle mr-2"><div class="mr-1 rounded display-inline roundTagIcon h-5 w-5" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
											<div class="text-sm inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
											<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-50 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
												{{ t ? this.helper.formatScore(t) : "0" }} {{ $t('annotations') }}  {{ layout.dataset?.tagsCounter?.tagsLabeledImg && layout.dataset?.tagsCounter?.tagsLabeledImg[idx] ? 'in ' + layout.dataset?.tagsCounter?.tagsLabeledImg[idx]  + ' images': '' }}
											</div>
										</div>
									</template>

									<template v-else>
										<select class="form-select bg-blue-500 text-white cursor-pointer w-full mr-5 " v-model="layout.optImages.objByTag">
											<option value="all">{{ $t('All tags') }}</option>
											<option :value="idx" v-for="(t,idx) in labeledTags" :key="idx">
											{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t }} {{ $t('annotations') }} 
											{{ layout.dataset?.tagsCounter && layout.dataset?.tagsCounter?.tagsLabeledImg && layout.dataset?.tagsCounter?.tagsLabeledImg[idx] ? 'in ' + layout.dataset?.tagsCounter?.tagsLabeledImg[idx] + ' images' : '' }})</option>
										</select>
									</template>

								</li>
							</template>

						</template>

						<!-- MULTICLASS -->
						<template v-if="layout.dataset.type == 'MULTICLASS'">
							
							<li id="eMmultiTags" class="selected nav" @click="labelingMenu('eMmultiTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>

							<li class="menu-content eMmultiTags sel">
								
								<template v-if="layout.dataset?.tagsCounter">

									<template v-if="Object.keys(labeledTags).length<=50">
										<div @click="!layout.loading ? layout.optImages.objByTag = 'all' : '';" 
											class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.objByTag=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'" :style="layout.loading ? 'cursor: not-allowed' : ''">
											<span class="text-sm inline-block mt-0.5">{{ $t('All') }} </span> 
											<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.objByTag == 'all' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
												{{ layout.dataset?.tagsCounter?.count ? this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }}
											</div>
										</div>
										<div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" 
											v-for="(t, idx) in labeledTags" :key="idx"  :style="layout.loading ? 'cursor: not-allowed' : ''"
											class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
											<div class="inline-block align-middle mr-1"><div class="rounded display-inline roundTagIcon h-6 w-6" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
											<div class="text-xs inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
											<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
												{{ t ? this.helper.formatScore(t) : "0" }}
											</div>
										</div>
									</template>

									<template v-else>
										<select class="form-select bg-white cursor-pointer w-full mr-5 text-theme-14" v-if="layout.dataset.type=='MULTICLASS'" 
											v-model="layout.optImages.objByTag" @change="reload()">
											<option value="all">{{ $t('All') }}  ({{ layout.dataset?.tagsCounter?.count ? this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }})</option>
											<option :value="idx" v-for="(t,idx) in layout.dataset.tagsCounter.tags" :key="idx">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t ? this.helper.formatScore(t) : "0" }})</option>
										</select>
									</template>

								</template>

								<template v-else>
									<div class="w-52 mr-5 inline-block"><div class="w-8 mr-5 inline-block"><LoadingIcon icon="three-dots" class="w-8 h-8 text-white" /></div></div>
								</template>

							</li>

						</template>
						
						<!-- DIVISION SECTION -->
						<li id="eMdatadivision" class="selected nav" @click="labelingMenu('eMdatadivision')"><span>{{ $t('Data division') }}</span><span class="arrow"></span></li>

						<li class="menu-content eMdatadivision sel">
							<template v-if="layout.dataset?.tagsCounter">
								<div v-for="division in ['all', 'train', 'test', 'validation', 'predetermined']" :key="division"
									@click="!layout.loading ? layout.optImages.division = division : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
									class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division == division ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">

									<div v-if="division == 'all'">
										<span class="text-sm inline-block mt-0.5">{{ $t('All division') }} </span>
										<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division == division ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : this.helper.formatScore(layout.dataset?.dataDivision['total']) }}
										</div>
									</div>
									<div v-else>
										<span class="text-sm inline-block mt-0.5">{{ $t(division.charAt(0).toUpperCase() + division.slice(1)) }}</span>
										<div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division == division ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
											{{ layout.loading  ? '...' : this.helper.formatScore(layout.dataset?.dataDivision[division]) }}
										</div>
									</div>

								</div>
							</template>
						</li>

					</ul>
				</div>

			</div>
			
		</div>

		<!-- MODAL ASSIGN TAG -->
		<div id="modal-labeling" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body p-5 text-center">
						<h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Tag assignment') }}</h2>
						<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="layout.actions.assingLoad">
							<LoadingIcon icon="ball-triangle" class="w-12 h-12" />
						</div>
						<div v-else>
							<div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{countCheck()}}</div>
							<div class="text-xs truncate mb-1"> {{ $t('Select the tag you want to assign') }}: </div>
							<div class="pt-3 pb-5 text-left" v-if="layout.dataset.tagsCounter">
								<div v-for="(t,idx) in layout.dataset.tagsCounter.tags" :key="idx">  
								<button class="btn mb-1 hover:bg-blue-500 hover:text-white w-full text-left font-normal" @click="layout.actions.assingTag=idx"
									:class="layout.actions.assingTag===idx? 'bg-blue-500 text-white' : 'text-gray-600'">
									<div class="float-left w-12"><div class="h-8 w-8 rounded display-inline" :style="layout.dataset.tagsCounter.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
									<div class="float-right w-80 ml-1 text-left text-sm"><span class="text-left">{{ layout.dataset.tagsCounter.names[idx] }} <span class="float-right ">{{ t }}</span></span></div>
								</button>
								</div>
							</div> 
							<div class="px-5 pb-3 text-center">
								<button id="dismiss-modal-labeling" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
								<button type="button" class="btn bg-green-600 text-white w-32" @click="categorize()">{{ $t('Confirm') }}</button>
							</div>
						</div>  
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DATA DIVISION -->
		<div id="modal-change-set" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"  v-if="!layout.actions.assingLoad"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body p-5 text-center">
						<h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Data division') }}</h2>
						<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="layout.actions.assingLoad">
							<LoadingIcon icon="ball-triangle" class="w-12 h-12" />
						</div>
						<div v-else>
							<div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{countCheck()}}</div>
							<div class="text-xs truncate mb-3"> {{ $t('Select the division you want to assign') }} </div>
							<select class="form-select bg-white cursor-pointer w-52 p-2" v-model="layout.actions.assingSet">
								<option value="train">{{ $t('Train') }}</option>
								<option value="test">{{ $t('Test') }}</option>
								<option value="validation">{{ $t('Validation') }}</option>
								<option value="predetermined">{{ $t('Predetermined') }}</option>
							</select> 
							<div class="text-left text-xs bg-gray-200 rounded p-5 m-5">
								<div class="text-xl"><img class="w-5 h-5 inline-block mr-1.5 align-top mt-1" :src="require(`@/assets/images/rosepetal/icon/info.png`)" />  {{ $t('About data division') }}</div>
								<div class="mt-3"><div class="font-medium text-sm">{{ $t('Train') }}</div>{{ $t('Used to adjust the model parameters') }}.</div>
								<div class="mt-2"><div class="font-medium text-sm">{{ $t('Test') }}</div>{{ $t('Used to evaluate the final performance of the model on unseen data and prevent overfitting') }}.</div>
								<div class="mt-2 mb-3"><div class="font-medium text-sm">{{ $t('Validation') }}</div>{{ $t('Used to evaluate the model performance during training and make adjustments to the model') }}.</div>
							</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-labeling" type="button"
									data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
								<button type="button" class="btn btn-primary w-24" v-if="layout.actions.assingSet" @click="setDataDivision()">{{ $t('Confirm') }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DOWNLOAD IMAGES -->
		<div id="modal-download-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer"  @click="if (completeDataset) unCheck();" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body px-5 text-center">

						<div v-if="layout.zip.loading" class="pb-2">
							<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
							<div class="text-normal">{{ $t('Creating Zip file...') }} ({{ layout.zip.counter }} / {{ selectedImages.length }})</div>
							<div class="text-normal pb-5" v-if="selectedImages.length && layout.zip.counter == selectedImages.length">
								{{ $t('Your download will start automatically') }}
								<div>{{ $t('Please wait...') }}</div>
							</div>
						</div>
						<div v-else>
							<div class="p-2 text-center">
								<DownloadCloudIcon class="w-16 h-16 text-theme-1 mx-auto" />
								<div class="text-2xl ">{{ $t('Download') }}</div>
								<div class="text-normal">{{ selectedImages.length }} {{ $t('selected media') }}</div>
								<input v-if="selectedImages.length === 1" type="checkbox" v-model="downloadImg">
								<label v-if="selectedImages.length === 1" type="checkbox" class="ml-2 text-gray-700">{{ $t('Download just the image') }}</label>
							</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-download-selected" type="button" @click="if (completeDataset) unCheck();" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
								<button type="button" @click="downloadMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Download') }}</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		
		<!-- MODAL MOVE IMAGES -->
		<div id="modal-move-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body px-5 text-center">
						<div class="text-xl text-left">{{ $t('Move images') }}</div>

						<div v-if="layout.move.loading" class="pb-2">
							<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
							<div class="text-normal">{{ $t('Moving images') }} ({{layout.move.counter}}/{{ selectedImages.length }})</div>
							<div>{{ $t('Please wait...') }}</div>
						</div>

						<div v-else-if="layout.move.success" class="text-left">
							{{ $t('Images have been moved correctly') }}
							<div class="px-5 pb-3 text-center mt-5">
								<button type="button" data-dismiss="modal" class="btn btn-primary w-24 rpbtn1">{{ $t('OK') }}</button>
							</div>
						</div>

						<div v-else class="p-2 text-left">
							<div class="text-normal"><span class="font-bold">{{ selectedImages.length }}</span> {{ $t('selected media') }}</div>
							<div class="text-sm truncate mb-3 mt-3 text-center"> {{ $t('Select the destination dataset') }} </div>
							<select class="form-select bg-white cursor-pointer w-full p-2" v-model="layout.move.toDataset">
								<option v-for="dataset in actionsDatasets" :value="dataset.id" :key="dataset.id">{{ dataset.name.toString().toLowerCase() }}</option>
							</select> 
							<div class="mt-2 text-xs text-center">{{ $t('Images will be placed in the unclassified tag') }}</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
								<button type="button" @click="moveMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Move') }}</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- MODAL COPY IMAGES -->
		<div id="modal-copy-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body px-5 text-center">
						<div class="text-xl text-left">{{ $t('Copy images') }}</div>

						<div v-if="layout.copy.loading" class="pb-2">
							<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
							<div class="text-normal">{{ $t('Copying images') }} ({{layout.copy.counter}}/{{ selectedImages.length }})</div>
							<div>{{ $t('Please wait...') }}</div>
						</div>

						<div v-else-if="layout.copy.success" class="text-left">
							{{ $t('Images have been copied correctly') }}
							<div class="px-5 pb-3 text-center mt-5">
								<button type="button" data-dismiss="modal" class="btn btn-primary w-24 rpbtn1">{{ $t('OK') }}</button>
							</div>
						</div>

						<div v-else class="p-2 text-left">
							<div class="text-normal"><span class="font-bold">{{ selectedImages.length }}</span> {{ $t('selected media') }}</div>
							<div class="text-sm truncate mb-3 mt-3 text-center"> {{ $t('Select the destination dataset') }} </div>
							<select class="form-select bg-white cursor-pointer w-full p-2" v-model="layout.copy.toDataset">
								<option v-for="dataset in actionsDatasets" :value="dataset.id" :key="dataset.id">{{ dataset.name.toString().toLowerCase() }}</option>
							</select> 
							<div class="mt-2 text-xs text-center">{{ $t('Images will be placed in the unclassified tag') }}</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
								<button type="button" @click="copyMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Copy') }}</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DELETE IMAGES --> 
		<div id="modal-delete" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-body p-5 text-center">
						<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
						<div class="p-2 text-center">
							<div class="text-xl mt-5">{{ $t('Are you sure you want to delete') }}<br> {{ selectedImages.length }} {{ $t('selected media') }}?</div>
							<div class="text-gray-600 mt-2">{{ $t('This action is irreversible') }}.</div>
						</div>
						<div class="px-5 pb-8 text-center">
							<button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
							<button type="button" class="btn btn-danger w-24" data-dismiss="modal" @click="deleteImages()">{{ $t('Continue') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch, nextTick } from "vue";
import { useRouter }		from "vue-router";
import { fabric } 			from 'fabric'
import cash     			from "cash-dom";
import lzstring 			from 'lz-string';

import * as rosepetalModel	from "rosepetal-model";
import config				from '@/etc/rosepetal.json';
import { helper as $h } 	from "@/utils/helper";

let $dataset	= rosepetalModel.default.dataset;
let $image		= rosepetalModel.default.image;
let $model		= rosepetalModel.default.model;
let $vision		= rosepetalModel.default.vision;

export default defineComponent({
	props: {
		options: {
			type: Object,
			required: true
		},
	},

	computed: {
		labeledTags() { 
			console.log("Computed labeledTags");
			const tags = {};
			if (this.layout.dataset.type === 'MULTICLASS') {
				for (const key in this.layout.dataset.tagsCounter.tags) { if (this.layout.dataset.tagsCounter.tags[key]) tags[key] = this.layout.dataset.tagsCounter.tags[key] }
				const keys = Object.keys(tags).sort();
				const sortedTags = {};
				for (let i = 0; i < keys.length; i++) sortedTags[keys[i]] = tags[keys[i]];
				return sortedTags;
			} else {
				for (const key in this.layout.dataset.tagsCounter.tagsLabeled) { if (this.layout.dataset.tagsCounter.tagsLabeled[key]) tags[key] = this.layout.dataset.tagsCounter.tagsLabeled[key] }
				return tags;
			}
		},

		noLabelTags() { 
			console.log("Computed noLabelTags");
			const tags = {};
			for (const key in this.layout.dataset.tagsCounter.tagsLabeled) { if (!this.layout.dataset.tagsCounter.tagsLabeled[key])tags[key] = this.layout.dataset.tagsCounter.tagsLabeled[key] }
			return tags;
		},

		labeledTagsCount() { 
			console.log("Computed labeledTagsCount");
			let total = 0;
			for (const key in this.layout.dataset.tagsCounter.tagsLabeled) total += parseInt(this.layout.dataset.tagsCounter.tagsLabeled[key]); 
			return total; 
		},
		
		actionsDatasets() {
			return this.layout.datasets.filter(dataset => dataset.id !== this.layout.dataset.id);
		},
	},

	created () {
		this.helper = $h;
	},

	setup(props) {
		const router 				= useRouter();
		const selectedImages  		= ref([]);
		const selectToDataset 		= ref([]);
		const downloadImg 			= ref(false);
		const manifestData 			= ref(false);
		
		const responsiveGroupSize	= ref(false);
		const fixedGroupSize		= ref(false);
		const showResponsive 		= ref(false);

		const completeDataset		= ref(false);

		const layout = ref({
			canvasList:   	[],
			dataset:      	false,
			datasets:     	[],
			edit:         	false,
			loading:      	true,
			tagsListener: 	false,
			pagination:   	false,
			optImages:    { 
				datasetID:		props.options.dataset, 
				objtagsType:	'all', 
				objByTag:		'all', 
				division:		'all',
				mediaSize:		window.innerWidth < 900 ? 3 : 2,
				windowWidth:	window.innerWidth,
				mediaLabel:		true,
				mediaName:		true, 
				mediaMask:		false,
				mediaBounding:	false,
				mediaSet:		true,
				mediaDate:		true,
				maskOpacity:	0.5,
				perPage:		50,
				order:			'date',
				direction:		'desc'
			},
			zip:  { loading: false, counter: 0 },
			move: { loading: false, toDataset: "", counter: 0 },
			copy: { loading: false, toDataset: "", counter: 0 },
			actions: { assingLoad: false, assingSet: false, assingTag: false }
		});

		const modal       	= (m) => { cash("#" + m).modal("show"); cash(".main").removeClass("overflow-y-hidden")}
		const modalClose	= (m) => { cash("#" + m).modal("hide"); }

		const check 		= () => { selectedImages.value = []; for (let i in layout.value.dataset.images.media) { selectedImages.value.push(layout.value.dataset.images.media[i].id); } }
		const unCheck 		= () => { completeDataset.value = false; selectedImages.value = []; }
		const countCheck 	= () => { return selectedImages.value.length ? selectedImages.value.length : 0; }

		const handleResize = async function() {
			const filtersLabeling	= document.querySelector('#rpFiltersLabeling');
			showResponsive.value = (fixedGroupSize.value + responsiveGroupSize.value) > filtersLabeling.offsetWidth;

			if (layout.value.canvasList.length) {
				layout.value.canvasList.forEach(async function(canvas) { 
					let container = canvas.lowerCanvasEl.parentNode.parentNode;
					container.style.height = `${container.clientWidth}px`;
					canvas.setWidth(container.clientWidth);
					canvas.setHeight(container.clientWidth);
					await adjustCanvasImage(canvas.getObjects()[0], canvas);
				});
			}
		}

		const checkItem = async (imgSelected) => {
			let exists = false;
			selectedImages.value.findIndex(function(m, index) { 
				if (m == imgSelected.id) { 
					exists = true;
					if (index > -1) {
						selectedImages.value.splice(index, 1);
					} 
				} 
			});
			if (!exists) {
				selectedImages.value.push(imgSelected.id);
			}
		}

		const setDataDivision = async () => { 
			layout.value.actions.assingLoad = true;
			let cnt = 0;

			for (const s of selectedImages.value) {
				await $image.setSet(s, layout.value.actions.assingSet.toString().toUpperCase());
				cnt++;
			}

			setTimeout(async () => {
				let currentSelectedImages = selectedImages.value;
				$h.NotificationTxt({ text: cnt + " media set updated" });
				modalClose("modal-change-set");
				layout.value.actions.assingSet = false;
				layout.value.actions.assingLoad = false;
				await loadImages();
				selectedImages.value = currentSelectedImages;
			}, 1000);
		}

		const categorize = async () => { 
			layout.value.actions.assingLoad = true;
			let cnt = 0;

			for (const s of selectedImages.value) {
				await $image.setTag(s, props.options.dataset, layout.value.actions.assingTag);
				cnt++;
			}

			setTimeout(async () => {
				$h.NotificationTxt({ text: cnt + " media tag updated" });
				modalClose("modal-labeling");
				layout.value.actions.assingTag = false;
				layout.value.actions.assingLoad = false;
				await loadImages();
			}, 2000);
		}

		const load = async () => {
			let datasetData 		= JSON.parse(sessionStorage.getItem('datasetData'));
			if (props.options.dataset != datasetData.id) {
				datasetData = await $dataset.get(props.options.dataset, { models: true, tagsCounter: true });
			}
			layout.value.dataset 	= datasetData;
			layout.value.datasets	= await $dataset.list();
			await loadImages();
		}

		const loadImages = async () => { 
			selectedImages.value = [];

			let opt = { 
				dataset:     layout.value.dataset,
				objDivision: layout.value.optImages.division != 'all' ? layout.value.optImages.division : 'all',
				objByTag:    layout.value.optImages.objByTag != 'all' ? layout.value.optImages.objByTag : 'all',
				perPage:     layout.value.optImages.perPage,
				action:      layout.value.optImages.action	? layout.value.optImages.action : false,
				order:       layout.value.optImages.order 	? layout.value.optImages.order : false,
				direction:   layout.value.optImages.direction ? layout.value.optImages.direction : false
			};

			if (layout.value.dataset.type == 'imageObjectDetection' || layout.value.dataset.type == 'MULTILABEL') {
				opt.objtagsType = layout.value.optImages.objtagsType ? layout.value.optImages.objtagsType : 'all';
			}

			if (layout.value.pagination) opt.paginationQry = layout.value.pagination;

			let tagsType = opt.objtagsType != 'all' ? opt.objtagsType : false;
			let byTag = opt.objByTag != 'all' ? opt.objByTag : false;

			/* layout.value.dataset.tagsCounter 	= await $dataset.getTagStats(layout.value.dataset); */
			layout.value.dataset.dataDivision 	= await $dataset.getDivisionStats(layout.value.dataset, tagsType, byTag);
			layout.value.dataset.images 		= await $dataset.getImages(layout.value.dataset, opt);
			
			layout.value.pagination 			= layout.value.dataset.images.pagination ? layout.value.dataset.images.pagination : false;
			layout.value.optImages.action 		= false;
			
			setTimeout(async () => { for (let image of layout.value.dataset.images.media) { if (image) await thumb(image) } }, 10);
			cash(".wrapper-box .content").addClass("singlemode_wrapper");

			layout.value.loading = false;
		}

		const thumb = async (image) => { 
			const canvasId 		= "Thumbcanvas_" 	+ image.id;
			const canvasBoxId 	= "ThumbcanvasBox_" + image.id;

			const showMask 		= layout.value.optImages.mediaMask;
			const showBounding 	= layout.value.optImages.mediaBounding;

			let cashImage = document.getElementById(canvasBoxId);
			let canvaOpt = { 
				width: 			cashImage.clientWidth, 
				height: 		cashImage.clientWidth,
				imageId: 		image.id,
				selection: 		false, 
				hoverCursor: 	'pointer'
			}

			let thumbCanvas = new fabric.Canvas(canvasId, canvaOpt);
			layout.value.canvasList.push(thumbCanvas);

			fabric.Image.fromURL(image.img_base64_val, async function (fabricImg) {
				fabricImg.type			= 'image';
				fabricImg.hoverCursor 	= 'pointer';
				fabricImg.erasable 		= false;
				fabricImg.selectable 	= false;

				
				if (showMask && image?.mask?.imageJson) await loadMask(image.mask, fabricImg, thumbCanvas);
				if (showBounding && image?.tags) 		await loadBoundingBox(image.tags, fabricImg, thumbCanvas);

				thumbCanvas.insertAt(fabricImg, 0);
				await adjustCanvasImage(fabricImg, thumbCanvas);

				thumbCanvas.renderAll();
			});
		}

		const adjustCanvasImage = async (img, thumbCanvas) => {
			const imgWidth 		= img.width;
			const imgHeight		= img.height;
			const canvasWidth 	= thumbCanvas.width;
			const canvasHeight	= thumbCanvas.height;

			let imgCenterX = img.left + img.width / 2;
			let imgCenterY = img.top + img.height / 2;

			let zoomFactor;
			let panX = 0;
			let panY = 0;

			if (imgWidth > imgHeight) {
				zoomFactor = canvasHeight / imgHeight;
				panX = canvasWidth / 2 - imgCenterX * zoomFactor;
			} else if (imgWidth < imgHeight) {
				zoomFactor = canvasWidth / imgWidth;
				panY = canvasHeight / 2 - imgCenterY * zoomFactor;
			} else {
				zoomFactor = canvasWidth / imgWidth;
				panX = canvasWidth / 2 - imgCenterX * zoomFactor;
				panY = canvasHeight / 2 - imgCenterY * zoomFactor;
			}

			thumbCanvas.setViewportTransform([zoomFactor, 0, 0, zoomFactor, panX, panY]);
		}

		const loadMask = async(mask, fabricImg, thumbCanvas) => {
			let maskJSON = false;
			
			try { 
				maskJSON = JSON.parse(mask.imageJson);
			} catch (error) { 
				maskJSON = JSON.parse(lzstring.decompressFromUint8Array(mask.imageJson.toUint8Array())); 
			}

			if (maskJSON) {
				thumbCanvas.loadFromJSON(maskJSON, function () {
					thumbCanvas.insertAt(fabricImg, 0);
				}, function(o, object) {
					const widthRatio	= fabricImg.width / mask.width;
					const heightRatio	= fabricImg.height / mask.height;
					const scaleFactor	= Math.min(widthRatio, heightRatio);
					
					object.set({
						strokeWidth:	object.strokeWidth	* scaleFactor,
						scaleX: 		object.scaleX 		* scaleFactor,
						scaleY: 		object.scaleY 		* scaleFactor,
						left: 			object.left 		* scaleFactor,
						top: 			object.top 			* scaleFactor,
						opacity: 		layout.value.optImages.maskOpacity,
						selectable: 	false,
						hoverCursor: 	"pointer"
					});
				});
			}
		}

		const loadBoundingBox = async(tags, fabricImg, thumbCanvas) =>  {
			const promises = tags.map(async (boundingBox, index) => {
				//BOUNDING BOX
				if (boundingBox.type == "rect") {
					let RectOpt = {
						left:     boundingBox.x * (fabricImg.width),
						top:      boundingBox.y * (fabricImg.height),
						originX:  'left',
						originY:  'top',
						width:    boundingBox.w * (fabricImg.width),
						height:   boundingBox.h * (fabricImg.height),
						angle:    0,
						fill:     'transparent',
						strokeWidth: 1,
						transparentCorners: false,
						selectable: false,
						hoverCursor: "pointer"
					};

					let newObject	= new fabric.Rect(RectOpt);
					let tagName 	= boundingBox.tag.path.toString().split('/').pop();
					let _tagData 	= await $dataset.getTag(layout.value.dataset.id, tagName);

					//ETIQUETA
					if (tagName) {
						let bbColor = _tagData.color ? _tagData.color : 'ff0000';
						let newObjectId = index + tagName;

						newObject.toObject = (function(toObject) {
							return function(propertiesToInclude) {
								return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
									name: tagName,
									objid: newObjectId
								});
							};
						})(newObject.toObject);

						newObject.set({ stroke: bbColor });
						newObject.set({ name: tagName });
						newObject.set({ objid: newObjectId });
						thumbCanvas.add(newObject);

						/* let TextOpt = {
							fill: bbColor,
							left: boundingBox.x * fabricImg.width,
							top: ((boundingBox.y * fabricImg.height) - 28),
							fontSize: 20,
							padding: 5,
							objParentId: newObjectId,
							selectable: false,
							hoverCursor: "pointer"
						};

						let tagNameBox = new fabric.Text(tagName, TextOpt);
						tagNameBox.toObject = (function(toObject) {
							return function(propertiesToInclude) {
								return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
									objParentId: newObjectId
								});
							};
						})(newObject.toObject);

						tagNameBox.set({ objParentId: newObjectId });
						thumbCanvas.add(tagNameBox); */
					}
				}
			});

			await Promise.all(promises);
		}

		const deleteImages = async () => { 
			cash("#modal-delete").modal("hide");
			layout.value.loading = true;
			resetPagination();
			console.log("Delete images", selectedImages.value);
			await $dataset.deleteImagesBatch(props.options.dataset , JSON.stringify(selectedImages.value)).then(async () => {
				$h.NotificationTxt({ text: "Images successfully removed", position: "right" });
				await load();
			});
		}

		const reload = async () => { 
			if (props.options.dataset) {
				layout.value.loading = true; 
				layout.value.canvasList	= [];
				await loadImages();
			}
		}

		const resetPagination = async () => { 
			layout.value.pagination = false; 
			layout.value.optImages.action = false;
			layout.value.dataset.images = {};
		}

		const labelingMenu = (navId) => {
			let menuItem = document.querySelector('#' + navId);

			if (menuItem.classList.contains('selected')) {
				menuItem.classList.remove('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id) && document.querySelector('.' + menuItem.id).classList.contains('sel')) {
					document.querySelector('.' + menuItem.id).classList.remove('sel');
				}
			} else {
				menuItem.classList.add('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id)) {
					document.querySelector('.' + menuItem.id).classList.add('sel');
				}
			}
		}

		const resizeFiltersMenu = () => {
			const fixedElements		= document.querySelectorAll('.fixed-element');
			const responsiveGroup	= document.querySelector('#responsive-group');
			const filtersLabeling	= document.querySelector('#rpFiltersLabeling');

			let totalWidth = 0;
			fixedElements.forEach(element => { totalWidth += element.offsetWidth; });
			
			fixedGroupSize.value 		= totalWidth;
			responsiveGroupSize.value 	= responsiveGroup.offsetWidth;

			showResponsive.value = (fixedGroupSize.value + responsiveGroupSize.value) > filtersLabeling.offsetWidth;
		}

		const downloadCSV = async () => {
			const csvResp	= await $dataset.updateCsv({ dataset: layout.value.dataset });
			const res 	= await fetch(csvResp.url);
			const blob 	= await res.blob();
			const blobUrl = window.URL.createObjectURL(blob);
			
			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', layout.value.dataset.id + '.csv');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(blobUrl);
		}

		const downloadDataset = async () => {
			const images 			= await $dataset.getImages(layout.value.dataset);
			completeDataset.value 	= true;
			selectedImages.value 	= images.media;
			modal("modal-download-selected");
		}

		const downloadMedia = async () => {
			layout.value.zip.loading = true;
			layout.value.zip.counter = 0;

			selectToDataset.value = await $dataset.getImagesData({ 
				images: 			layout.value.dataset.images.media, 
				zipCounter: 		layout.value.zip.counter,
				completeDataset: 	completeDataset.value,
				selectedIds:		new Set(selectedImages.value),
			}, (currentProgress) => {{
				layout.value.zip.counter = currentProgress;
			}});

			await $dataset.createZip(layout.value.dataset, selectToDataset.value, { rawImg: downloadImg.value });

			cash("#modal-download-selected").modal("hide");
			unCheck();
			layout.value.zip.loading = false;
			downloadImg.value = false;
		}

		watch(() => props.options.dataset, async () => {
			console.log("Watcher prop.options.dataset");
			layout.value.edit = false;
			layout.value.optImages.objtagsType = 'all';
			layout.value.optImages.objByTag = 'all';
			layout.value.optImages.division = 'all';
			resetPagination(); 
			await load();
		});

		watch(() => layout.value.optImages.mediaSize, async () => { 
			console.log("Watcher layout.value.optImages.mediaSize");
			await nextTick();
			await handleResize();
		});

		watch(() => layout.value.optImages.division , async () => {
			console.log("Watcher layout.value.optImages.division");
			layout.value.loading = true; 
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.objtagsType , async () => {
			console.log("Watcher layout.value.optImages.objtagsType");
			layout.value.loading = true;
			layout.value.optImages.objByTag = 'all';
			layout.value.optImages.division = 'all';
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.objByTag , async () => { 
			console.log("Watcher layout.value.optImages.objByTag");
			layout.value.loading = true;
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.perPage, async () => {  
			console.log("Watcher layout.value.optImages.perPage");
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.order, async () => {  
			console.log("Watcher layout.value.optImages.order");
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.direction, async () => {  
			console.log("Watcher layout.value.optImages.direction");
			resetPagination();
			await loadImages();
		});

		watch(() => layout.value.optImages.maskOpacity, async () => {  
			console.log("Watcher layout.value.optImages.maskOpacity");
			resetPagination();
			await loadImages(); 
		});
	
		onMounted(async () => {
			router.push('/datasets/' + props.options.dataset + '/labeling');
			window.addEventListener('resize', handleResize);
			cash("#rpTopBarLine").hide();
			if (props.options.dataset) { await load(); }
			await nextTick(resizeFiltersMenu());
			cash("body").css("overflow-y", "hidden");
		});

		onUnmounted(async () => { 
			window.removeEventListener('resize', handleResize);
			cash("body").css("overflow-y", "scroll");
			cash(".wrapper-box .content").removeClass("singlemode_wrapper");
			cash("#rpTopBarLine").show();
		});

		return {
			router,
			layout,
			props,
			selectedImages,
			selectToDataset,
			downloadImg,
			showResponsive,
			completeDataset,
			manifestData,
			modal,
			modalClose,
			checkItem,
			check,
			unCheck,
			countCheck,
			setDataDivision,
			categorize,
			load,
			loadImages,
			loadBoundingBox,
			deleteImages,
			reload,
			resetPagination,
			labelingMenu,
			downloadCSV,
			downloadDataset,
			downloadMedia
		};
	},

	methods: {
		back: async function () { 
			this.$parent.back() 
		},

		goEditImage: async function (img) { 
			this.$parent.goEditImage(img) 
		},

		hideLabeling: async function () { 
			this.router.push('/datasets/' + this.props.options.dataset); 
			this.$parent.hideLabeling(); 
		},

		refreshLabeling: async function () { 
			console.log("refreshLabeling");
			await this.load() 
		},

		goDataset: async function () { 
			if (this.props.options.dataset) {
				if (this.props.options.datasetsList) {
					for (let ds of this.props.options.datasetsList) {
						if (ds.id == this.props.options.dataset) {
							this.$parent.goDataset(ds);
						}
					}
				}
			}
		},

		moveMedia: async function () { 
			if (!this.layout.move.toDataset) alert('Select a dataset to move the images')
			else {
				this.layout.move.loading = true;
				this.layout.move.counter = 0;
				let imagesMoveList       = { images: {} }

				for (let i = 0; i < this.selectedImages.length; i++) {
					if (this.selectedImages[i]) {
						this.layout.move.counter++;
						imagesMoveList.images[this.selectedImages[i].toString().replace(/'/g,"")] = "0";
					}
				}

				if (Object.keys(imagesMoveList.images).length)
					await $dataset.createOperation(this.layout.move.toDataset, { images: imagesMoveList.images, type: 'move' }, config.functions.usapi);
				
				this.layout.move.success   = true;
				this.layout.move.loading   = false;
				this.resetPagination();
				await this.loadImages();
			}
		},

		copyMedia: async function () { 
			if (!this.layout.copy.toDataset) {
				alert('Select a dataset to copy the images')
			} else {
				this.layout.copy.loading = true;
				this.layout.copy.counter = 0;

				let imagesCopyList = { images: {} }

				const toDatasetData = await $dataset.get(this.layout.copy.toDataset);
				const toDatasetTags = Object.keys(await $dataset.getTags(this.layout.copy.toDataset));

				for (let i = 0; i < this.selectedImages.length; i++) {
					const imageId = this.selectedImages[i].toString().replace(/'/g,"");
					this.layout.copy.counter++;

					const tagDoc = await $image.getTags(imageId);
					const imageTag = tagDoc['tags'][0]['tag'];

					if (!toDatasetTags.includes(imageTag)) {
						await $dataset.createTag(this.layout.copy.toDataset, { tag: imageTag })
					}

					if (toDatasetData.type === 'MULTICLASS' || toDatasetData.type === 'MULTILABEL') {
						imagesCopyList.images[imageId] = imageTag;
					} else if (toDatasetData.type === 'imageObjectDetection') {
						imagesCopyList.images[imageId] = '0';
					}
				}
				
				if (Object.keys(imagesCopyList.images).length) {
					await $dataset.createOperation(this.layout.copy.toDataset, { images: imagesCopyList.images, type: 'copy' });
				}

				this.layout.copy.success   = true;
				this.layout.copy.counter   = 0;
				this.layout.copy.loading   = false;
			}
		},
	}
});
</script>

<style>
.rpLabelingTopMenu{ z-index: 99999999 !important; margin-top: 3px !important; }
.rpLabelingMenu {  width: 100%;font-size: 13px; }
.rpLabelingMenu li { background-color: #f1f5f8; width: 100%; cursor: pointer; padding: 10px; margin-bottom: 1px;display: block; }
.rpLabelingMenu ul li:hover { background-color: #f1f5f8 }
.rpLabelingMenu ul li.selected { background-color: #f1f5f8;}
.rpLabelingMenu ul li .arrow { display: block;float: right;width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 7px solid transparent;border-left: 7px solid #ccc; margin: 2px 8px 0 0; }
.rpLabelingMenu ul li.selected .arrow{ transform: rotate(90deg);}
.rpLabelingMenu ul li.menu-content { background-color: #fff; padding: 15px; font-size: 12px; display: none; cursor: default;}
.rpLabelingMenu ul li.menu-content.sel{ display: block; }
.labelingBox{  position: fixed; top: 0; left: 0; min-height: 100%; width: 100% !important; z-index: 9999 !important; }
</style>