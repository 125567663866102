'use strict';

import Tabulator    from "tabulator-tables";
import feather      from "feather-icons";

const languages = {
    "es-es":{
        "columns":      { 
            "name":     "Nombre",
        },
        "ajax":         { 
            "loading":  "Cargando", 
            "error":    "Error" 
        },
        "groups":       { 
            "item":     "item", 
            "items":    "items"
        },
        "pagination":   { 
            "page_size":    "", 
            "first":        "First", 
            "first_title":  "Primera", 
            "last":         "Last", 
            "last_title":   "Última", 
            "prev":         "Prev", 
            "prev_title":   "Anterior", 
            "next":         "Next", 
            "next_title":   "Siguiente"
        },
        "headerFilters":{ 
            "default":      "Filtrar columna...", 
            "columns": { 
                "name":     "Filtrar nombre...", 
            }
        }
    }
}

const table = {

    init: async function(tableRef, tableData, tableColumns, handleRowClick) {
        if (!tableRef || !tableData || !tableColumns) return;

        let table = new Tabulator(tableRef, {
            data:                   tableData,
            pagination:             "local",
            paginationSize:         5, 
            paginationSizeSelector: [5, 10, 25, 50, 100],
            layout:                 "fitColumns",
            responsiveLayout:       "collapse",
            placeholder:            "No tags have been registered",
            columns:                tableColumns,
            movableRows:            true,
            locale:                 false,
            langs:                  languages,
            rowClick: (e, row) => handleRowClick(e, row),
            renderComplete() { feather.replace({ "stroke-width": 1.5 }); }
        });

        window.addEventListener("resize", () => { 
            table.redraw(); 
            feather.replace({ "stroke-width": 1.5 }); 
        });
        
        return table;
    },

    init_o: async function(tableRef, columns, opt = false) {
        let table = new Tabulator(tableRef, {
            data:                    [],
            printHeader:             opt.printHeader ? opt.printHeader : '', 
            pagination:              opt.pagination,
            paginationSize:          opt.paginationSize ? opt.paginationSize : 5, 
            paginationSizeSelector:  [5, 10, 25, 50, 100],
            layout:                  "fitColumns",
            responsiveLayout:        "collapse",
            placeholder:             opt.placeholder ? opt.placeholder : "No matching records found",
            columns:                 columns,
            responsiveLayoutCollapseStartOpen:  false,
            locale:                  false,
            langs:{
                "es-es":{
                    "columns":      { "name":"Nombre",},
                    "ajax":         { "loading":"Cargando", "error":"Error",},
                    "groups":       { "item":"item", "items":"items",},
                    "pagination":   { "page_size":"", "first":"First", "first_title":"Primera", "last":"Last", "last_title":"Última", "prev":"Prev", "prev_title":"Anterior", "next":"Next", "next_title":"Siguiente", },
                    "headerFilters":{ "default":"Filtrar columna...", "columns":{ "name":"Filtrar nombre...", }}
                }
            },
            renderComplete() { 
                feather.replace({ "stroke-width": 1.5 });
            }
        });
        this.InitOnResizeWindow(table);  
        return table;
    },

    InitOnResizeWindow: async function(table){ window.addEventListener("resize", () => { table.redraw(); feather.replace({ "stroke-width": 1.5 }); }); },
    
    setData: async function(table,data){ await table.setData(data) }
}

export default table;