<template>
	<div>
		
		<!-- Model list -->
		<div class="grid chat grid-cols-12 gap-0" v-if="!currentModel" >

			<div class="col-span-12 flex flex-col">
				<div class="grid chat grid-cols-12 gap-3 mt-3">

					<div v-for="model in localModelsMenu" :key="model.id" class="intro-y col-span-6" @click="currentModel=model">
						<div class="box px-4 py-4 flex items-center zoom-in shadow-none border border-gray-300 hover:bg-gray-100 h-100">
							
							<div class="mr-auto">
								<div class="font-medium truncate text-gray-700">{{ model.id.toString().substring(0, 35) }}<span v-if="model.id.toString().length>35">...</span></div>
								<div class="text-gray-600 text-xs mt-0.5">{{ model.created }}</div>
							</div>

							<div v-if="model?.service && model?.service === 'visionPlatform'" class="flex items-center space-x-2">
								<div class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal border border-gray-400 text-gray-500">
									{{ "VP" }}
								</div>
								<div v-if="model?.status === 'training'" class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal text-gray-500">
									<LoadingIcon icon="puff" class="w-6 h-6" />
								</div>
								<div v-else-if="model?.status === 'finished'" class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal border border-green-400 text-green-500">
									{{ "Finished" }}
								</div>
								<div v-else class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal border border-red-400 text-red-500">
									{{ "Failed" }}
								</div>
							</div>

							<div v-else>
								<div v-if="!model?.automl && model?.trainOperation?.operationId" class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal text-gray-500"><LoadingIcon icon="puff" class="w-24 h-24" /></div>
								<div v-else class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal border border-gray-400 text-gray-500">{{ model.aws ? "Anomaly detection" : "Supervised" }}</div>
							</div>

						</div>
					</div>
					
				</div>
			</div>

			<div class="col-span-12 flex flex-col mt-5" v-if="Dataset?.localModels.length">
				<div class="box px-3 py-5 shadow-none border border-solid border-gray-200">
					<div class="text-center">
						<span class="text-xs text-gray-500">{{$t('Keep fine-tuning the precision')}}</span>
						<div class="text-center mt-2"><button type="button" @click="modal('modal-trainBox')" class="btn bg-green-600 text-white py-3 px-8">{{ $t('Train a new model') }}</button></div>  
						<div class="mt-3 text-gray-500 text-center">
							{{$t('If there are fewer Images, inaccurate accuracy and recall scores are likely to be generated')}}.<br />
							{{$t('Assign the maximum number of images to each tag before training')}}. 
						</div>
					</div>
				</div>
			</div>

		</div>
  
		<!-- Results -->
		<div class="grid chat grid-cols-12">

			<div class="col-span-12" v-if="Layout.loading">
				<div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
				<div class="intro-y flex flex-col justify-center items-center mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
			</div>

			<div class="col-span-12" v-else>
				<div class="pb-2 text-left">
			
					<!-- No models trained -->
					<div class="text-center" v-if="!Dataset.localModels.length">
						<span class="text-xs text-gray-500">{{$t('Has not been trained')}}</span>
						<div class="font-medium text-lg text-gray-700">{{$t('Train a new Model')}}</div>
						<div class="mt-1 text-gray-500 text-center">
							{{$t('If there are fewer Images, inaccurate accuracy and recall scores are likely to be generated')}}.<br />
							{{$t('Assign the maximum number of images to each tag before training')}}. 
						</div> 
						<div class="text-center mt-2"><button type="button" @click="modal('modal-trainBox')" class="btn bg-green-600 text-white py-3 mt-2 px-8">{{ $t('Train a new model') }}</button></div>  
					</div>

					<div class="w-full" v-else>

						<!-- Header -->
						<div class="grid chat grid-cols-12 gap-5 py-2 mt-2" v-if="currentModel">
							<div class="col-span-12 text-left">
							
								<span class="text-xl truncate text-gray-700"> {{ currentModel?.db?.id }}</span>

								<span v-if="currentModel?.db?.aws" class="py-0.5 px-2 rounded-full text-xs font-normal ml-2 border border-gray-400 text-gray-500">{{ "Semi-supervised" }}</span>
								<span v-else-if="currentModel?.db?.service === 'visionPlatform'" class="py-0.5 px-2 rounded-full text-xs font-normal ml-2 border border-gray-400 text-gray-500">{{ "Vision Platform" }}</span>
								<span v-else-if="currentModel?.db?.automl" class="py-0.5 px-2 rounded-full text-xs font-normal ml-2 border border-gray-400 text-gray-500">{{ "Supervised" }}</span>

								<span class="py-2 px-6 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-700 text-white" @click="currentModel=false;">{{ $t('Back') }}</span>
								<span class="py-2 px-3 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-500 hover:bg-red-500 text-white mr-2" @click="modal('model-delete-box')"><Trash2Icon class="w-4 h-4" /></span>
								
								<!-- Retrain Google -->
								<span class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-20 cursor-pointer float-right ml-auto mr-2 border-green-200 text-green-500" 
									v-if="currentModel?.db?.automl && currentModel.vertexEvaluation?.evaluations && currentModel.vertexEvaluation?.evaluations[0]?.model?.eligibleAsBaseModel" @click="modal('modal-trainBox')">{{ $t('Retrain') }}</span>
								
								<!-- Retrain AWS -->
								<span class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-20 cursor-pointer float-right ml-auto mr-2 border-green-200 text-green-500" 
									v-else-if="currentModel?.db?.aws" @click="modal('modal-trainBox')">{{ $t('Retrain') }}</span>
							
								<!-- Evaluation AWS -->
								<span v-if="currentModel?.db?.aws && currentModel?.awsEvaluation?.manifestResume && !semiSupervisedEvaluationObjects?.length && !currentModel.awsEvaluation?.loading" @click="loadEvaluationObjects(currentModel?.awsEvaluation?.manifest)" 
									class="py-2 px-2 rounded-full text-xs font-normal align-middle border border-blue-200 text-blue-500 w-48 text-center cursor-pointer hover:bg-blue-500 hover:text-white float-right ml-auto mr-2">
									{{ $t('View evaluation images') }} ({{ currentModel.awsEvaluation.manifestResume?.totalTestImages }}) 
								</span>

								<!-- Metrics AWS -->
								<span v-else-if="currentModel?.db?.aws" @click="semiSupervisedEvaluationObjects=[]"
									class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-48 float-right ml-auto mr-2"
									:class="semiSupervisedEvaluationObjects?.length && !currentModel.awsEvaluation?.loading ? 'border-blue-200 text-blue-500 hover:bg-blue-500 hover:text-white cursor-pointer' : 'border-gray-200 text-gray-500 cursor-not-allowed'">
									{{ $t('View Metrics') }}
								</span>
								
							</div>
						</div>
		
						<!-- Table settings -->
						<div class="col-span-12" v-if="currentModel.db" >
							<table class="table table--sm text-xs text-left">
								<tbody>
									<tr v-if="currentModel.db.description">
										<td class="border"><span>{{$t('description')}}</span></td>
										<td class="border"><span>{{  currentModel.db.description }}</span></td>
									</tr>
								</tbody>
							</table>
						</div>
		
						<!-- Training Supervised Training -->
						<div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="!currentModel?.db?.automl && currentModel?.db?.trainOperation?.operationId"
							:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
							<span v-if="currentModel?.db?.trainOperation?.operationId">{{ $t('Supervised classification model is training, please wait ...') }}</span>
							<span class="py-2 px-6 rounded-full text-xs font-normal align-middle text-center cursor-pointer text-gray-700 bg-white ml-3" style="z-index: 99999;position: relative;" @click="currentModel=false;">{{ $t('Back') }}</span>
						</div>
		
						<!-- Training Semi-supervised Training -->
						<div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="!currentModel?.db?.trainOperation?.operationId && currentModel?.awsModel?.response?.ModelDescription?.Status=='TRAINING'"
							:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
							<span>{{ $t('Anomaly detection model is training, please wait ...') }}</span>
							<span class="py-2 px-6 rounded-full text-xs font-normal align-middle text-center cursor-pointer text-gray-700 bg-white ml-3" style="z-index: 99999;position: relative;" @click="currentModel=false;">{{ $t('Back') }}</span>
						</div>

						<!-- Training Vision Platform Model -->
						<div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="currentModel?.db?.service === 'visionPlatform' && currentModel?.db?.status === 'training'"
							:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
							<span>{{ $t('Vision Platform is training, please wait ...') }}</span>
							<span class="py-2 px-6 rounded-full text-xs font-normal align-middle text-center cursor-pointer text-gray-700 bg-white ml-3" style="z-index: 99999;position: relative;" @click="currentModel=false;">{{ $t('Back') }}</span>
						</div>

						<!-- Load model error -->
						<div v-if="currentModel?.errorLoad">
							<div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
								<div>{{  $t('An error occurred while retrieving model information')  }}.</div>
								<div class="py-3 text-red-600 text-xs">{{  currentModel?.errorLoad  }}</div>
							</div>
						</div>
		
						<!-- Supervised Results -->
						<div class="grid chat grid-cols-12 gap-6" v-if="currentModel?.db?.automl">

							<div class="col-span-5 text-center mt-8">

								<!-- Evaluation Error -->
								<div class="flex flex-col justify-center items-center" v-if="!currentModel?.vertexEvaluation?.render">
									<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="currentModel?.vertexEvaluation?.error">
										<div class="mt-3 text-gray-600 text-xs">{{ currentModel?.vertexEvaluation?.error }}</div>
									</div>
									<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-else>
										<LoadingIcon icon="puff" class="w-20 h-20" />
									</div>
								</div>

								<!-- Evaluation Diagram -->
								<div v-else>

									<div class="relative" v-if="currentModel.vertexEvaluation?.evaluations[0]">
										
										<!-- Donut Diagram -->
										<ReportDonutChart
											:height="190"
											:options="{ legend: { display: false }, cutoutPercentage: 70 }"
											:data="{
												labels: ['Train', 'validation'],
												datasets: [{
													data: [
														currentModel.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount,
														currentModel.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount
													],
													backgroundColor: ['#333333', '#1d8102']
												}]
											}"
										/>

										<!-- Total images -->
										<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
											<div class="text-xl xxl:text-2xl font-medium">
												{{ 
													parseInt(currentModel.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount) +
													parseInt(currentModel.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount) + 
													parseInt(currentModel.vertexEvaluation?.evaluations[0]?.evaluatedCount)
												}}
											</div>
											<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
										</div>

									</div>

									<div class="mx-auto w-10/12 xxl:w-2/3 mt-3" v-if="currentModel.vertexEvaluation?.evaluations[0]">
										
										<!-- Train -->
										<div class="flex items-center mt-2">
											<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #333333"></div>
											<span class="truncate">{{$t('Train')}}</span>
											<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
											<span class="font-medium xl:ml-auto">{{ currentModel.vertexEvaluation?.evaluations[0]?.model.trainingDataItemsCount }}</span>
										</div>
										
										<!-- Validation -->
										<div class="flex items-center mt-2">
											<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
											<span class="truncate">{{$t('Validation')}}</span>
											<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
											<span class="font-medium xl:ml-auto">{{ currentModel.vertexEvaluation?.evaluations[0]?.model.validationDataItemsCount }}</span>
										</div>

										<!-- Test -->
										<div class="flex items-center mt-2" v-if="currentModel.vertexEvaluation?.evaluations[0]?.evaluatedCount">
											<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
											<span class="truncate">{{$t('Test')}}</span>
											<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
											<span class="font-medium xl:ml-auto">{{ currentModel.vertexEvaluation?.evaluations[0]?.evaluatedCount }}</span>
										</div>

									</div>

								</div>

							</div>

							<!-- Model configuration table -->
							<div class="col-span-7 pb-0 text-left mt-5">
								
								<table class="table table--sm text-xs text-left">
									
									<tbody>

										<!-- <tr><td class="border" colspan="2"><div id="respGptBox"></div></td></tr> -->

										<tr v-if="currentModel.db.automl">
											<td class="border"><span>{{$t('Storage bucket')}}</span></td>
											<td class="border bg-gray-100" v-if="currentModel.db?.savedModel">
												<div 
													class="copyButton cursor-pointer"
													@click="copyToClipboard(currentModel.db.savedModel)"
													:data-clipboard-text="currentModel.db.savedModel"
												>
													<span class="text-green-700">{{$t('model saved')}} <CopyIcon class="w-4 h-4 ml-2 hover:text-blue-900" /></span>
												</div>
											</td>
											<td class="border bg-gray-100" v-else>
												<div v-if="Layout.loading" class="w-10 h-10">
													<LoadingIcon icon="three-dots" class="w-12 h-12" />
												</div>
												<button
													v-else
													type="button"
													@click="exportModelToBucket()"
													class="btn bg-theme-14 text-white my-2 font-normal zoom-in"
												>
													{{ $t('Export to Storage Bucket') }}
												</button>
											</td>
										</tr>

										<tr v-if="currentModel.vertexEvaluation?.evaluations && currentModel.vertexEvaluation?.evaluations[0]?.model?.versionId">
											<td class="border"><span>{{$t('Version')}}</span></td>
											<td class="border bg-gray-100"><span>{{ currentModel.vertexEvaluation.evaluations[0].model.versionId }}</span></td>
										</tr>

										<tr v-if="currentModel.vertexEvaluation?.evaluations && currentModel.vertexEvaluation?.evaluations[0]?.model?.training?.budgetMilliNodeHours">
											<td class="border"><span>{{$t('Budget')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ currentModel.vertexEvaluation.evaluations[0].model.training.budgetMilliNodeHours }} {{$t('milliNodeHours')}}</span>
											</td>
										</tr>

										<tr v-if="currentModel.vertexEvaluation?.evaluations && currentModel.vertexEvaluation?.evaluations[0]?.model?.training?.costMilliNodeHours">
											<td class="border"><span>{{$t('Cost')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ currentModel.vertexEvaluation.evaluations[0].model.training.costMilliNodeHours }} {{$t('milliNodeHours')}}</span>
											</td>
										</tr>

										<tr v-if="currentModel.vertexEvaluation?.evaluations && currentModel.vertexEvaluation?.evaluations[0]?.model?.training?.stopReason">
											<td class="border"><span>{{$t('Stop reason')}}</span></td>
											<td class="border bg-gray-100">
												<span :class="currentModel.vertexEvaluation.evaluations[0].model.training.stopReason=='MODEL_CONVERGED' ? 'text-green-500' : 'text-blue-300'">
													{{ currentModel.vertexEvaluation.evaluations[0].model.training.stopReason }}
												</span>
											</td>
										</tr>

										<tr v-if="currentModel?.vertex?.createTime">
											<td class="border"><span>{{$t('Created')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ this.helper.getFbDate(currentModel.vertex.createTime) }}</span>
											</td>
										</tr>

									</tbody>

								</table>

							</div>

							<!-- Evaluation Table -->
							<div class="col-span-12" v-if="currentModel?.vertexEvaluation?.render">
								<div v-html="currentModel.vertexEvaluation.render"></div>
							</div>

						</div>
		
						<!-- Semi-supervised results --> 
						<table class="table table--sm text-xs text-left mt-5" v-if="currentModel.db?.aws && !semiSupervisedEvaluationObjects?.length && !currentModel.awsEvaluation?.loading">
							<tbody>
								<tr v-if="currentModel.db?.aws">
									<td class="border w-52"><span>{{$t('Identifier')}}</span></td>
									<td class="border"><span>{{ currentModel.db.aws }}</span></td>
								</tr>
								<tr v-if="currentModel.db?.aws && (currentModel.db.ModelVersion || currentModel?.awsModel?.response?.ModelDescription?.ModelVersion)">
									<td class="border"><span>{{$t('Version')}}</span></td>
									<td class="border">
										<span v-if="currentModel.db.ModelVersion">{{  currentModel.db.ModelVersion }}</span>
										<span v-if="currentModel?.awsModel?.response?.ModelDescription?.ModelVersion!=currentModel.db.ModelVersion">({{ currentModel?.awsModel?.response?.ModelDescription?.ModelVersion }})</span>
									</td>
								</tr>
								<tr v-if="currentModel?.awsModel && currentModel?.awsModel?.response?.ModelDescription?.Status && currentModel?.awsModel?.response?.ModelDescription?.Status!='TRAINING_FAILED'">
									<td class="border">{{$t('Packages')}}</td>
									<td class="border text-left" v-if="currentModel.packageJobs?.response?.ModelPackagingJobs.length">
										<div v-for="job in currentModel.packageJobs?.response?.ModelPackagingJobs" :key="job" class=" mt-3 mb-3">
											<span class="py-1 px-5 rounded-full text-xs cursor-pointer mr-2" 
											:class="job.Status=='SUCCEEDED'?'bg-green-600 text-white':'bg-gray-300 text-gray-600'"
											@click="currentModel.packageJob=job.JobName; modal('modal-semi-supervised-packageJob')">{{ job.JobName }}</span> 
										</div>
									</td>
									<td class="border" v-else>
										<div class="text-left mt-1">{{$t('The model does not packaging jobs')}}.</div>
										<button type="button" class="btn bg-gray-600 font-normal text-white mt-2">{{ $t('Create new package') }}</button>
									</td>
								</tr>
								<tr v-if="currentModel?.awsModel?.response?.ModelDescription?.Status">
									<td class="border"><span>{{$t('Status')}}</span></td>
									<td class="border" :class="currentModel?.awsModel?.response?.ModelDescription?.Status=='TRAINING_FAILED' ? 'text-red-500' : ''">
										<span>{{  currentModel?.awsModel?.response?.ModelDescription?.Status }}</span>
										<span v-if="currentModel?.awsModel?.response?.ModelDescription?.StatusMessage" class="pl-3">{{  currentModel?.awsModel?.response?.ModelDescription?.StatusMessage }}</span>
									</td>
								</tr>
								<tr v-if="currentModel?.awsModel?.response?.ModelDescription?.CreationTimestamp">
									<td class="border"><span>{{$t('Created')}}</span></td>
									<td class="border"><span>{{  currentModel?.awsModel?.response?.ModelDescription?.CreationTimestamp }}</span></td>
								</tr>
							</tbody>
						</table>

						<!-- AWS Performance metrics -->
						<div class="grid chat grid-cols-12 gap-6 mt-3" v-if="currentModel?.db?.aws">
							<div class="col-span-12 p-0">
								<div class="grid chat grid-cols-12 gap-5 py-1">
									
									<div class="col-span-12 text-left" v-if="currentModel?.awsEvaluation && currentModel?.awsEvaluation?.status!='error'">
										<div class="text-center">

											<div v-if="currentModel.awsEvaluation?.loading">
												<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0">
													<div class="flex items-center justify-center mt-5">  
														<img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" />
													</div>
													<LoadingIcon icon="three-dots" class="w-12 h-12" />
												</div>
											</div>

											<div v-if="!semiSupervisedEvaluationObjects?.length && !currentModel.awsEvaluation?.loading">
												<div class="grid chat grid-cols-12 gap-0 py-2 px-2 text-gray-600">
													<div class="col-span-12 text-gray-600 text-center mb-2">
														<h2 class="text-xl truncate">{{ $t('Model performance metrics') }}</h2>
														<div class="text-gray-600 text-xs font-normal text-center mt-2">{{$t('Use model performance metrics to find the strengths and weaknesses of the model')}}.</div>
													</div>
													<div class="col-span-4 text-center mx-auto mt-5">
														<div class="relative">
															<div class="w-72"><ReportDonutChart :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive/currentModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' /></div>
															<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																<div class="text-xl xxl:text-2xl font-medium">{{ ((currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive/currentModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0)  }}%</div>
																<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Precision')}}</div>
															</div>
														</div>
														<div class="mx-auto w-f xxl:w-3/4 mt-5">
															<div class="flex items-center">
																<span class="mr-2 font-bold">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
																<span class="truncate">{{$t('anomalies were correct of')}}</span>
															</div>
															<div class="flex items-center mt-1">
																<span class="mr-2 font-bold">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.total }}</span>
																<span class="truncate">{{$t('total predictions')}}</span>
															</div>
														</div>
													</div>
													<div class="col-span-4 text-center mx-auto mt-5">
														<div class="relative">
															<div class="w-72">
																<ReportDonutChart :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive/currentModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
															</div>
															<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																<div class="text-xl xxl:text-2xl font-medium">
																	{{ 
																	((((currentModel.awsEvaluation.manifestResume?.anomaly?.predictions+currentModel.awsEvaluation.manifestResume?.normal?.predictions)-(currentModel.awsEvaluation.manifestResume?.anomaly?.falsePositive+currentModel.awsEvaluation.manifestResume?.normal?.falseNegative))/currentModel.awsEvaluation.manifestResume?.totalTestImages)*100).toFixed(0) 
																	}}%</div>
																<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('F1 Score')}}</div>
															</div>
														</div>
														<div class="mx-auto w-full mt-5 text-center">
															<span class="truncate">{{$t('The overall performance')}}</span>
														</div>
													</div>
													<div class="col-span-4 text-center mx-auto mt-5">
														<div class="relative">
															<div class="w-72">
																<ReportDonutChart  :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive/currentModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
															</div>
															<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																<div class="text-xl xxl:text-2xl font-medium">{{ ((currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive/currentModel.awsEvaluation.manifestResume?.anomaly?.predictions)*100).toFixed(0)  }}%</div>
																<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Recovery')}}</div>
															</div>
														</div>
														<div class="mx-auto w-f xxl:w-4/4 mt-5">
															<div class="overflow-hidden">
																<div class="flex items-center">
																	<span class="truncate pl-5">{{$t('were correct')}}</span>
																	<span class="mr-2 ml-2 font-bold">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
																	<span class="truncate text-xs">{{$t('anomalies predicted of')}}</span>
																</div>
																<div class="flex items-center mt-1">
																	<span class="mr-2 font-bold pl-5">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.predictions }}</span>
																	<span class="truncate">{{$t('total anomalies')}}</span>
																</div>
															</div>
														</div>
													</div>
													<div class="col-span-12 mt-5 text-gray-600 text-center">
														<h2 class="text-xl truncate">{{ $t('About test results') }}</h2>
													</div>
													<div class="col-span-6 text-left mt-5 p-5 border ml-2 rounded mr-1">
														<h2 class="text-base font-medium truncate">{{ $t('Predicted as normal') }}</h2>
														<div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
															<div class="col-span-6">
																<div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true negative') }})</span></div>
																<h2 class="text-5xl font-medium truncate px-5">{{ currentModel.awsEvaluation.manifestResume?.normal?.trueNegative }}</h2>
															</div>
															<div class="col-span-6">
																<div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false negative') }})</span></div>
																<h2 class="text-5xl font-medium truncate px-5">{{ currentModel.awsEvaluation.manifestResume?.normal?.falseNegative }}</h2>
															</div>
														</div>   
													</div>
													<div class="col-span-6 text-left mt-5 p-5 border mr-2 rounded" style="border-left: none;">
														<h2 class="text-base font-medium truncate">{{ $t('Predicted as anomaly') }}</h2>
														<div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
															<div class="col-span-6">
																<div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true positive') }})</span></div>
																<h2 class="text-5xl font-medium truncate px-5">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</h2>
															</div>
															<div class="col-span-6">
																<div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false positive') }})</span></div>
																<h2 class="text-5xl font-medium truncate px-5">{{ currentModel.awsEvaluation.manifestResume?.anomaly?.falsePositive }}</h2>
															</div>
														</div>   
													</div>
												</div>
											</div>

											<div class="grid chat grid-cols-12 gap-2 text-gray-600 mt-0" v-else>
												<div class="col-span-12" v-if="currentModel?.awsEvaluation?.order && !currentModel.awsEvaluation?.loading">
													<select class="form-select bg-white cursor-pointer w-44 mt-0" v-model="currentModel.awsEvaluation.filterPrediction" @change="loadEvaluationObjects(currentModel?.awsEvaluation?.manifest)">
														<option value="all">{{$t('All prediction')}}</option>
														<option value="correct">{{$t('Correct')}}</option>
														<option value="incorrect">{{$t('Incorrect')}}</option>
													</select>
													<select class="form-select bg-white cursor-pointer w-60 mt-0 ml-2" v-model="currentModel.awsEvaluation.order" @change="loadEvaluationObjects(currentModel?.awsEvaluation?.manifest)">
														<option value="asc">{{$t('Lowest confidence')}}</option>
														<option value="desc">{{$t('Highest confidence')}}</option>
													</select>
													<div style="display: inline-block" class="w-44 ml-5"><Slider v-model="currentModel.awsEvaluation.sizeObject" :min="25" :max="75" :step="25" :merge="25" :tooltips="false" :lazy="true" /></div>
													<div v-if="!semiSupervisedEvaluationObjects[0]?.empty" class="text-xs text-center pt-3 pb-2">
														- {{$t('Showing')}} {{ semiSupervisedEvaluationObjects?.length }} {{$t('of')}} {{ currentModel.awsEvaluation.manifestResume?.totalTestImages }} {{$t('test images')}} - 
													</div>
												</div>
												<div v-if="semiSupervisedEvaluationObjects[0]?.empty" class="col-span-12 py-20">- {{$t('No results')}} -</div> 
												<div v-else class="col-span-12 text-gray-600 text-center">
													<div v-for="(t,indx) in semiSupervisedEvaluationObjects" :key="indx" class="relative inline-block rounded border border-gray-200 mb-2"
														:style="currentModel.awsEvaluation.sizeObject ? 'float: left !important; width: '+((currentModel.awsEvaluation.sizeObject==25 ? 33 : currentModel.awsEvaluation.sizeObject==75 ? 100 : currentModel.awsEvaluation.sizeObject)-0.5)+'% !important; margin-right: 0.5% !important' : ''">
														<div class="pt-3 px-2 pb-10 rounded border" style="background-color: #fff">
															<div class="text-lg mb-3 truncate">{{ semiSupervisedEvaluationObjects[indx]?.obj['source-ref'].toString().split("/").pop() }}</div>
															<img class="w-full rounded" :src="semiSupervisedEvaluationObjects[indx]?.imageSrc?.renderSrc" />
															<div v-if="currentModel?.awsEvaluation?.manifestResume">
																<div class="pt-3 text-left font-medium" :class="currentModel.awsEvaluation.sizeObject!=25 ? 'px-2' : ''">
																	<span v-if="semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label']==semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected']">
																		<img :src="require(`@/assets/images/rosepetal/icon/tick.png`)" class="w-5" style="display: inline" /> <span class="text-green-700 align-middle font-normal">{{$t('Correct')}}</span> 
																	</span>
																	<span v-else><X-circleIcon  class="text-red-600 w-5 h-5" /> <span class="text-red-700">{{$t('Incorrect')}}</span></span>
																	<span class="float-right ml-aut text-gray-600 text-center">
																		<span class="text-xs text-gray-500">{{$t('Confidence')}}</span><br />
																		<span class="text-lg">{{ (semiSupervisedEvaluationObjects[indx].obj['anomaly-label-detected-metadata']['confidence']*100).toFixed(1) }}%</span>
																	</span>
																	<span class="float-right ml-aut text-gray-600 text-center mr-8">
																		<span class="text-xs text-gray-500">{{$t('Prediction')}}</span><br />
																		{{ semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected-metadata']['class-name'] }}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div> 
											</div>

										</div>
									</div>

									<div v-else-if="currentModel?.awsEvaluation?.status=='error'" class="col-span-12 text-left text-xs">
										<div class="text-center">- {{ currentModel.awsEvaluation.error }} - </div>
									</div>

									<div class="col-span-12 text-center text-lg">{{$t('Training data')}}</div>

									<div class="col-span-5 text-center mr-5">
										<div class="relative">
											<ReportDonutChart :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
												labels: [ "Normal", "Anomaly" ],
												datasets: [ {
													data: [ currentModel?.response?.dataset?.test.DatasetDescription.ImageStats["Normal"], currentModel?.response?.dataset?.test.DatasetDescription.ImageStats["Anomaly"] ],
													backgroundColor: [ "#1d8102", "#d13212" ]
												}]
											}' />
											<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
												<div class="text-xl xxl:text-2xl font-medium">{{ this.helper.formatScore(currentModel?.response.dataset.train.DatasetDescription.ImageStats["Total"]+currentModel.response.dataset.test.DatasetDescription.ImageStats["Total"]) }}</div>
												<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
											</div>
										</div>
										<div class="mx-auto w-10/12 xxl:w-2/3 mt-8">
											<div class="flex items-center">
												<div class="w-2 h-2 bg-theme-26 rounded-full mr-3" style="background-color: #1d8102"></div>
												<span class="truncate">{{$t('Normal')}}</span>
												<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
												<span class="font-medium xl:ml-auto">
													{{ ((currentModel.response.dataset.test.DatasetDescription.ImageStats["Normal"]/currentModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
												</span>
											</div>
											<div class="flex items-center mt-2">
												<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #d13212"></div>
												<span class="truncate">{{$t('Anomaly')}}</span>
												<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
												<span class="font-medium xl:ml-auto">
													{{ ((currentModel.response.dataset.test.DatasetDescription.ImageStats["Anomaly"]/currentModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
												</span>
											</div>
										</div>
									</div>

									<div class="col-span-7 pb-0 text-left">
										<table class="table table--sm text-xs text-center">
											<tbody>
												<tr>
													<td></td>
													<td class="border bg-gray-100"><span>{{$t('TEST')}}</span></td>
													<td class="border bg-gray-100"><span>{{$t('TRAIN')}}</span></td>
												</tr>
												<tr v-for="(t,indx) in currentModel.response.dataset.test.DatasetDescription.ImageStats" :key="indx">
													<td class="border p-2 bg-gray-100">{{ indx }}</td>
													<td class="border p-2"><span>{{ t }}</span></td>
													<td class="border p-2" v-if="currentModel.response.dataset.train.DatasetDescription.ImageStats[indx]"><span>{{ currentModel.response.dataset.train.DatasetDescription.ImageStats[indx] }}</span></td>
												</tr>
											</tbody>
										</table>
										<div v-if="currentModel.db && currentModel.db.tagMap" class="pt-5">
											<div class="text-sm text-gray-600">{{$t('Label Map')}}</div>
											<div class="grid chat grid-cols-12 gap-1 py-2  text-gray-600">
												<div class="col-span-6 text-left">
													<table class="table table--sm text-xs">
														<tbody>
															<tr><td class="border text-center text-green-700"><span>{{$t('NORMAL')}}</span></td></tr>
															<tr v-for="(t,indx) in JSON.parse(currentModel.db.tagMap)['normal']" :key="indx">
																<td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
															</tr>
														</tbody>
													</table>
												</div>
												<div class="col-span-6 text-left">
													<table class="table table--sm text-xs">
														<tbody>
															<tr><td class="border text-center text-red-700"><span>{{$t('ANOMALY')}}</span></td></tr>
															<tr v-for="(t,indx) in JSON.parse(currentModel.db.tagMap)['anomaly']" :key="indx">
																<td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div> 
										</div>
									</div>

								</div>
							</div>
						</div>

						<!-- Vision Platform Results -->
						<div class="grid chat grid-cols-12 gap-6" v-if="currentModel?.db?.evaluation?.service === 'visionPlatform'">

							<!-- Evaluation Diagram Section -->
							<div class="col-span-5 text-center mt-8" v-if="currentModel.db.evaluation?.status === 'finished'">
								
								<!-- Donut Diagram -->
								<div class="relative" v-if="currentModel.db.evaluation?.dataDivision">
									
									<!-- Graphic -->
									<ReportDonutChart
										:height="190"
										:options="{ legend: { display: false }, cutoutPercentage: 70 }"
										:data="{
											labels: ['Train', 'Test', 'Validation'],
											datasets: [{
												data: [
													currentModel.db.evaluation.dataDivision.train,
													currentModel.db.evaluation.dataDivision.test,
													currentModel.db.evaluation.dataDivision.validation
												],
												backgroundColor: ['#333333', '#1d8102', '#09491b']
											}]
										}"
									/>

									<!-- Total images value -->
									<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
										<div class="text-xl xxl:text-2xl font-medium">
											{{ 
												parseInt(currentModel.db.evaluation.dataDivision.train) +
												parseInt(currentModel.db.evaluation.dataDivision.test) + 
												parseInt(currentModel.db.evaluation.dataDivision.validation)
											}}
										</div>
										<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
									</div>

								</div>

								<!-- Donut Diagram values-->
								<div class="mx-auto w-10/12 xxl:w-2/3 mt-3" v-if="currentModel.db.evaluation?.dataDivision">
									
									<!-- Train value -->
									<div class="flex items-center mt-2">
										<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #333333"></div>
										<span class="truncate">{{$t('Train')}}</span>
										<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
										<span class="font-medium xl:ml-auto">{{ currentModel.db.evaluation.dataDivision.train }}</span>
									</div>

									<!-- Test value -->
									<div class="flex items-center mt-2">
										<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
										<span class="truncate">{{$t('Test')}}</span>
										<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
										<span class="font-medium xl:ml-auto">{{ currentModel.db.evaluation.dataDivision.test }}</span>
									</div>

									<!-- Validation value -->
									<div class="flex items-center mt-2">
										<div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
										<span class="truncate">{{$t('Validation')}}</span>
										<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
										<span class="font-medium xl:ml-auto">{{ currentModel.db.evaluation.dataDivision.validation }}</span>
									</div>

								</div>

							</div>

							<!-- Model Configuration Table -->
							<div class="col-span-7 pb-0 text-left mt-5">
								
								<table class="table table--sm text-xs text-left">
									
									<tbody>

										<tr v-if="currentModel.db.evaluation?.configuration?.budget">
											<td class="border"><span>{{$t('Budget')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ currentModel.db.evaluation.configuration.budget }} {{$t('milliNodeHours')}}</span>
											</td>
										</tr>

										<tr v-if="currentModel.db.evaluation?.configuration?.cost">
											<td class="border"><span>{{$t('Cost')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ currentModel.db.evaluation.configuration.cost }} {{$t('milliNodeHours')}}</span>
											</td>
										</tr>

										<tr v-if="currentModel.db.evaluation?.configuration?.stopReason">
											<td class="border"><span>{{$t('Stop reason')}}</span></td>
											<td class="border bg-gray-100">
												<span :class="currentModel.db.evaluation.configuration.stopReason =='MODEL_CONVERGED' ? 'text-green-500' : 'text-blue-300'">
													{{ currentModel.db.evaluation.configuration.stopReason }}
												</span>
											</td>
										</tr>

										<tr v-if="currentModel.db.evaluation?.configuration?.createdAt">
											<td class="border"><span>{{$t('Created')}}</span></td>
											<td class="border bg-gray-100">
												<span>{{ currentModel.db.evaluation.configuration.createdAt }}</span>
											</td>
										</tr>

									</tbody>

								</table>

							</div>

							<!-- Model Performance Metrics -->
							<div class="col-span-12 text-left" v-if="currentModel?.db?.evaluation?.metrics && currentModel?.db?.evaluation?.status == 'finished'">
								<div class="text-center">

									<div v-if="Layout.loading">
										<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0">
											<div class="flex items-center justify-center mt-5">  
												<img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" />
											</div>
											<LoadingIcon icon="three-dots" class="w-12 h-12" />
										</div>
									</div>

									<div v-else>

										<div class="grid chat grid-cols-12 gap-0 py-2 px-2 text-gray-600">

											<!-- Title -->
											<div class="col-span-12 text-gray-600 text-center mb-2">
												<h2 class="text-xl truncate">{{ $t('Model performance metrics') }}</h2>
												<div class="text-gray-600 text-xs font-normal text-center mt-2">{{$t('Use model performance metrics to find the strengths and weaknesses of the model')}}.</div>
											</div>

											<!-- Precision -->
											<div class="col-span-4 text-center mx-auto mt-5">

												<div class="relative">
													<div class="w-72">
														<ReportDonutChart 
															:options="{ legend: { display: false }, cutoutPercentage: 80 }" 
															:data='{ 
																labels: ["Precision"],
																datasets: [{ 
																	data: [ (currentModel.db.evaluation.metrics.precision * 100).toFixed(0) ], 
																	backgroundColor: [ "#1d8102" ]
																}] 
															}' 
														/>
													</div>
													<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
														<div class="text-xl xxl:text-2xl font-medium">{{ (currentModel.db.evaluation.metrics.precision * 100).toFixed(0) }}%</div>
														<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Precision')}}</div>
													</div>
												</div>

												<!-- <div class="mx-auto w-f xxl:w-3/4 mt-5">
													<div class="flex items-center">
														<span class="mr-2 font-bold">{{ currentModel.db.evaluation.manifestResume?.anomaly?.truePositive }}</span>
														<span class="truncate">{{$t('anomalies were correct of')}}</span>
													</div>
													<div class="flex items-center mt-1">
														<span class="mr-2 font-bold">{{ currentModel.db.evaluation.manifestResume?.anomaly?.total }}</span>
														<span class="truncate">{{$t('total predictions')}}</span>
													</div>
												</div> -->

											</div>
											
											<!-- F1 Score -->
											<div class="col-span-4 text-center mx-auto mt-5">

												<div class="relative">
													<div class="w-72">
														<ReportDonutChart 
															:options="{ legend: { display: false }, cutoutPercentage: 80 }" 
															:data='{ 
																labels: ["F1 Score"],
																datasets: [{ 
																	data: [ (currentModel.db.evaluation.metrics.f1 * 100).toFixed(0) ], 
																	backgroundColor: [ "#1d8102" ]
																}] 
															}' 
														/>
													</div>
													<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
														<div class="text-xl xxl:text-2xl font-medium">{{ (currentModel.db.evaluation.metrics.f1 * 100).toFixed(0) }}%</div>
														<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('F1 Score')}}</div>
													</div>
												</div>

												<div class="mx-auto w-full mt-5 text-center">
													<span class="truncate">{{$t('Overall performance')}}</span>
												</div>

											</div>
											
											<!-- Recall -->
											<div class="col-span-4 text-center mx-auto mt-5">
												
												<div class="relative">
													<div class="w-72">
														<ReportDonutChart 
															:options="{ legend: { display: false }, cutoutPercentage: 80 }" 
															:data='{ 
																labels: ["Recall"],
																datasets: [{ 
																	data: [ (currentModel.db.evaluation.metrics.recall * 100).toFixed(0) ], 
																	backgroundColor: [ "#1d8102" ]
																}] 
															}' 
														/>
													</div>
													<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
														<div class="text-xl xxl:text-2xl font-medium">{{ (currentModel.db.evaluation.metrics.recall * 100).toFixed(0) }}%</div>
														<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Recall')}}</div>
													</div>
												</div>

												<!-- <div class="mx-auto w-f xxl:w-4/4 mt-5">
													<div class="overflow-hidden">
														<div class="flex items-center">
															<span class="truncate pl-5">{{$t('were correct')}}</span>
															<span class="mr-2 ml-2 font-bold">{{ currentModel.db.evaluation.manifestResume?.anomaly?.truePositive }}</span>
															<span class="truncate text-xs">{{$t('anomalies predicted of')}}</span>
														</div>
														<div class="flex items-center mt-1">
															<span class="mr-2 font-bold pl-5">{{ currentModel.db.evaluation.manifestResume?.anomaly?.predictions }}</span>
															<span class="truncate">{{$t('total anomalies')}}</span>
														</div>
													</div>
												</div> -->

											</div>

										</div>
									</div>

								</div>
							</div>

							<!-- Evaluation Graphics (Tags, Confusion Matrix) -->
							<div class="col-span-12 flex justify-center items-center" v-if="currentModel?.db?.evaluation?.render">
								<div v-html="currentModel.db.evaluation.render"></div>
							</div>

						</div>

					</div>

				</div>
			</div>

		</div>
  
		<!-- Delete model --> 
		<div id="model-delete-box"  class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body p-5 text-center">
						<div class="p-5 px-2 text-center" v-if="currentModel?.db">
							<div class="text-base mt-5">{{ $t('Are you sure to delete this model') }}?</div>
							<div class="text-red-700 text-base mt-1">{{currentModel.db.id}}</div>  
							<div class="text-gray-600 mt-1">{{ $t('This action is irreversible') }}.</div>
						</div>
						<div class="px-5 pb-3 text-center">
							<button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in">{{ $t('Cancel') }}</button>
							<button type="button" class="btn bg-red-700 text-white w-24 zoom-in" @click="deleteModel(); refreshBtn()">{{ $t('Delete') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
  
		<!-- Train box types resume --> 
		<div id="modal-trainBox" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content pt-5">

					<h2 class="text-xl font-normal text-center pb-2">{{ $t('Select a goal you want to achieve with the trained model') }}</h2>

					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>

					<div class="modal-body py-0 px-3 text-center pb-5">
						<div class="grid chat grid-cols-12 gap-3 py-2 px-2 text-gray-600">

							<!-- SUPERVISED DETECTION -->
							<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left" :style="Dataset && Dataset?.data?.type && Dataset?.data?.type!='MULTILABEL' ? 'opacity: 1' : 'opacity: 0.6' ">
								<img :src="require(`@/assets/images/rosepetal/icon/newmodel_supervised.png`)" class="w-full" />
								<div class="text-lg mb-2 mt-4 font-medium text-center text-gray-900 h-16">
									{{ $t('Supervised') }}
									<div class="text-sm pt-2 text-gray-700" v-if="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='imageObjectDetection')">{{ $t('Returns the bounding box of the object') }}</div>
								</div>
								<div class="text-sm text-center mx-5 h-24">
									<template v-if="Dataset && Dataset?.data?.type && Dataset?.data?.type=='imageObjectDetection'">
										{{ $t('Each instance is mapped with one or more classes, providing not only the labels but also the spatial information of the objects') }}.
									</template>
									<template v-else>
										{{ $t('Each instance belongs to only one class, and the probabilities of all the classes add up to 1') }}.
									</template>
								</div>
								<div class="pb-3 text-center">
									<button type="button" @click=" modalClose('modal-trainBox'); modalTrainSupervised()" class="btn w-44 bg-green-600 text-white zoom-in font-normal" v-if="Dataset && Dataset?.data?.type && Dataset?.data?.type!='MULTILABEL'">{{ $t('Create') }}</button> 
									<button type="button" v-else class="btn w-44 bg-gray-600 text-gray-500 cursor-default disabledButton font-normal">{{ $t('Unavailable') }}</button> 
								</div>
							</div>

							<!-- SEMI-SUPERVISED ANOMALY DETECTION -->
							<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left" :style="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='MULTICLASS' || Dataset?.data?.type=='MULTILABEL') ? 'opacity: 1' : 'opacity: 0.6' ">
								<img :src="require(`@/assets/images/rosepetal/icon/newmodel_semisupervised.png`)" class="w-full" />
								<div class="text-lg mb-2 mt-4 font-medium text-center text-gray-900 h-16">
									{{ $t('Semi-supervised anomaly detection') }}
									<div class="text-sm text-gray-700" v-if="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='MULTILABEL')">{{ $t('Returns heatmap of the detected label') }}</div>
								</div>
								<div class="text-sm text-center mx-5 h-24" :class="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='MULTILABEL') ? 'mt-4' : ''">
									{{ $t('Each instance is classified as "normal" or "anomaly", and the accuracy of identifying "normal" instances is mainly measured') }}.
								</div>
								<div class="pb-3 text-center">
									<button type="button" @click="modalClose('modal-trainBox'); modalTrainSemiSupervised()" class="btn w-44 bg-green-600 text-white zoom-in font-normal" v-if="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='MULTICLASS' || Dataset?.data?.type=='MULTILABEL')">{{ $t('Create') }}</button> 
									<button type="button" v-else class="btn w-44 bg-gray-600 text-gray-500 cursor-default disabledButton font-normal">{{ $t('Unavailable') }}</button> 
								</div>
							</div>

							<!-- VISION PLATFORM DETECTION -->
							<div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-2 text-left" style="opacity: 1">
								<img :src="require(`@/assets/images/rosepetal/icon/vision_platform_logo.png`)" class="w-full" />
								<div class="text-lg mb-2 mt-4 font-medium text-center text-gray-900 h-16">
									{{ $t('Vision Platform') }}
								</div>
								<div class="text-sm text-center mx-5 h-24">
									{{ $t('Train any type of model, offering flexibility to handle both supervised and unsupervised approaches.') }}.
								</div>
								<div class="pb-3 text-center">
									<button type="button" @click=" modalClose('modal-trainBox'); modalTrainVisionPlatform()" class="btn w-44 bg-green-600 text-white zoom-in font-normal">{{ $t('Create') }}</button> 
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
  
		<!-- Train Supervised Vertex Model --> 
		<div id="model-supervised-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static" :style="trainVertex.training ? 'z-index: 99999;position: relative;' : ''">
			<div class="modal-dialog modal-xl" style="min-width: 550px;">
				<div class="modal-content p-5">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer p-5" data-dismiss="modal"><XIcon class="w-8 h-8" v-if="trainVertex.setup || trainVertex.training"  /></label>
					<div class="modal-body py-2 px-0 text-center" v-if="Dataset">
						<div class="grid chat grid-cols-12">

							<div class="col-span-12" v-if="Layout.loading">
								<div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
								<div class="intro-y flex flex-col justify-center items-center mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
							</div>

							<div class="col-span-12" v-else>
								<div class="grid chat grid-cols-12 px-10">

									<div class="col-span-12 mt-2">

										<!-- SETUP -->
										<div class="grid chat grid-cols-12" v-if="trainVertex.setup">
											
											<div class="col-span-12">
												<div class="text-center">
													<div class="text-center"><div class="text-2xl font-medium">{{ $t('Train a new supervised model') }}</div></div>
													<div class="text-gray-700">{{$t('Use this dataset and annotation set to train a new machine learning model')}}</div> 
												</div>
											</div>
				
											<div class="col-span-12 mt-2" v-if="Dataset?.validateToTrain && !Dataset?.validateToTrain?.validated && trainVertex?.tagMap && supervisedTagMap.selected && Object.keys(supervisedTagMap.selected).length">
												<div class="text-left w-full p-5 rounded border my-3">
													<div class="mr-5 mb-3">{{$t('Now the dataset cannot be training, check requirements and warnings for train')}}.</div>
													<div class="grid chat grid-cols-12 mt-5 text-xs">
														<div class="col-span-7" v-if="(Dataset.validateToTrain.errors && Dataset.validateToTrain.errors.length)">
															<div class="pr-3 text-red-400 text-sm mb-5" v-if="Dataset.validateToTrain.errors.length"><AlertCircleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.errors.length}} {{$t('Requirements')}}</div>
															<div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.errors" :key="k"> {{k}}</div>  
														</div>
														<div class="col-span-5" v-if="(Dataset.validateToTrain.warnings && Dataset.validateToTrain.warnings.length)" >
															<div v-if="Dataset.validateToTrain.warnings.length" class="text-sm mb-5"><AlertTriangleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.warnings.length}} {{$t('Warnings')}}</div>
															<div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.warnings" :key="k">{{k}}</div> 
														</div>
													</div>
													<div class="mt-5 text-xs"><span class="text-blue-500 cursor-pointer" @click="manageTags()">Manage tags</span> | <span class="text-blue-500 cursor-pointer" @click="linkToBrowser()">Labeling</span></div>
												</div>
											</div>
					
											<div class="col-span-12 mt-2">
												<div class="text-left">
													<div class="mb-3 ">{{ $t('Model name') }} *</div>
													<input id="train-name" type="text" :maxlength="32" v-model="trainVertex.modelName" @input="removeHyphens()" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10" required   />
													<div class="text-xs pt-2 text-center">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
												</div>
											</div>
					
											<!-- TAG SELECTION -->
											<div class="col-span-12 mt-5">
												<div class="text-left">{{ $t('Select the tags you want to include in the training') }} *</div>

												<div class="grid grid-cols-12 gap-1" v-if="supervisedTagMap">
													
													<div class="col-span-6 overflow-hidden">
														<div class="bg-gray-300 h-full px-3">
															<draggable class="list-group bg-gray-300 h-full pt-5 mt-5 rounded pb-3" :list="supervisedTagMap.labels" group="people" @change="refreshTagMapSupervised" v-if="Object.keys(Dataset?.tags).length">
																<template #item="{ element }"><div class="list-group-item bg-theme-2 text-white text-center mb-1 cursor-pointer p-2 mx-2 text-xs rounded truncate" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div></template>
															</draggable>
															<div v-else class="pt-5 mb-5">{{ $t('Dataset does not contain tags') }} <div class="text-blue-500 text-xs text-center cursor-pointer" @click="manageTags()">{{ $t('create tags') }}</div></div>
														</div>
													</div>

													<div class="col-span-6 mt-5 overflow-hidden" >
														<div class="bg-green-100 h-full px-3">
															<draggable class="list-group h-full pt-5 pb-10" :list="supervisedTagMap.selected" group="people" >
																<template #item="{ element }">
																	<div class="list-group-item bg-green-500 text-white text-center mb-1 cursor-pointer p-2 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">
																		<div class="font-medium">{{ element.name }}</div>
																		<input type="text" class="w-full py-2 px-3 border-transparent bg-white text-gray-900 mt-2" v-model="element.vertexLabel" :placeholder="'Label'" @change="refreshTagMapSupervised" />
																	</div>
																</template>
															</draggable>
														</div>
													</div>

												</div>

											</div>

											<div class="col-span-12 mt-5" v-if="supervisedTagMap?.selected && Object.keys(supervisedTagMap?.selected).length && trainVertex?.tagMap">
												<div class="grid chat grid-cols-12">

													<div class="col-span-12">
														<div class="mb-3 ">{{ $t('The train budget of creating this model') }} *</div>
														<div v-if="Dataset.data.type!='imageObjectDetection'" class="text-xs mb-5">
															<span>{{$t('Recommended')}}</span> <span class="py-1 rounded-full text-xs bg-gray-400 text-gray-600 cursor-pointer px-5 ml-2">{{ trainVertex.modelTrainBudgetObjMin+" "+$t('node hours')}}</span>
														</div> 
														<div class="relative text-gray-700 dark:text-gray-300 px-5">
															<Slider v-model="trainVertex.modelTrainBudget" 
																	:min="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMin : trainVertex.modelTrainBudgetMin" 
																	:max="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMax : trainVertex.modelTrainBudgetMax" 
																	:step="trainVertex.modelTrainBudgetStep" 
																	:merge="trainVertex.modelTrainBudgetStep" />
															<div class="text-xs pt-2 mb-2">
																{{$t('The budget for the creation of this model, expressed in milli node hours, that is, the value of 1000 in this field means 1 node hour')}}.
																<div class="font-bold">{{$t('24.000 represents one day in wall time')}}.</div>
															</div>
														</div>
													</div>

													<div class="col-span-12 mt-3">
														<div class="text-left">
															<div class="text-left">

																<div class="pb-5 text-center">
																	<input v-model="trainVertex.method" class="form-check-input mr-1" type="radio" value="labeled" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='labeled' ? 'text-blue-500' : ''">{{ $t('Current labeled') }}</span>
																	<input v-model="trainVertex.method" class="form-check-input ml-10 mr-1" type="radio" value="auto" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='auto' ? 'text-blue-500' : ''">{{ $t('Auto assignment') }}</span> 
																</div>

																<div v-if="trainVertex.method=='labeled'">
																	<div class="grid chat grid-cols-12 mb-10">
																		<div class="col-span-5">
																			<div v-if="Dataset.vertexDivision" class="p-5 pt-0">
																				<div class="relative">
																					<ReportDonutChart :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
																						labels: [ "Train", "Test", "validation", "Predetermined" ],
																						datasets: [ {
																							data: [ 
																								Dataset.vertexDivision?.train, Dataset.vertexDivision?.test, Dataset.vertexDivision?.validation, Dataset.vertexDivision?.predetermined
																							],
																							backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
																						}
																						]
																					}' />
																					<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																						<div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
																						<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="col-span-7">
																			<div class="text-left text-xs mx-12">
																				<div class="mb-2">{{ $t('Data division') }} *</div>
																				{{ $t('Your datasets will be manually split into training, validation, and test sets with the following ratios') }}: 
																			</div>
																			<div class="mx-auto w-10/12 xxl:w-2/3 mt-5">
																				<div class="flex items-center">
																					<div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
																					<span class="truncate">{{$t('Train')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.train  }} ({{ ((Dataset.vertexDivision?.train*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
																					<span class="truncate">{{$t('Test')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.test }} ({{ ((Dataset.vertexDivision?.test*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
																					<span class="truncate">{{$t('Validation')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.validation }} ({{ ((Dataset.vertexDivision?.validation*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2" v-if="Dataset.vertexDivision">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #b7b7b7"></div>
																					<span class="truncate">{{$t('Predetermined')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.predetermined }} ({{ ((Dataset.vertexDivision?.predetermined*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																			</div>
																			<div class="text-center text-xs mt-5">
																				{{ $t('You can configure the set by image through') }} <span class="text-blue-600 cursor pointer" @click="linkToBrowser()">{{ $t('data labeling') }}</span>
																			</div>
																		</div>
																	</div>
																</div>

																<div v-if="trainVertex.method=='auto'">
																	<div class="grid chat grid-cols-12">
																		<div class="col-span-5 mb-10">
																			<div v-if="Dataset.vertexDivision" class="p-5 pt-0">
																				<div class="relative">
																					<ReportDonutChart :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
																						labels: [ "Train", "Test", "validation" ],
																						datasets: [ {
																							data: [ trainVertex.train, trainVertex.test, trainVertex.validation],
																							backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
																						}
																						]
																					}' />
																					<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																						<div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
																						<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
																					</div>
																				</div>
																			</div>
																			<div class="mx-auto w-10/12 xxl:w-2/3">
																				<div class="flex items-center">
																					<div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
																					<span class="truncate">{{$t('Train')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.train*Dataset.vertexDivision?.total)/100).toFixed(0)}}
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
																					<span class="truncate">{{$t('Test')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.test*Dataset.vertexDivision?.total)/100).toFixed(0)}}
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
																					<span class="truncate">{{$t('Validation')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.validation*Dataset.vertexDivision?.total)/100).toFixed(0)}} 
																					</span>
																				</div>
																			</div>
																		</div> 
																		<div class="col-span-7">
																			<div class="text-left text-xs mx-12">
																				<div class="mb-2">{{ $t('Data division') }} *</div> 
																				{{ $t('Your dataset will be automatically randomized and split into training, validation, and test sets with the following ratios') }}:
																			</div>
																			<div class="text-left text-xs mx-12 bg-red-200 text-gray-800 py-3 mt-5 pl-5" v-if="autoDivisionAlert">
																				<AlertTriangleIcon class="w-4 h-4 mr-1" /> 
																				{{$t('The division of data must add up to 100%')}}. 
																			</div>
																			<div class="grid chat grid-cols-12 mx-20 my-2 mt-8">
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Train') }} 
																						<span v-if="trainVertex.train" class="ml-auto float-right text-xs">{{trainVertex.train}}%</span>
																					</div>
																					<Slider v-model="trainVertex.train" :min="50" :max="100" :step="5" :merge="1" :tooltips="false" :lazy="true" />
																				</div>
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Test') }}  
																						<span v-if="trainVertex.test" class="ml-auto float-right text-xs">{{trainVertex.test}}%</span>
																					</div>
																					<Slider v-model="trainVertex.test" :min="0" :max="50" :step="5" :merge="1" :tooltips="false" :lazy="true" />
																				</div>
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Validation') }} 
																						<span v-if="trainVertex.validation" class="ml-auto float-right text-xs">{{trainVertex.validation}}%</span>
																					</div>
																					<Slider v-model="trainVertex.validation" :min="0" :max="50" :step="5" :merge="1" :tooltips="false" :lazy="true" />
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div> 
													</div>
													
													<div class="col-span-12 text-center mt-5" v-if="Dataset?.validateToTrain?.validated && !autoDivisionAlert">
														<button type="button" class="btn bg-theme-15 w-44 mr-1 zoom-in py-4" @click="trainSupervised()">{{ $t('Train') }}</button>
														<button type="button" class="btn bg-theme-15 w-44 mr-1 zoom-in py-4" @click="trainSupervised(true)">{{ $t('Upload vertex') }}</button>
													</div>

												</div>
											</div>

										</div>

										<!-- OTHER -->
										<div v-else>
											<div class="col-span-12">

												<div v-if="trainVertex.error">
													<div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
														<div>{{  $t('An error has occurred that prevents training from starting')  }}.</div>
														<div class="py-3 text-red-600">{{ trainVertex.error }}</div>
														<button type="button" class="btn bg-theme-15 text-gray-800 w-32 mr-l zoom-in py-2 mt-5" @click="trainVertex.setup=true; trainVertex.error=false">{{ $t('Back') }}</button>
													</div>
												</div>

												<div v-if="trainVertex.onlyUpload">
													<div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
														<div>{{  $t('Data successfully uploaded to Google Vertex')  }}.</div>
														<button type="button" class="btn bg-theme-15 text-gray-800 w-32 mr-l zoom-in py-2 mt-5" @click="trainVertex.setup=true; trainVertex.error=false">{{ $t('Back') }}</button>
													</div>
												</div>

												<div v-else class="grid chat grid-cols-12 gap-0">
													<div class="col-span-12">
														<div class="intro-y flex flex-col justify-center items-center  text-center my-20 mx-10">
															<LoadingIcon icon="oval" class="w-20 h-20 mb-5" /><br />
															{{$t('Synchronizing and creating the model')}}.<br /> 
															{{$t('This process can take several minutes')}}.<br /> 
															<span class="text-theme-5 text-base">{{$t("Don't close your browser")}}</span>
														</div>
													</div>  
												</div>

											</div> 
										</div>

										<!-- TRAINING -->
										<div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="trainVertex.training" 
											:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
											<div class="text-center">{{ $t('Model is training, please wait ...') }}</div> <div @click="trainVertex =false; refreshBtn()" class="ml-5 bg-green-500 text-white py-2 px-5 rounded cursor-pointer">{{ $t('Continue') }}</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Train Semi-supervised AWS Model --> 
		<div id="model-semi-supervised-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-xl" style="min-width: 550px;">
                <div class="modal-content">

					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer p-5" data-dismiss="modal"><XIcon class="w-8 h-8" v-if="Dataset && !Dataset.trainSemiSupervisedAction && !Dataset.trainSemiSupervisedError"  /></label>

					<div class="modal-body p-2 text-center" v-if="Dataset">
						<div class="grid chat grid-cols-12">

							<div class="col-span-12 mt-5 mb-2" v-if="!Dataset.trainSemiSupervisedSuccess">
								<div class="text-center">
									<div class="text-center"><div class="text-2xl font-medium">{{ $t('Semi-supervised anomaly detection') }}</div></div>
									<div class="text-gray-700">{{$t('Use this dataset and annotation set to train a new machine learning model')}}</div> 
								</div>
							</div>

							<div class="col-span-12">

								<!-- TRAINING ERROR -->
								<div id="semi-supervised-training-error" v-if="Dataset.trainSemiSupervisedError">
									<div class="text-left mt-5 text-gray-800 pb-3 pl-5 mb-3">
										<div>{{  $t('An error has occurred that prevents training from starting')  }}.</div>
										<div class="py-2 text-red-600">{{ Dataset.trainSemiSupervisedError.toString().replace("Amazon Lookout for Vision","")  }}</div>
										<button type="button" class="btn bg-theme-15 text-gray-800 w-24 mr-l zoom-in py-2 mt-3" @click="Dataset.trainSemiSupervisedError=false">{{ $t('Back') }}</button>
									</div>
								</div>

								<div v-else class="grid chat grid-cols-12 mx-10">

									<!-- REQUIREMENTS ALERTS -->
									<div class="col-span-12 mt-2" v-if="!Dataset.trainSemiSupervisedAction && semiSupervisedTagMap?.resume?.errors?.length">
										<div class="text-left w-full p-5 rounded border my-3">
											<div class="mr-5 mb-2">{{$t('Now the dataset cannot be training, check requirements for train')}}.</div>
											<span class="pr-3 text-red-400" ><AlertCircleIcon class="w-4 h-4" /> <span class="ml-1">{{semiSupervisedTagMap?.resume?.errors.length}} {{$t('Requirements')}}</span></span>
											<div class="grid chat grid-cols-12 mt-3 text-xs">
												<div class="col-span-12" v-if="semiSupervisedTagMap?.resume?.errors?.length">
													<div class="w-full text-truncate font-italic" v-for="(e) in semiSupervisedTagMap?.resume?.errors" v-bind:value="wd" v-bind:key="e">{{e}}</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-span-12" v-else>
										<div v-if="!semiSupervisedTagMap?.resume?.total && Dataset?.data?.type!='MULTILABEL'">
											<div class="flex items-center justify-center text-center mb-3 border rounded py-5 mt-3">
												<div class="grid grid-cols-12 gap-1">
													<div class="col-span-2 flex justify-end">
														<Mouse-pointerIcon class="w-10 h-10 text-gray-600" />
													</div>
													<div class="col-span-9 flex items-center">
														<div class="text-sm text-gray-600 ml-5 mt-1">
															{{ $t('Drag and drop the labels to classify in the data set as normal or anomaly') }}.<br />
															{{ $t('Add anomaly labels only if you want your model to find anomalous areas') }}.
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-span-12" v-if="!Dataset.trainSemiSupervisedAction">

										<div class="grid grid-cols-12 gap-1 mb-10">

											<!-- TAGS COLUMN -->
											<div class="col-span-4 pt-14 mt-11 rounded overflow-hidden" v-if="Dataset?.data?.type!='MULTILABEL'">

												<div class="bg-gray-300 h-full px-3">
													<draggable v-if="Object.keys(Dataset?.tags).length" class="list-group h-full pt-5 mt-20 rounded pb-5" :list="semiSupervisedTagMap.labels" group="people" @change="refreshTagMapSemiSupervised">
														<template #item="{ element }">
															<div class="list-group-item bg-theme-2 text-white text-center mb-1 cursor-pointer p-3 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div>
														</template>
													</draggable>
													<div v-else class="pt-5">{{ $t('Dataset does not contain tags') }} 
														<div class="text-blue-500 text-xs text-center cursor-pointer" @click="manageTags()">{{ $t('create tags') }}</div>
													</div>
												</div>

											</div>

											<!-- NORMAL COLUMN -->
											<div class="overflow-hidden text-green-600" :class="Dataset?.data?.type!='MULTILABEL' ? 'col-span-4' : 'col-span-6'">

												<div class="text-lg font-medium text-center pt-2">{{ $t('Normal') }}</div>

												<div class="text-2xl font-medium text-center text-gray-600" style="margin-bottom: 56px;">
													<span v-if="semiSupervisedTagMap?.resume?.normal">{{ this.helper.formatScore(semiSupervisedTagMap.resume.normal) }}</span><span v-else>0</span>
													<div class="mx-5 text-base mt-2">
														<div class="flex items-center">
															<span class="truncate">{{$t('Test')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto">
																<span v-if="semiSupervisedTagMap?.resume?.division?.normal?.test">
																	<span v-if="semiSupervisedTagMap?.resume?.division?.normal?.validation">
																		{{ parseInt(semiSupervisedTagMap?.resume?.division?.normal?.test) + parseInt(semiSupervisedTagMap?.resume?.division?.normal?.validation) }}
																	</span>
																	<span v-else>{{ semiSupervisedTagMap?.resume?.division?.normal?.test }}</span>
																</span>
																<span v-else>0</span>
															</span>
														</div>
														<div class="flex items-center">
															<span class="truncate">{{$t('Train')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.normal?.train">{{ semiSupervisedTagMap?.resume?.division?.normal?.train }}</span><span v-else>0</span></span>
														</div>
													</div>
												</div>

												<div class="bg-green-100 h-full px-3" style="margin-top: 20px;">
													<draggable class="list-group h-full pt-5 pb-10" :list="semiSupervisedTagMap.normal" group="people" @change="refreshTagMapSemiSupervised">
														<template #item="{ element }">
															<div class="list-group-item bg-green-500 text-white text-center mb-1 cursor-pointer p-3 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div>
														</template>
													</draggable>
												</div>

											</div>

											<!-- ANOMALY COLUMN -->
											<div class="overflow-hidden text-red-400" :class="Dataset?.data?.type!='MULTILABEL' ? 'col-span-4' : 'col-span-6'">

												<div class="text-lg font-medium text-center pt-2">{{ $t('Anomaly') }}</div>

												<div class="text-2xl font-medium text-center mb-2 text-gray-600">
													<span v-if="semiSupervisedTagMap?.resume?.anomaly">{{ this.helper.formatScore(semiSupervisedTagMap.resume.anomaly) }}</span><span v-else>0</span>
													<div class="mx-5 text-base mt-2">
														<div class="flex items-center">
															<span class="truncate">{{$t('Test')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto">
																<span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.test">
																	<span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.validation">
																		{{ parseInt(semiSupervisedTagMap?.resume?.division?.anomaly?.test) + parseInt(semiSupervisedTagMap?.resume?.division?.anomaly?.validation) }}
																	</span>
																	<span v-else>{{ semiSupervisedTagMap?.resume?.division?.anomaly?.test }}</span>
																</span>
																<span v-else>0</span>
															</span>
														</div>
														<div class="flex items-center">
															<span class="truncate">{{$t('Train')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.train">{{ semiSupervisedTagMap?.resume?.division?.anomaly?.train }}</span><span v-else>0</span></span>
														</div>
														<div class="flex items-center">
															<span class="truncate">{{$t('Mask')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.anomalyMask">{{ semiSupervisedTagMap?.resume?.anomalyMask }}</span><span v-else>0</span></span>
														</div>
														<div class="flex items-center">
															<span class="truncate">{{$t('Use mask for training')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
															<span class="font-medium xl:ml-auto"><input type="checkbox" class="cursor-pointer rounded" v-model="semiSupervisedTagMapUseMask"></span>
														</div>
													</div>
												</div>

												<div class="bg-red-100 h-full px-3">
													<draggable class="list-group h-full pt-5  pb-10" :list="semiSupervisedTagMap.anomaly" group="people" @change="refreshTagMapSemiSupervised">
														<template #item="{ element }">
															<div class="list-group-item bg-red-400 text-white text-center mb-1 cursor-pointer p-2 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">
																<div>{{ element.name }}</div>
																<input type="text" class="w-full py-2 px-3 border-transparent bg-white text-gray-900 mt-2" v-model="element.anomalyLabel" :placeholder="'Anomaly label'" @change="refreshTagMapSemiSupervised" />
															</div>
														</template>
													</draggable>
												</div>

											</div>

											<div class="col-span-12" v-if="Dataset?.data?.type=='MULTILABEL'"><div id="refreshTagMapBtn" class="hidden" @click="refreshTagMapSemiSupervised"></div></div>

										</div>

										<!-- SUMMARY BOX -->
										<div id="trainingSummayBox" class="grid grid-cols-12 gap-0 mb-5 bg-gray-100" style="border: 2px solid #ccc; border-radius: 20px;" v-if="semiSupervisedTagMap?.resume?.total">
											
											<div class="col-span-12">
												<div class="text-xl pt-3">{{ $t('Summary') }}</div>
											</div>

											<div class="col-span-12">
												<div class="grid grid-cols-12 gap-1 py-5 px-2 pt-0 text-gray-600" v-if="semiSupervisedTagMap?.resume">
													<div class="col-span-6 text-center px-5">
														{{ $t('Training') }} 
														<div class="text-2xl text-gray-700">{{  this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.resume?.train) }}</div>
														<div class="text-xs text-left pl-2 mt-1">
															<div class="float-left w-1/2">{{ $t('Normal') }}</div>
															<div class="float-right w-1/2 text-right pr-3">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.normal?.train) }}</div>
															<div class="float-left w-1/2 mt-1">{{ $t('Anomaly') }}</div>
															<div class="float-right w-1/2 text-right pr-3 mt-1">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.anomaly?.train) }}</div>
														</div> 
													</div> 
													<div class="col-span-6 text-center px-5 border-l pl-2">
														{{ $t('Test') }} 
														<div class="text-2xl text-gray-700">{{  this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.resume?.test) }}</div>
														<div class="text-xs text-left pl-2 mt-1">
															<div class="float-left w-1/2">{{ $t('Normal') }}</div>
															<div class="float-right w-1/2 text-right pr-3">{{ this.helper.formatScore((semiSupervisedTagMap?.resume?.division?.normal?.test+semiSupervisedTagMap?.resume?.division?.normal?.validation)) }}</div>
															<div class="float-left w-1/2 mt-1">{{ $t('Anomaly') }}</div>
															<div class="float-right w-1/2 text-right pr-3 mt-1">{{ this.helper.formatScore((semiSupervisedTagMap?.resume?.division?.anomaly?.test+semiSupervisedTagMap?.resume?.division?.anomaly?.validation)) }}</div>
														</div> 
													</div> 
												</div>
											</div>

											<div class="col-span-12" v-if="!Dataset.trainSemiSupervisedError && !semiSupervisedTagMap?.resume?.errors?.length">
												<div class="text-green-600 text-center px-10">{{ $t('Now the dataset is ready to be trained with') }} <span class="text-xl px-1">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.total) }}</span> {{ $t('labeled images') }}.</div>
												<div class="py-3" v-if="semiSupervisedOpt.uploadImageType">
													<select class="form-select bg-white cursor-pointer w-52 mt-0" v-model="semiSupervisedOpt.uploadImageType">
														<option value="image/png">{{$t('PNG')}} ({{$t('Recommended')}})</option>
														<option value="image/jpeg">{{$t('JPG')}}</option>
													</select>
												</div>
												<button type="button" class="btn bg-green-500 text-white w-44 mr-1 zoom-in py-2 mb-5 text-lg" @click="trainSemiSupervised()">{{ $t('Continue') }}</button>
											</div>

										</div>

									</div>

								</div>

								<div v-if="Dataset && Dataset.trainSemiSupervisedAction">

									<div v-if="Dataset && Dataset.trainSemiSupervisedSuccess">
										<div class="text-lg font-medium text-green-600 mt-5">{{ Dataset.trainSemiSupervisedSuccess }}</div>
										<div class="text-base">{{ Dataset.trainSemiSupervisedAction }}</div>
										<div class="px-5 pb-5 text-center mt-5" v-if="Dataset"><button type="button" data-dismiss="modal" class="btn text-white bg-green-500 w-32" @click="refreshBtn()">{{ $t('Ok') }}</button></div>
									</div>

									<div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 pt-0" v-if="Dataset && !Dataset.trainSemiSupervisedSuccess">
										<div class="w-full text-center pt-8" style="background-color: #f1f2f1">
											<div class="text-lg">{{ Dataset.trainSemiSupervisedAction }}</div>
											<div class="mt-3">{{$t("This process can take several minutes")}}</div>
											<span class="text-base text-red-600 font-bold">{{$t("Please do not close your browser")}}</span>
										</div>
										<div class="w-full text-center py-24" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/loading.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`"></div>
									</div>

								</div>

							</div>

						</div>
					</div>

				</div>
			</div>
		</div>

		<!-- Vision Platform Model --> 
		<div id="model-vision-platform-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static" :style="trainVertex.training ? 'z-index: 99999;position: relative;' : ''">
			<div class="modal-dialog modal-xl" style="min-width: 550px;">
				<div class="modal-content p-5">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer p-5" data-dismiss="modal"><XIcon class="w-8 h-8" v-if="trainVertex.setup || trainVertex.training" /></label>
					<div class="modal-body py-2 px-0 text-center" v-if="Dataset">
						<div class="grid chat grid-cols-12">

							<div class="col-span-12" v-if="Layout.loading">
								<div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
								<div class="intro-y flex flex-col justify-center items-center mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
							</div>

							<div class="col-span-12" v-else>
								<div class="grid chat grid-cols-12 px-10">

									<div class="col-span-12 mt-2">

										<!-- SETUP -->
										<div class="grid chat grid-cols-12" v-if="trainVertex.setup">
											
											<div class="col-span-12">
												<div class="text-center">
													<div class="text-center"><div class="text-2xl font-medium">{{ $t('Vision Platform') }}</div></div>
													<div class="text-gray-700">{{$t('Use this dataset and annotation set to train a new model')}}</div> 
												</div>
											</div>
				
											<!-- TRAINING REQUIREMENTS -->
											<div class="col-span-12 mt-2" v-if="Dataset?.validateToTrain && !Dataset?.validateToTrain?.validated && trainVertex?.tagMap && supervisedTagMap.selected && Object.keys(supervisedTagMap.selected).length">
												<div class="text-left w-full p-5 rounded border my-3">
													<div class="mr-5 mb-3">{{$t('Now the dataset cannot be training, check requirements and warnings for train')}}.</div>
													<div class="grid chat grid-cols-12 mt-5 text-xs">
														<div class="col-span-7" v-if="(Dataset.validateToTrain.errors && Dataset.validateToTrain.errors.length)">
															<div class="pr-3 text-red-400 text-sm mb-5" v-if="Dataset.validateToTrain.errors.length"><AlertCircleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.errors.length}} {{$t('Requirements')}}</div>
															<div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.errors" :key="k"> {{k}}</div>  
														</div>
														<div class="col-span-5" v-if="(Dataset.validateToTrain.warnings && Dataset.validateToTrain.warnings.length)" >
															<div v-if="Dataset.validateToTrain.warnings.length" class="text-sm mb-5"><AlertTriangleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.warnings.length}} {{$t('Warnings')}}</div>
															<div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.warnings" :key="k">{{k}}</div> 
														</div>
													</div>
													<div class="mt-5 text-xs"><span class="text-blue-500 cursor-pointer" @click="manageTags()">Manage tags</span> | <span class="text-blue-500 cursor-pointer" @click="linkToBrowser()">Labeling</span></div>
												</div>
											</div>
					
											<!-- MODEL NAME -->
											<div class="col-span-12 mt-2">
												<div class="text-left">
													<div class="mb-3 ">{{ $t('Model name') }} *</div>
													<input 
														id="train-name" 
														type="text" 
														:maxlength="32" 
														v-model="trainVertex.modelName" 
														@input="removeHyphens()" 
														:class="{'form-control py-3 px-4 border-transparent bg-gray-200 pr-10': true, 'border-red-500': !enableCreation}" 
														required   
													/>
													<div 
														v-if="!enableCreation" 
														class="text-xs pt-2 text-red-500 text-center">
														{{ $t('This model name is already in use. Please choose a different name.') }}
													</div>
													<div class="text-xs pt-2 text-center">{{ 
														$t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.
													</div>
												</div>
											</div>
					
											<!-- TAG SELECTION -->
											<div class="col-span-12 mt-5">
												<div class="text-left">{{ $t('Select the tags you want to include in the training') }} *</div>

												<div class="grid grid-cols-12 gap-1" v-if="supervisedTagMap">
													
													<!-- UNSELECTED TAGS -->
													<div class="col-span-6 overflow-hidden">
														<div class="bg-gray-300 h-full px-3">
															<draggable class="list-group bg-gray-300 h-full pt-5 mt-5 rounded pb-3" :list="supervisedTagMap.labels" group="people" @change="refreshTagMapSupervised" v-if="Object.keys(Dataset?.tags).length">
																<template #item="{ element }"><div class="list-group-item bg-theme-2 text-white text-center mb-1 cursor-pointer p-2 mx-2 text-xs rounded truncate" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div></template>
															</draggable>
															<div v-else class="pt-5 mb-5">{{ $t('Dataset does not contain tags') }} <div class="text-blue-500 text-xs text-center cursor-pointer" @click="manageTags()">{{ $t('create tags') }}</div></div>
														</div>
													</div>

													<!-- SELECTED TAGS -->
													<div class="col-span-6 mt-5 overflow-hidden" >
														<div class="bg-green-100 h-full px-3">
															<draggable class="list-group h-full pt-5 pb-10" :list="supervisedTagMap.selected" group="people" >
																<template #item="{ element }">
																	<div class="list-group-item bg-green-500 text-white text-center mb-1 cursor-pointer p-2 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">
																		<div class="font-medium">{{ element.name }}</div>
																		<input type="text" class="w-full py-2 px-3 border-transparent bg-white text-gray-900 mt-2" v-model="element.vertexLabel" :placeholder="'Label'" @change="refreshTagMapSupervised" />
																	</div>
																</template>
															</draggable>
														</div>
													</div>

												</div>

											</div>

											<div class="col-span-12 mt-5" v-if="supervisedTagMap?.selected && Object.keys(supervisedTagMap?.selected).length && trainVertex?.tagMap">
												<div class="grid chat grid-cols-12">

													<!-- 
													<div class="col-span-12">
														<div class="mb-3 ">{{ $t('The train budget of creating this model') }} *</div>
														<div v-if="Dataset.data.type!='imageObjectDetection'" class="text-xs mb-5">
															<span>{{$t('Recommended')}}</span> <span class="py-1 rounded-full text-xs bg-gray-400 text-gray-600 cursor-pointer px-5 ml-2">{{ trainVertex.modelTrainBudgetObjMin+" "+$t('node hours')}}</span>
														</div> 
														<div class="relative text-gray-700 dark:text-gray-300 px-5">
															<Slider v-model="trainVertex.modelTrainBudget" 
																	:min="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMin : trainVertex.modelTrainBudgetMin" 
																	:max="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMax : trainVertex.modelTrainBudgetMax" 
																	:step="trainVertex.modelTrainBudgetStep" 
																	:merge="trainVertex.modelTrainBudgetStep" />
															<div class="text-xs pt-2 mb-2">
																{{$t('The budget for the creation of this model, expressed in milli node hours, that is, the value of 1000 in this field means 1 node hour')}}.
																<div class="font-bold">{{$t('24.000 represents one day in wall time')}}.</div>
															</div>
														</div>
													</div> 
													-->

													<div class="col-span-12 mt-3">
														<div class="text-left">
															<div class="text-left">

																<div class="pb-5 text-center">
																	<input v-model="trainVertex.method" class="form-check-input mr-1" type="radio" value="labeled" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='labeled' ? 'text-blue-500' : ''">{{ $t('Current labeled') }}</span>
																	<input v-model="trainVertex.method" class="form-check-input ml-10 mr-1" type="radio" value="auto" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='auto' ? 'text-blue-500' : ''">{{ $t('Auto assignment') }}</span> 
																</div>

																<div v-if="trainVertex.method=='labeled'">
																	<div class="grid chat grid-cols-12 mb-10">
																		<div class="col-span-5">
																			<div v-if="Dataset.vertexDivision" class="p-5 pt-0">
																				<div class="relative">
																					<ReportDonutChart :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
																						labels: [ "Train", "Test", "validation", "Predetermined" ],
																						datasets: [ {
																							data: [ 
																								Dataset.vertexDivision?.train, Dataset.vertexDivision?.test, Dataset.vertexDivision?.validation, Dataset.vertexDivision?.predetermined
																							],
																							backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
																						}
																						]
																					}' />
																					<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																						<div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
																						<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="col-span-7">
																			<div class="text-left text-xs mx-12">
																				<div class="mb-2">{{ $t('Data division') }} *</div>
																				{{ $t('Your datasets will be manually split into training, validation, and test sets with the following ratios') }}: 
																			</div>
																			<div class="mx-auto w-10/12 xxl:w-2/3 mt-5">
																				<div class="flex items-center">
																					<div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
																					<span class="truncate">{{$t('Train')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.train  }} ({{ ((Dataset.vertexDivision?.train*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
																					<span class="truncate">{{$t('Test')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.test }} ({{ ((Dataset.vertexDivision?.test*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
																					<span class="truncate">{{$t('Validation')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.validation }} ({{ ((Dataset.vertexDivision?.validation*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																				<div class="flex items-center mt-2" v-if="Dataset.vertexDivision">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #b7b7b7"></div>
																					<span class="truncate">{{$t('Predetermined')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ Dataset.vertexDivision?.predetermined }} ({{ ((Dataset.vertexDivision?.predetermined*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
																					</span>
																				</div>
																			</div>
																			<div class="text-center text-xs mt-5">
																				{{ $t('You can configure the set by image through') }} <span class="text-blue-600 cursor pointer" @click="linkToBrowser()">{{ $t('data labeling') }}</span>
																			</div>
																		</div>
																	</div>
																</div>

																<div v-if="trainVertex.method=='auto'">
																	<div class="grid chat grid-cols-12">

																		<div class="col-span-5 mb-10">
																			<div v-if="Dataset.vertexDivision" class="p-5 pt-0">
																				<div class="relative">
																					<ReportDonutChart :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
																							labels: [ "Train", "Test", "validation" ],
																							datasets: [{
																								data: [ trainVertex.train, trainVertex.test, trainVertex.validation],
																								backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
																							}]
																						}'
																					/>
																					<div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
																						<div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
																						<div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
																					</div>
																				</div>
																			</div>
																			<div class="mx-auto w-10/12 xxl:w-2/3">
																				<div class="flex items-center">
																					<div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
																					<span class="truncate">{{$t('Train')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.train*Dataset.vertexDivision?.total)/100).toFixed(0)}}
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
																					<span class="truncate">{{$t('Test')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.test*Dataset.vertexDivision?.total)/100).toFixed(0)}}
																					</span>
																				</div>
																				<div class="flex items-center mt-2">
																					<div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
																					<span class="truncate">{{$t('Validation')}}</span>
																					<div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
																					<span class="font-medium xl:ml-auto">
																						{{ ((trainVertex.validation*Dataset.vertexDivision?.total)/100).toFixed(0)}} 
																					</span>
																				</div>
																			</div>
																		</div> 

																		<div class="col-span-7">
																			<div class="text-left text-xs mx-12">
																				<div class="mb-2">{{ $t('Data division') }} *</div> 
																				{{ $t('Your dataset will be automatically randomized and split into training, validation, and test sets with the following ratios') }}:
																			</div>
																			<div class="text-left text-xs mx-12 bg-red-200 text-gray-800 py-3 mt-5 pl-5" v-if="autoDivisionAlert">
																				<AlertTriangleIcon class="w-4 h-4 mr-1" /> 
																				{{$t('The division of data must add up to 100%')}}. 
																			</div>
																			<div class="grid chat grid-cols-12 mx-20 my-2 mt-8">
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Train') }} 
																						<span v-if="trainVertex.train" class="ml-auto float-right text-xs">{{ trainVertex.train }}%</span>
																					</div>
																					<Slider 
																						v-model="trainVertex.train"
																						:min="50" 
																						:max="100" 
																						:step="5" 
																						:tooltips="false"
																					/>
																				</div>
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Test') }}  
																						<span v-if="trainVertex.test" class="ml-auto float-right text-xs">{{ trainVertex.test }}%</span>
																					</div>
																					<Slider 
																						v-model="trainVertex.test" 
																						:min="0" 
																						:max="50" 
																						:step="5" 
																						:tooltips="false"
																					/>
																				</div>
																				<div class="col-span-12 text-left mb-4">
																					<div class="mb-2">{{ $t('Validation') }} 
																						<span v-if="trainVertex.validation" class="ml-auto float-right text-xs">{{ trainVertex.validation }}%</span>
																					</div>
																					<Slider 
																						v-model="trainVertex.validation" 
																						:min="0" 
																						:max="50" 
																						:step="5" 
																						:tooltips="false"
																					/>
																				</div>
																			</div>
																		</div>

																	</div>
																</div>

															</div>
														</div> 
													</div>
													
													<div class="col-span-12 text-center mt-5" v-if="Dataset?.validateToTrain?.validated && !autoDivisionAlert">
														<button 
															type="button" 
															class="btn bg-theme-15 w-44 mr-1 zoom-in py-4"
															@click="trainVisionPlatform()" 
															:disabled="!enableCreation"
															:class="{'opacity-50 cursor-not-allowed': !enableCreation}">
															{{ $t('Train') }}
														</button>
													</div>

												</div>
											</div>

										</div>

										<!-- OTHER -->
										<div v-else>
											<div class="col-span-12">

												<div v-if="trainVertex.error">
													<div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
														<div>{{  $t('An error has occurred that prevents training from starting')  }}.</div>
														<div class="py-3 text-red-600">{{ trainVertex.error }}</div>
														<button type="button" class="btn bg-theme-15 text-gray-800 w-32 mr-l zoom-in py-2 mt-5" @click="trainVertex.setup=true; trainVertex.error=false">{{ $t('Back') }}</button>
													</div>
												</div>
												
												<div class="grid chat grid-cols-12 gap-0">
													<div class="col-span-12">
														<div class="intro-y flex flex-col justify-center items-center  text-center my-20 mx-10">
															<LoadingIcon icon="oval" class="w-20 h-20 mb-5" /><br />
															{{$t('Synchronizing and creating the model')}}.<br /> 
															{{$t('This process can take several minutes')}}.<br /> 
															<span class="text-theme-5 text-base">{{$t("Don't close your browser")}}</span>
														</div>
													</div>
												</div>

											</div>
										</div>

										<!-- TRAINING -->
										<div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="trainVertex.training" 
											:style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
											<div class="text-center">{{ $t('Model is training, please wait ...') }}</div> <div @click="trainVertex =false; refreshBtn()" class="ml-5 bg-green-500 text-white py-2 px-5 rounded cursor-pointer">{{ $t('Continue') }}</div>
										</div>

									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { defineComponent, onMounted, ref,  watch  } from "vue";
import { useRouter }		from "vue-router";
import { helper as $h } 	from "@/utils/helper";
import Slider 				from "@vueform/slider";
import cash 				from "cash-dom";
import draggable 			from "vuedraggable";
import ClipboardJS			from "clipboard";

import ReportDonutChart		from "@/components/visuals/report-donut-chart/Main.vue";

import * as rosepetalModel	from "rosepetal-model";

let $helper 	= rosepetalModel.default.helper;
let $dataset	= rosepetalModel.default.dataset;
let $model		= rosepetalModel.default.model;
let $other 		= rosepetalModel.default.other;
let $aws		= rosepetalModel.default.aws;
let $vision		= rosepetalModel.default.vision;
let $openai		= rosepetalModel.default.openai;
  
export default defineComponent({
	props: {  
		dataset: {
			type: Object,
			required: true
		}
	},
	
	components: {
		Slider,
		ReportDonutChart,
		draggable
	},

	computed: {
		autoDivisionAlert() { 
			if (this.trainVertex) return this.trainVertex.test + this.trainVertex.train + this.trainVertex.validation != 100 ? true : false;
			return false;
		},

		enableCreation() {
			if (!this.localModelsNames.includes(this.trainVertex.modelName)) return true;
			else return false;
		}
	},

	created () {
		this.helper = $helper;
	},

	setup(props) { 
		const router             				= useRouter();
		const Layout             				= ref({ loading: true, wizard: { curStep: 1 }, modalImport: false });
		const Dataset            				= ref({ data: {}, tags: {}, localModels: [], selectedModel: false });
		const currentDataset					= ref();
		const currentModel						= ref({});
		const DatasetTagsObj     				= ref({});
		const localModelsMenu    				= ref([]);
		const localModelsNames   				= ref([]);

		const supervisedTagMap                	= ref({});

		const semiSupervisedTagMap            	= ref({});
		const semiSupervisedTagMapUseMask     	= ref(true);
		const semiSupervisedEvaluationObjects	= ref([]);

		const visionPlatformTagMap            	= ref({});
		const visionPlatform = ref({
			method:		'auto',
			modelName: 	'model_undefined',
			train: 		80,
			test: 		10,
			validation:	10,
			setup: 		true
		})

		const clipboard                       	= ref();
		const trainVertexInCourse             	= ref(false);

		const semiSupervisedOpt = ref({ uploadImageType: 'image/png' });

		const trainVertex = ref({  
			method: 				"auto",
			modelName: 				'model_undefined',
			train: 					80, 
			test: 					10,
			validation: 			10,
			setup: 					true, 
			modelTrainBudget: 		1000, 
			modelTrainBudgetMin: 	1000,
			modelTrainBudgetObjMin: 1000,
			modelTrainBudgetStep: 	1000,
			modelTrainBudgetMax: 	24000, 
			modelTrainBudgetObjMax:	30000,
		});
		
		const Train = ref({ 
			csv: {
				method: 				"auto",
				test: 					10,
				validation: 			10,
				train: 					80,
				modelTrainBudget: 		3000, 
				modelTrainBudgetMin: 	1000,
				modelTrainBudgetObjMin: 1000,
				modelTrainBudgetStep: 	1000,
				modelTrainBudgetMax: 	24000, 
				modelTrainBudgetObjMax: 30000,
				modelName: 				'model_undefined',
				modelAnnotationSet: 	false,
				minTagImages: 			10, 
				minTagCounter: 			false, 
				gs: 					false, 
				error: 					false, 
				loading: 				false, 
				lastImport: 			{} 
			},
			train: {
				loading: 				false, 
				lastTraining: 			{},
				annotationSetList: 		{}
			}
		});
  
		const modal       = (m) => { cash("#" + m).modal("show"); cash(".main").removeClass("overflow-y-hidden") };
		const modalClose  = (m) => { cash("#" + m).modal("hide"); };
		const modalRemove = (m) => { cash("#" + m).remove(); };
  
		const getDataset = async () => {
			Layout.value.loading        = true;

			Dataset.value.data			= props.dataset;
			Dataset.value.tags      	= await $dataset.getTags(props.dataset.id, Dataset.value.data.type == "MULTILABEL" ? true : false);
			Dataset.value.localModels   = props.dataset?.models ? props.dataset.models.models : false;
			clipboard.value             = new ClipboardJS(".copyButton");

			console.log("Local models", Dataset.value.localModels);

			Layout.value.loading        = false;
		}
  
		const exportModelToBucket = async () => { 
			Layout.value.loading = true;
			if (currentModel.value?.db?.id) {
				await $model.export(currentModel.value.db.id, { updateModel: true }).then(async response =>  {
					if (response?.savedModel) currentModel.value.db.savedModel = response?.savedModel;
				})
			}
			Layout.value.loading = false
		}

		const deleteModel = async () => {
			cash("#model-delete-box").modal("hide");
			if (Dataset.value.selectedModel) await $model.update(Dataset.value.selectedModel, { deleted: Boolean(true) });
		}


		//------- SUPERVISED (VERTEX / GOOGLE) -------

		const modalTrainSupervised = async () => {
			modal("model-supervised-box");

			Layout.value.loading          = true;
			supervisedTagMap.value        =  { "labels":  [], "selected":  [], "resume": {} };

			if (Object.keys(Dataset.value.tags).length) {
				for (const dstag of Object.values(Dataset.value.tags)) {
					if(!dstag.unclassified) {
						supervisedTagMap.value.labels.push({ 
							name:     dstag.name, 
							id:       dstag.id, 
							counter:  dstag.imageCounter, 
							color:    dstag.color, 
							division: await $dataset.getDivisionStats(props.dataset, false, dstag.id), 
							mask:     await $dataset.getMaskCounter(currentDataset.value, dstag.id), 
						})
					}
				}
			}

			trainVertex.value.modelName   = Dataset.value.data.name.toString().replace(/-/g, '_');
			Layout.value.loading          = false;
		}
          
		const initTrainSupervised = async () => {
			console.log("Starting supervised training...");

			if (!trainVertexInCourse.value) {
				await $dataset.getDatasetAnnotationSetList(Dataset.value.data.automl).then(async response => {
					if (response?.status && response?.status == "success") {
						trainVertex.value.annotationSetId = response?.last;

						await $model.create(currentDataset.value, trainVertex.value.modelName, { trainBudget: trainVertex.value.modelTrainBudget, annotationSetId:  trainVertex.value.annotationSetId, tagMap: JSON.stringify(trainVertex.value.tagMap) }).then(async response =>  {
							if (response?.status && response?.status == "success") {
								let trainSet = { 
									datasetID:       Dataset.value.data.automl, 
									type:            Dataset.value.data.type, 
									modelName:       trainVertex.value.modelName, 
									trainBudget:     trainVertex.value.modelTrainBudget,
									annotationSetId: trainVertex.value.annotationSetId,
									tagMap:          trainVertex.value.tagMap ? trainVertex.value.tagMap : false,
									divisionType:    trainVertex.value.method,
									division:        trainVertex.value.train + "," + trainVertex.value.validation + "," + trainVertex.value.test
								}

								await $model.train(trainSet).then(async responseTrain =>  {
									if (responseTrain.status == 'error') trainVertex.value.error = responseTrain.error;
									else { 
										trainVertex.value.trainOperation  = responseTrain;
										if (trainVertex.value.trainOperation.operationId) {
											await $model.update(response.modelId, { trainOperation: { operationId: trainVertex.value.trainOperation.operationId, operationName: trainVertex.value.trainOperation.operationName, } })
											trainVertex.value.training = true;
										}
									}
								})
							
							} else trainVertex.value.error = response?.error;
						});
					} else trainVertex.value.error = response?.error
				});
			}
		}
  
		const trainSupervised = async (onlyUpload = false) => {
			trainVertex.value.setup = false;

			let intervId = setInterval(async () => {
				Dataset.value.vertex 			= await $model.getVertex({ datasetID: props.dataset.id });
				trainVertex.value.vertexCount 	= Dataset.value.vertex?.response[0].exampleCount;

				if (trainVertex.value.importOperation) {
					if (onlyUpload) {
						clearInterval(intervId);
						trainVertex.value = { onlyUpload: true };
					} else {
						await $other.httpsCallable('api/model/operation/status/' + trainVertex.value.storage?.operationName.replace(/\//g, "--")).then(async response => {
							if (response.data) trainVertex.value.importOperation = response.data;
							if (response.data.done) {
								clearInterval(intervId);
								await initTrainSupervised();
								trainVertexInCourse.value = true;
							}
						})
					}
				}

				if (Dataset.value.vertex?.status !== 'error') {
					if (Dataset.value.vertex?.response[0]) Dataset.value.vertex = Dataset.value.vertex?.response[0]
					if (Dataset.value.vertex?.dataItems[0]) {
						Dataset.value.vertex.division = { training: 0, validation: 0, test: 0 }
						for (const item of Dataset.value.vertex?.dataItems[0]) {
							if (item?.labels["aiplatform.googleapis.com/ml_use"] == 'training') 	Dataset.value.vertex.division.training++;
							if (item?.labels["aiplatform.googleapis.com/ml_use"] == 'validation') 	Dataset.value.vertex.division.validation++;
							if (item?.labels["aiplatform.googleapis.com/ml_use"] == 'test') 		Dataset.value.vertex.division.test++;
						}
					}
				} else { console.log("Error vertex:", Dataset.value.vertex?.error) }

				if (Dataset.value.vertex?.status === 'error' || trainVertex.value?.trainOperation) clearInterval(intervId)
			}, 120000);

			let uploadStorageOptions = { tagMap: trainVertex.value.tagMap ? trainVertex.value.tagMap : false };

			if (trainVertex.value.method == "auto") {
				uploadStorageOptions.test 		= trainVertex.value.test;
				uploadStorageOptions.validation = trainVertex.value.validation;
			}

			await $model.uploadStorage(props.dataset, uploadStorageOptions).then(async response => {
				console.log("Upload to storage completed:", response);

				if (response.status !== 'error') {
					trainVertex.value.storage = response;

					if (trainVertex.value.storage?.status === 'error') {
						trainVertex.value.error = trainVertex.value.storage?.error;
					} else {
						const operationName = trainVertex.value.storage?.operationName.replace(/\//g, "--");
						const operationStatusUrl = `api/model/operation/status/${operationName}`;
						
						await $other.httpsCallable(operationStatusUrl).then(async response => {
							if (response.data) trainVertex.value.importOperation = response.data;
						});
					}
				} else {
					trainVertex.value.error = response.error;
				}
			})
		}

		const refreshTagMapSupervised = async () => {
			trainVertex.value.tagMap = { 
				total: 	0,
				tags: 	{},
				errors: [],
				resume: { division: { total: 0, test: 0, train: 0, validation: 0, predetermined: 0 } }
			}

			if (supervisedTagMap.value?.selected && Object.keys(supervisedTagMap.value.selected).length) {
				supervisedTagMap.value.selected.forEach(async (tag) => {
					const _tagName  = tag.vertexLabel ? tag.vertexLabel : tag.id;

					if (!trainVertex.value.tagMap.tags[_tagName]) {
						trainVertex.value.tagMap.tags[_tagName] = { total: 0, tags: { count: 0 } };
					}

					trainVertex.value.tagMap.tags[_tagName]["tags"][tag.id] = { count: tag.counter };
					trainVertex.value.tagMap.tags[_tagName]["tags"]["count"]++;

					if (Dataset.value.data.type == 'MULTICLASS') {
						trainVertex.value.tagMap.tags[_tagName]["tags"][tag.id]["count"] = tag.counter;

						trainVertex.value.tagMap.total += tag.counter;
						trainVertex.value.tagMap.tags[_tagName].total += tag.counter;

						Object.keys(tag.division).forEach((div) => {
							trainVertex.value.tagMap.resume.division[div] += tag.division[div];
						})
					}
				})

				if (Dataset.value.data.type === 'imageObjectDetection' || Dataset.value.data.type === 'MULTILABEL') { 
					const divisions = await $dataset.getDivisionStats(Dataset.value.data, false, false, trainVertex.value.tagMap);

					trainVertex.value.tagMap.total = divisions.total;

					Object.keys(divisions).forEach((div) => {
						trainVertex.value.tagMap.resume.division[div] += divisions[div];
					})
				}
			}

			Dataset.value.vertexDivision = trainVertex.value.tagMap.resume.division;
			
			let validationOpt = { noDivision: trainVertex.value.method == 'auto' ? true : false, tagMap: trainVertex.value.tagMap };
			if (Dataset.value.data.type == 'imageObjectDetection') validationOpt.labeledImages = true;
			
			Dataset.value.validateToTrain = await $model.validateToTrain(Dataset.value, validationOpt);
		}


		//------- SEMI-SUPERVISED (AWS) -------
  
		const modalTrainSemiSupervised = async () => {
			modal("model-semi-supervised-box");

			Dataset.value.trainSemiSupervisedAction = Dataset.value.trainSemiSupervisedError = Dataset.value.trainSemiSupervisedSuccess = false;
			semiSupervisedTagMap.value = { "labels": [], "normal": [], "anomaly": [], "resume": {} };

			if (Object.keys(Dataset.value.tags).length) {
				const tagPromises = Object.values(Dataset.value.tags).map(async (dstag) => {
					if (!dstag.unclassified) {
						const [division, mask] = await Promise.all([
							$dataset.getDivisionStats(props.dataset, 'labeled', dstag.id),
							$dataset.getMaskCounter(currentDataset.value, dstag.id)
						]);

						const tagItem = {
							name: dstag.name,
							id: dstag.id,
							counter: dstag.imageCounter,
							color: dstag.color,
							division: division,
							mask: mask
						};

						if (tagItem?.division?.total) {
							tagItem.counter = tagItem.division.total;
						}

						return tagItem;
					}

					return null;
				});

				const tagItems = await Promise.all(tagPromises);
				semiSupervisedTagMap.value.labels.push(...tagItems.filter(tagItem => tagItem !== null));
			}

			if (Dataset.value.data.type == 'MULTILABEL') {
				let normalTag = await $dataset.getTag(Dataset.value.data.id, 'OK');

				semiSupervisedTagMap.value.normal.push({ 
					name: 		normalTag.name, 
					id: 		normalTag.id, 
					counter: 	normalTag.imageCounter, 
					color: 		normalTag.color, 
					division:	await $dataset.getDivisionStats(props.dataset, false, normalTag.id), 
					mask: 		await $dataset.getMaskCounter(currentDataset.value, normalTag.id)
				});

				let anomalyTag = await $dataset.getTag(Dataset.value.data.id, '0');

				semiSupervisedTagMap.value.anomaly.push({ 
					name: 		anomalyTag.name, 
					id: 		anomalyTag.id, 
					counter: 	anomalyTag.imageCounter, 
					color: 		anomalyTag.color, 
					division: 	await $dataset.getDivisionStats(props.dataset, false, anomalyTag.id), 
					mask: 		await $dataset.getMaskCounter(currentDataset.value, anomalyTag.id)
				});

				if (document.getElementById("refreshTagMapBtn")) {console.log("HOLA"); document.getElementById("refreshTagMapBtn").click();}
			}
		}
  
		const trainSemiSupervised = async () => {
			Dataset.value.trainSemiSupervisedError = false;

			if (semiSupervisedTagMap.value) {
				const tagMap = {
					normal: 		[],
					anomaly: 		[],
					anomalyLabel: 	{},
					useMask: 		semiSupervisedTagMapUseMask.value || false
				};

				const { normal, anomaly } = semiSupervisedTagMap.value;

				tagMap.normal 	= normal.map(tag => tag.name.toString().toUpperCase());
				tagMap.anomaly	= anomaly.map(tag => {
					const tagName = tag.name.toString().toUpperCase();
					if (tag.anomalyLabel) {
						if (!tagMap.anomalyLabel[tag.anomalyLabel]) {
							tagMap.anomalyLabel[tag.anomalyLabel] = [];
						}
						tagMap.anomalyLabel[tag.anomalyLabel].push(tagName);
					}
					return tagName;
				});

				semiSupervisedTagMap.value.tagMap = tagMap;
			}

			Dataset.value.trainSemiSupervisedAction = "Step 1 of 2 - Uploading images, Please wait...";

			await $aws.create(props.dataset, semiSupervisedTagMap.value.tagMap, semiSupervisedOpt.value.uploadImageType).then(async response =>  {
				if (response?.status && response?.status == "success") {
					Dataset.value.trainSemiSupervisedAction = response?.projectName;
					let projectName = response?.projectName;

					console.log("---> AWS Created successfully", projectName);
					Dataset.value.trainSemiSupervisedAction = "Step 2 of 2 - Starting training, Please wait...";
		
					setTimeout( async () => { 
						await $aws.createModel(projectName).then(async createNewModel =>  {
							if (createNewModel?.status && createNewModel?.status == "success") {
								console.log("---> AWS Model Created successfully");

								let modelName = 'model';
								let modelData = { aws: projectName, tagMap: JSON.stringify(semiSupervisedTagMap.value.tagMap) };

								if (createNewModel?.response?.ModelMetadata) {
									modelData.ModelArn     = createNewModel.response.ModelMetadata.ModelArn;
									modelData.ModelVersion = createNewModel.response.ModelMetadata.ModelVersion;
									if (modelData.ModelVersion) modelName += '-' + modelData.ModelVersion;
								}

								await $model.create(currentDataset.value, modelName, modelData).then(async response =>  {
									if (response?.status && response?.status == "success") {
										Dataset.value.trainSemiSupervisedAction  = "When finished you will be able to see the evaluation of the new model from this same section.";
										Dataset.value.trainSemiSupervisedSuccess = "The training has started successfully";
									} else {
										Dataset.value.trainSemiSupervisedAction = false;
										Dataset.value.trainSemiSupervisedError  = response?.error;
									}
								});
							} else {
								Dataset.value.trainSemiSupervisedAction = false;
								Dataset.value.trainSemiSupervisedError  = createNewModel?.error;
							}
						});
					}, 30000);
				} else {
					Dataset.value.trainSemiSupervisedAction = false;
					Dataset.value.trainSemiSupervisedError  = response?.error;
				}
			});
		}
		
		
		//------- VISION PLATFORM -------

		const modalTrainVisionPlatform = async () => {
			modal("model-vision-platform-box");

			Layout.value.loading	= true;
			supervisedTagMap.value	= { "labels":  [], "selected":  [], "resume": {} };

			if (Object.keys(Dataset.value.tags).length) {
				for (const tag of Object.values(Dataset.value.tags)) {
					if (!tag.unclassified) {
						const tagObj = {
							name:     tag.name, 
							id:       tag.id, 
							counter:  tag.imageCounter, 
							color:    tag.color, 
							division: await $dataset.getDivisionStats(props.dataset, false, tag.id), 
							mask:     await $dataset.getMaskCounter(currentDataset.value, tag.id), 
						}

						supervisedTagMap.value.selected.push(tagObj);
					}
				}
			}

			console.log("Selected tags:", supervisedTagMap.value.selected);

			refreshTagMapSupervised();

			trainVertex.value.modelName   = Dataset.value.data.name.toString().replace(/-/g, '_').toLowerCase();
			Layout.value.loading          = false;
		}

		const trainVisionPlatform = async () => {
			trainVertex.value.setup = false;

			const opt = {
				dataset:	Dataset.value.data,
				modelName:	trainVertex.value.modelName,
				tagMap:		trainVertex.value.tagMap ? trainVertex.value.tagMap : false,
				dataDivision: {
					train:		trainVertex.value.train,
					test:		trainVertex.value.test,
					validation:	trainVertex.value.validation
				}
			}

			const resp = await $vision.manageRequest(opt);
			if (resp.status === 'success') trainVertex.value.training = true;
		}


		const linkToBrowser = () => { 
			cash(".modal").modal("hide"); 
			Layout.value.modalImport = false; 
			router.push({ name: 'side-menu-datasets', params: { datasetID: props.dataset.id } });  
		}

		onMounted(async () => {
			console.log("------listModels mounted------")
			currentModel.value   = false;
			currentDataset.value = props.dataset.id;

			await getDataset();

			localModelsMenu.value	= Dataset.value.localModels;
			localModelsNames.value 	= Dataset.value.localModels.map((model) => model.id);

			//await $dataset.updateAutomlId(props.dataset.id);
		});
  
		watch(() => currentModel.value.packageJob, async () => { 
			if (currentModel.value.packageJob) 
				currentModel.value.packageJobData = await $aws.getModelPackagingJob(currentModel.value.projectName, currentModel.value.packageJob)  
		});
  
		watch(() => currentModel.value.id, async () => { 
			if (currentModel.value.id) {
				for (let ds in Dataset.value.localModels) { 
					if (currentModel.value.id == Dataset.value.localModels[ds].id) {
						Layout.value.loading = true;

						/* Google Model */
						if (Dataset.value.localModels[ds].automl) {
							currentModel.value = {}

							await $model.getVertex({ modelID: Dataset.value.localModels[ds].automl }).then(async responseModel =>  {
								if (responseModel?.status=="success") {
									currentModel.value  = responseModel.data;
									let vertexDataset  = await $model.getVertex({ datasetID: currentDataset.value });

									if (vertexDataset?.status == "success") {
										currentModel.value.vertex = vertexDataset.response[0]
										if (currentModel.value?.vertex?.dataItems && currentModel.value?.vertex?.dataItems[0]?.length) {
											currentModel.value.vertex.dataDivision = { test: 0, training: 0, validation: 0 }
											for (let i=0; i<currentModel.value.vertex.dataItems[0].length; i++) { 
												if (currentModel.value.vertex.dataItems[0][i].labels["aiplatform.googleapis.com/ml_use"])
													currentModel.value.vertex.dataDivision[currentModel.value.vertex.dataItems[0][i].labels["aiplatform.googleapis.com/ml_use"].toLowerCase()]++ 
											}
										}
									} else { currentModel.value.errorLoad = ' 1- ' + vertexDataset?.error; }
								} else { currentModel.value.errorLoad = ' 2- '+responseModel?.error; }
							})

							currentModel.value.db                 	= Dataset.value.localModels[ds];
							Layout.value.loading					= false;
							currentModel.value.vertexEvaluation   	= await $model.renderEvaluations(currentModel.value.db.id, { theme: "light", onlyMetrics: true, titles: false });
							clipboard.value                   		= new ClipboardJS(".copyButton");
							
							/* 
							let chatUser		=  "I have a dataset with these characteristics:";
							let datasetResume	=  await $dataset.generateResume(props.dataset.id);
							chatUser			+=  datasetResume?.resume
							chatUser			+=  "I have trained a new objects supervised model with vertex ai, marking the classification mask on each image using a bounding box or several depending on the image."
							chatUser			+=  "For train this model I have used " + (parseInt(currentModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount)+parseInt(currentModel.value.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount) + parseInt(currentModel.value.vertexEvaluation?.evaluations[0]?.evaluatedCount) ) + " total images, " 
							chatUser			+=  currentModel.value.vertexEvaluation?.evaluations[0]?.model.trainingDataItemsCount  +" for training, "
							chatUser			+=  currentModel.value.vertexEvaluation?.evaluations[0]?.model.validationDataItemsCount + " for validation and " 
							chatUser			+=  currentModel.value.vertexEvaluation?.evaluations[0]?.evaluatedCount +" for tests."
							chatUser			+=  "These are the results of the vertex evaluation:" 
							chatUser			+=  "training: budgetMilliNodeHours("+currentModel.value.vertexEvaluation?.evaluations[0]?.model?.training.budgetMilliNodeHours+"), costMilliNodeHours ("+currentModel.value.vertexEvaluation?.evaluations[0]?.model?.training.costMilliNodeHours+") and stopReason ("+currentModel.value.vertexEvaluation?.evaluations[0]?.model?.training.stopReason+")"
							chatUser			+=  ", type: "+ currentModel.value.vertexEvaluation?.evaluations[0]?.model?.type
							chatUser			+=  ", trainingAnnotationsCount: "+ currentModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingAnnotationsCount
							chatUser			+=  ", trainingDataItemsCount: "+ currentModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount
							chatUser			+=  ", validationAnnotationsCount: "+ currentModel.value.vertexEvaluation?.evaluations[0]?.model?.validationAnnotationsCount
							chatUser			+=  ", validationDataItemsCount: "+ currentModel.value.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount
							chatUser			+=  ", with " + Object.keys(currentModel.value.vertexEvaluation?.evaluations[0]?.slices).length +" slices: "
							
							for (let ind in currentModel.value.vertexEvaluation?.evaluations[0]?.slices) {
								chatUser	+=  ind + "("
								chatUser	+=  "boundingBoxMeanAveragePrecision: " + currentModel.value.vertexEvaluation?.evaluations[0]?.slices[ind].boundingBoxMeanAveragePrecision
								chatUser	+=  " and evaluatedBoundingBoxCount: " + currentModel.value.vertexEvaluation?.evaluations[0]?.slices[ind].evaluatedBoundingBoxCount + "), "
							}

							chatUser +=  "What is your evaluation?"
							let chatSystem = "You are chatting with the rosepetal assistant, you are going to be in charge of evaluating the models based on the information from the trained data set and the results obtained. It has to be a technical answer and as precise as possible for the objective of the evaluation, which is to improve the prediction model, to evaluate if another approach is required when training, if another type of training is recommended..."
							
							console.log('ChatUser',chatUser)
							console.log('ChatSystem',chatSystem)

							currentModel.value.descriptionGPT = await $openai.chatWithGPT({ system: chatSystem, user: chatUser })

							if (currentModel.value.descriptionGPT.length) {
								const respGptBox = document.getElementById("respGptBox");
								let i = 0;
								setInterval(() => {
									if (currentModel.value.descriptionGPT && i < currentModel.value.descriptionGPT.length) { 
										respGptBox.innerHTML += currentModel.value.descriptionGPT.charAt(i);
										i++;
									}
								}, 50);
							}
							*/
						}

						/* AWS Model */
						else if (Dataset.value.localModels[ds].aws) {
							semiSupervisedEvaluationObjects.value = [];

							Layout.value.loading	= true;
							currentModel.value		= await $aws.getDataset(Dataset.value.localModels[ds].aws);
							currentModel.value.db	= Dataset.value.localModels[ds];
							Layout.value.loading	= false;

							currentModel.value.awsModel        = await $aws.getModel(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion);
							currentModel.value.packageJobs     = await $aws.getModelPackagingJobs(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion);
							currentModel.value.awsEvaluation   = await $aws.getEvaluation(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion);
						}

						/* Vision Platform Model */
						else if (Dataset.value.localModels[ds].service === 'visionPlatform') {
							currentModel.value = {}
							currentModel.value.db = Dataset.value.localModels[ds];

							//console.log("currentModel:", currentModel.value.db);

							if (currentModel.value.db.status === 'training') {
								Layout.value.loading = false;
								return;
							}

							let modelEvaluation = {
								service:	'visionPlatform',
								status: 	'finished',
								dataDivision: {
									train:		currentModel.value.db.dataDivision.train,
									test:		currentModel.value.db.dataDivision.test,
									validation:	currentModel.value.db.dataDivision.validation
								}, 
								configuration: {
									budget: 	1000,
									cost: 		500,
									stopReason: 'MODEL_CONVERGED',
									createdAt: 	currentModel.value.db.createdAt
								},
								metrics: {
									precision: 	currentModel.value.db.metrics.all.Precision,
									recall:		currentModel.value.db.metrics.all.Recall,
									f1:			currentModel.value.db.metrics.all.F1Score,
								}
							}

							modelEvaluation.render = await $vision.renderEvaluations(currentModel.value.db, { theme: "light", onlyMetrics: true, titles: false });
							currentModel.value.db.evaluation = modelEvaluation;

							Layout.value.loading = false;
						}

						else {
							currentModel.value = { db: Dataset.value.localModels[ds] };
							Layout.value.loading = false;
						}

						break;
					}
				}
			}
		});
  
		watch(() => props.dataset, async () => { 
			currentDataset.value = props.dataset.id; 
			await getDataset(); 
		});

		watch(() => trainVertex.value.method, async () => {
			Dataset.value.validateToTrain = false;
			let validateOpt = { noDivision: trainVertex.value.method == 'auto' ? true : false };
			if (trainVertex.value.tagMap) validateOpt.tagMap = trainVertex.value.tagMap;
			Dataset.value.validateToTrain = await $model.validateToTrain(Dataset.value, validateOpt);
		});
		
		return {
			Layout,
			Dataset,
			currentModel,
			DatasetTagsObj,
			localModelsMenu,
			localModelsNames,
			supervisedTagMap,
			semiSupervisedTagMap,
			semiSupervisedEvaluationObjects,
			semiSupervisedTagMapUseMask,
			clipboard,
			semiSupervisedOpt,
			trainVertex,
			Train,
			modal,
			modalClose,
			modalRemove,
			exportModelToBucket,
			deleteModel,
			modalTrainSupervised,
			trainSupervised,
			refreshTagMapSupervised,
			modalTrainSemiSupervised,
			trainSemiSupervised,
			modalTrainVisionPlatform,
			trainVisionPlatform,
			linkToBrowser
		};
	},
	
	methods:{
		
		refreshBtn: async function() { 
			this.modalRemove("model-semi-supervised-box"); 
			this.modalRemove("model-supervised-box"); 
			this.modalRemove("model-vision-platform-box");
			await this.$parent.manageDataset(this.Dataset.data, 'models'); 
		},
		
		manageTags: async function() { 
			this.modalRemove("model-semi-supervised-box"); 
			this.modalRemove("model-supervised-box");
			this.modalRemove("model-vision-platform-box");
			await this.$parent.manageDataset(this.Dataset.data, 'labels'); 
		},

		refreshTagMapSemiSupervised: async function() {
			const resume = this.semiSupervisedTagMap.resume;
			
			resume.division = {
				normal:		{ test: 0, validation: 0, train: 0 },
				anomaly: 	{ test: 0, validation: 0, train: 0 },
				resume: 	{ test: 0, train: 0 }
			};
			
			resume.normal 			= 0;
			resume.anomaly 			= 0;
			resume.anomalyMask 		= 0;
			resume.anomalyLabels 	= {};

			const processTag = (tag, type) => {
				resume[type] += tag.counter;
				for (const key of ['test', 'validation', 'train']) {
					resume.division[type][key] += tag.division[key];
					if (key !== 'validation') {
						resume.division.resume[key] += tag.division[key];
					}
				}
				resume.division.resume.test += tag.division.validation;

				if (type === 'anomaly') {
					if (tag.mask) resume.anomalyMask += tag.mask;
					if (tag.anomalyLabel) {
						const label = resume.anomalyLabels[tag.anomalyLabel] || { counter: 0, mask: 0 };
						label.counter += tag.counter;
						label.mask += tag.mask || 0;
						resume.anomalyLabels[tag.anomalyLabel] = label;
					}
				}
			};

			this.semiSupervisedTagMap.normal.forEach(tag => processTag(tag, 'normal'));
			this.semiSupervisedTagMap.anomaly.forEach(tag => processTag(tag, 'anomaly'));

			resume.total	= resume.normal + resume.anomaly;
			resume.errors 	= [];

			const validateData = () => {
				const { normal, anomaly } = this.semiSupervisedTagMap;
				const totalClasses = normal.length + anomaly.length;

				if (totalClasses > 0) {
					if (totalClasses < 2) resume.errors.push("You must select at least 2 classes");
					
					if (!normal.length) {
						resume.errors.push("You must select at least 1 normal label");
					} else if (resume.normal < 20) {
						resume.errors.push("You must select at least 20 normal images");
					} else if (resume.division.normal.test + resume.division.normal.validation < 10) {
						resume.errors.push(`You must have at least 10 normal images counting the test and validation images. Now have only ${resume.division.normal.test + resume.division.normal.validation}.`);
					}

					if (!anomaly.length) {
						resume.errors.push("You must select at least 1 anomaly label");
					} else if (resume.anomaly < 20) {
						resume.errors.push("You must select at least 20 anomaly images");
					} else if (resume.division.anomaly.test + resume.division.anomaly.validation < 10) {
						resume.errors.push(`You must have at least 10 anomaly images counting the test and validation images. Now have only ${resume.division.anomaly.test + resume.division.anomaly.validation}.`);
					} else if (this.semiSupervisedTagMapUseMask) {
						Object.entries(resume.anomalyLabels).forEach(([key, value]) => {
							if (value.counter < 20) resume.errors.push(`Select at least 20 images by anomaly label ${key}`);
							if (value.mask < value.counter) {
								resume.errors.push(`All images linked to an anomaly label must have a mask. Anomaly label: ${key} have ${value.mask}/${value.counter} images with annotated mask. Use data labeling tool to add masks.`);
							}
						});
					}
				}
			};

			validateData();

			console.log("Semisupervised tag map:", this.semiSupervisedTagMap);

			if (resume.total) $h.dragElement(document.getElementById("trainingSummayBox"));
		},
  
		loadEvaluationObjects_o: async function(objs) {
			this.currentModel.awsEvaluation.loading           = true;
			if (!this.currentModel.awsEvaluation.sizeObject) this.currentModel.awsEvaluation.sizeObject = 50;
			this.semiSupervisedEvaluationObjects          = [];
			this.currentModel.awsEvaluation.order             = this.currentModel.awsEvaluation.order ? this.currentModel.awsEvaluation.order : "asc";
			this.currentModel.awsEvaluation.filterPrediction  = this.currentModel.awsEvaluation.filterPrediction ? this.currentModel.awsEvaluation.filterPrediction : "all";
			
			if (!this.currentModel.awsEvaluation.oriAwsTestObjects) {
				this.currentModel.awsEvaluation.oriAwsTestObjects = [];
				for (let i=0; i<objs.length; i++) { 
					if (objs[i].key) {
						this.currentModel.awsEvaluation.oriAwsTestObjects.push({ imageSrc: await $aws.getObject(objs[i].key, true), obj: objs[i]  });
					}
				}
			}
			
			let modelTestObj = this.currentModel.awsEvaluation.oriAwsTestObjects;
			
			if (modelTestObj.length) {
				//filter prediction correct/incorrect
				let filterObjects = [];

				for (let i = 0; i < modelTestObj.length; i++) {
					let addImage = true;
					let awsObj   = modelTestObj[i];
					
					if (awsObj?.obj && this.currentModel.awsEvaluation.filterPrediction != "all") {
						if (this.currentModel.awsEvaluation.filterPrediction=="correct"   && awsObj?.obj['anomaly-label']!=awsObj?.obj['anomaly-label-detected']) addImage = false;
						if (this.currentModel.awsEvaluation.filterPrediction=="incorrect" && awsObj?.obj['anomaly-label']==awsObj?.obj['anomaly-label-detected']) { addImage = false; filterObjects.push({ empty: true}); }
					}
					if (addImage) filterObjects.push(awsObj)
				}

				modelTestObj = filterObjects;

				//order
				if (!modelTestObj[0]['empty']) {
					if (this.currentModel.awsEvaluation.order == "asc") modelTestObj.sort((a, b) => a.obj['anomaly-label-detected-metadata']['confidence'] - b.obj['anomaly-label-detected-metadata']['confidence']);
					else modelTestObj.sort((a, b) => b.obj['anomaly-label-detected-metadata']['confidence'] - a.obj['anomaly-label-detected-metadata']['confidence']);
				}
			}

			this.semiSupervisedEvaluationObjects = modelTestObj;
			this.currentModel.awsEvaluation.loading = false;
		},

		loadEvaluationObjects: async function(objs) {
			const awsEvaluation = this.currentModel.awsEvaluation;

			awsEvaluation.loading = true;
			awsEvaluation.sizeObject = awsEvaluation.sizeObject || 50;
			awsEvaluation.order = awsEvaluation.order || "asc";
			awsEvaluation.filterPrediction = awsEvaluation.filterPrediction || "all";

			this.semiSupervisedEvaluationObjects = [];

			if (!awsEvaluation.oriAwsTestObjects) {
				awsEvaluation.oriAwsTestObjects = await this.fetchImagesInBatches(objs, 15); // Batch de 10 imágenes
			}

			let modelTestObj = [...awsEvaluation.oriAwsTestObjects];

			// Filtrar predicciones
			if (awsEvaluation.filterPrediction !== "all") {
				modelTestObj = modelTestObj.filter(({ obj }) => {
					const isCorrect = obj['anomaly-label'] === obj['anomaly-label-detected'];
					return awsEvaluation.filterPrediction === "correct" ? isCorrect : !isCorrect;
				});

				if (awsEvaluation.filterPrediction === "incorrect") {
					modelTestObj.push({ empty: true });
				}
			}

			// Ordenar si hay datos válidos
			if (modelTestObj.length && !modelTestObj[0]?.empty) {
				modelTestObj.sort((a, b) => {
					const confA = a.obj['anomaly-label-detected-metadata']?.confidence || 0;
					const confB = b.obj['anomaly-label-detected-metadata']?.confidence || 0;
					return awsEvaluation.order === "asc" ? confA - confB : confB - confA;
				});
			}

			this.semiSupervisedEvaluationObjects = modelTestObj;
			awsEvaluation.loading = false;
		},

		fetchImagesInBatches: async function (objs, batchSize = 10) {
			const results = [];
			const batches = [];

			for (let i = 0; i < objs.length; i += batchSize) {
				batches.push(objs.slice(i, i + batchSize));
			}

			for (const batch of batches) {
				const batchResults = await Promise.all(
					batch
						.filter(obj => obj.key)
						.map(async obj => ({
							imageSrc: await $aws.getObject(obj.key, true),
							obj
						}))
				);
				results.push(...batchResults);
			}

			return results;
		},

		copyToClipboard: async function() { 
			this.clipboard.on('success', () => { $h.NotificationTxt({ text: "Storage copied to clipboard ", position: "center" });  });
			this.clipboard.on('error', () => {   $h.NotificationTxt({ text: "Storage could not copy text", position: "center" });  });
		},

		removeHyphens: function() {
			this.trainVertex.modelName = this.trainVertex.modelName.replace(/-/g, '_').toLowerCase();
		}
	}
});
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style>
.selConfidence { 
    padding: 10px !important; 
    background-color: #fff8ec !important;
}

.overflow-hidden { 
    overflow: hidden !important; 
}

.disabledButton {
    cursor: not-allowed !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#model-supervised-box, #model-semi-supervised-box, #model-vision-platform-box { 
    background-color: #f2f3f3 !important;
}

#model-supervised-box .modal-dialog, 
#model-semi-supervised-box .modal-dialog,
#model-vision-platform-box .modal-dialog { 
    min-width: 900px !important; 
}

#model-supervised-box .nav.nav-tabs .active, 
#model-semi-supervised-box .nav.nav-tabs .active
#model-vision-platform-box .nav.nav-tabs .active {
    --tw-border-opacity: 1;
    background-color: #1a73e8;
    border-color: #1a73e8;
    font-weight: 500;
    color: #fff;
}
</style>