'use strict';

import _firebase	from './_firebase.js';
import dataset 		from './dataset.js';
import event 		from './event.js';
import helper 		from './helper.js';
import other 		from './other.js';
import project 		from './project.js';

import lzstring 	from 'lz-string';

import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';

import { 
	doc, 
	collection, 
	getDoc, 
	getDocs, 
	deleteDoc, 
	updateDoc,
	addDoc,
	setDoc, 
	query, 
	where, 
	limit, 
	orderBy,
	serverTimestamp,
	getCountFromServer
} from "firebase/firestore";

import { ref, getDownloadURL, getMetadata, uploadBytes } from "firebase/storage";

const image = {
	
	get: async function(imageId) {
		const docRef = doc(_firebase.firestore, "image", imageId);
		let image = {}
		let snapshot = await getDoc(docRef);
		image = snapshot.data();
		if (image) {
			image.id = snapshot.id;
			image.createdDate = helper.getTimestampDate(image.date, 'full');
			image.updatedDate = image["updatedAt"] 
				? helper.getTimestampDate(image["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(image.date, 'full');
		}
		return image;
	},

	getByName: async function(datasetId, imageName) {
		const db = _firebase.firestore;

		const images 		= collection(db, 'image');
		const datasetRef 	= doc(db, 'dataset', datasetId);
		const querySnapshot = await getDocs(query(images, where('dataset', '==', datasetRef), where('name', '==', imageName)));
		
		if (querySnapshot.empty) return null;
		
		let image = querySnapshot.docs[0].data();
		image.id = querySnapshot.docs[0].id;
		
		return image;
	},

	getStorageUri: async function(imageId) {
		let item = await this.get(imageId)
		return { uri: item && item.uri ? item.uri : "NOT FOUND", error: item && item.uri ? false : true };
	},

	getStorageUrl: async function(uri) {
		const storage = _firebase.storage;

		let url = { 
			uri: 	uri, 
			url: 	false, 
			error:	false 
		}

		try {
			let promises = [
				getDownloadURL(ref(storage, uri)), 
				getMetadata(ref(storage, uri))
			];
			let results = await Promise.all(promises);
			url.url		= results[0];
			url.meta 	= results[1];
		} catch (error) { url.error = error }

		return url;
	},

	load: function (blob) {
		return new Promise((resolve) => {
			const image = new Image();
			image.src = URL.createObjectURL(blob);
			image.onload = () => resolve(image);
		});
	},

	delete: async function(imageId) {
		const db = _firebase.firestore;
		await this.deleteStorage(imageId)
		await deleteDoc(doc(db, 'image', imageId)).then(async () => {
			await event.saveEvent('image.delete', { imageId: imageId }, false);
			return { error: false, status: "success" }
		})
	},

	deleteStorage: async function(imageId) {
		let uri = await this.getStorageUri(imageId)
		let config = project.getConfig()
		if (uri.uri) {
			let imageUri = uri.uri.replace(config.modelBucket + "/", "").replace(/\//g, "--")
			await other.httpsCallable('api/image/delete/image/' + imageUri)
		}
	},

	getSet: async function(imageId) {
		let item = await this.get(imageId)
		return item && item.set ? item.set : "PREDETERMINED";
	},

	setSet: async function(imageId, set) {
		const db = _firebase.firestore;
		if (!imageId || !set) return { error: "ImageId and set are required", status: "error" }
		await updateDoc(doc(db, "image", imageId.toString()), { "set": set });
		await event.saveEvent('image.update', { imageId: imageId, set: set }, false);
		return { error: false, status: "success" }
	},

	updateMask: async function(docId, mask, type = false) {
		const db 	= _firebase.firestore;
		let resp 	= { error: false, status: "success" };
		let key 	= type ? "masks" : "mask";

		try {
			await setDoc(doc(db, "image", docId.toString()), { [key]: mask }, { merge: true });
			return resp;
		} catch (e) {
			try {
				mask.imageJson = lzstring.compressToUint8Array(JSON.stringify(mask.imageJson));
				mask.imageJson = new Blob([mask.imageJson], { type: 'application/octet-stream' });
				await setDoc(doc(db, "image", docId.toString()), { "mask": mask }, { merge: true });
				return resp;
			} catch (er) {
				console.log('Error saving mask and compressed mask:', er);
				resp.error = true;
				resp.status = "error";
				return resp;
			}
		}
	},

	uploadMask: async function(opt) {
		try {
			const childPath		= `masks/${opt.datasetId}/${opt.imageName}/${opt.maskName}`;
			const storageRef	= _firebase.getStorage(opt.configPath, childPath);
			
			const response = await fetch(opt.maskSrc);
			const blob = await response.blob();

			await uploadBytes(storageRef, blob, { contentType: 'image/webp' });
	
			const uri = opt.configPath + "/" + childPath;
			const url = await getDownloadURL(storageRef);
			
			return { uri: uri, url: url };
		} catch (err) {
			console.error("Error subiendo máscara:", err);
			throw err;
		}
	},

	getComments: async function(imageId) {
		let item = await this.get(imageId)
		return { imageId: imageId, name: item.name, comments: item && item.comments ? item.comments : "" }
	},

	setComments: async function(imageId, comments) {
		const db = _firebase.firestore;
		if (!imageId) return { error: "ImageId and comments are required", status: "error" }
		await updateDoc(doc(db, "image", imageId.toString()), { "comments": comments });
		await event.saveEvent('image.update', { imageId: imageId, comments: comments }, false);
		return { error: false, status: "success" }
	},

	getTags: async function(imageId) {
		let item = await this.get(imageId)
		let resp = { image: imageId, dataset: null, tags: [] }
		if (item.updatedAt) resp.updatedAt = helper.getTimestampDate(item.updatedAt.toDate(), 'full')
		if (item.tags && item.tags.length) {
			let tagsArr = []; let nameArr = []; let bbArr = []; let colorArr = []
			for (let obt in item.tags) {
				if (item.tags[obt].tag) {
					let tagRef = item.tags[obt].tag.path.toString().split('/')
					if (tagRef[1]) resp.dataset = tagRef[1]
					let tagName = false
					if (tagRef[tagRef.length - 1]) tagName = tagRef[tagRef.length - 1]
					if (tagName) { if (!tagsArr[tagName]) { tagsArr[tagName] = 1 } else { tagsArr[tagName]++; } }
					let tagData = await dataset.getTag(tagRef[1], tagName)
					if (tagData) {
						if (tagData.name && !nameArr[tagName]) nameArr[tagName] = tagData.name
						if (tagData.color && !colorArr[tagName]) colorArr[tagName] = tagData.color
					}
					let bb = {
						h: item.tags[obt].h,
						w: item.tags[obt].w,
						x: item.tags[obt].x,
						y: item.tags[obt].y,
						type: item.tags[obt].type
					}
					if (!bbArr[tagName]) bbArr[tagName] = [];
					bbArr[tagName].push(bb)
				}
			}
			if (Object.keys(tagsArr).length) {
				for (let objectTag in tagsArr) {
					resp.tags.push({ tag: objectTag, name: nameArr[objectTag] ? nameArr[objectTag] : objectTag, color: colorArr[objectTag] ? colorArr[objectTag] : "#000", count: tagsArr[objectTag], bounding_box: bbArr[objectTag] ? bbArr[objectTag] : [] });
				}
			} else { resp.response = "IMAGE NOT LABELED" }
			return resp
		} else {
			if (item.tag) {
				let tagName = item.tag.path.toString().split('/')
				let tags = []
				let tagData = await dataset.getTag(tagName[1], tagName[3])
				tags.push({ tag: tagName[3], name: tagData.name, color: tagData.color, count: 1 })
				resp.dataset = tagName[1]
				resp.tags = tags
				return resp
			} else { resp.response = "IMAGE NOT LABELED"; return resp }
		}
	},

	setTag: async function(imageId, datasetID, tagID) {
		let tagRef = doc(_firebase.firestore, "dataset", datasetID.toString(), 'tag', tagID.toString());
		let data = { tag: tagRef, updatedAt: serverTimestamp() };
		await updateDoc(doc(_firebase.firestore, "image", imageId.toString()), data);
	},

	setTags: async function(imageId, tags, tagsContained) {
		await updateDoc(doc(_firebase.firestore, "image", imageId.toString()), { "tags": tags, "tagsContained": tagsContained, updatedAt: serverTimestamp() });
	},

	removeTags: async function(imageId) {
		const db = _firebase.firestore;
		let resp = { status: "error", error: false }
		if (imageId) {
			resp.image = imageId
			let item = await this.get(imageId)
			if (item.dataset) {
				let _dataset = item.dataset.path.toString().split('/')
				if (_dataset[1]) {
					let datasetRef = doc(db, "dataset", _dataset[1].toString(), 'tag', "0")
					await updateDoc(doc(db, "image", imageId), { tag: datasetRef, tags: [], tagsContained: [], updatedAt: serverTimestamp() });
					resp.status = "success"
					resp.currentTags = await this.getTags(imageId)
				} else { resp.error = "image is not associated with any dataset" }
			}
		} else { resp.error = "image id is required" }
		return resp
	},

	previewB64: async function(imageId) {
		let resp = { error: false, status: "error" }
		if (imageId) {
			let image = await this.get(imageId)
			if (image.imageData) {
				resp.image = imageId
				resp.b64 = 'data:image/png;base64,' + image.imageData.toBase64()
				resp.status = "success"
			} else { resp.error = "Image not available in base64 (imageData)" }
		} else { resp.error = "image id is required" }
		return resp
	},

	getb64: async function(Url) {
		try {
			const response = await fetch(Url);
			const buffer = await response.arrayBuffer();
			return 'data:image/png;base64,' + helper.arrayBufferToBase64(buffer);
		} catch (error) {
			throw error;
		}
	},

	navControls: async function(imageId, datasetType, opt = {}) {
		let resp = { status: "success", error: false }
		let { prev, next } = await this.getAdjacent(imageId, datasetType, opt);
		resp.prev = prev;
		resp.next = next;
		return resp;
	},

	getPrev: async function(imageId, opt = {}) {
		const db 		= _firebase.firestore;
		let prev 		= false;
		let item 		= await this.get(imageId);
		let images 		= collection(db, 'image');
		let datasetId 	= item.dataset.path.toString().split("/").pop();
		let dsRef		= doc(db, "dataset", datasetId);
		let order 		= "date";
		
		if (opt.order) order = opt.order;

		images = query(images, where("dataset", "==", dsRef), where("date", ">=", item.date));
		
		if (order == 'tag' && opt.tag) {
			let _tagRef = doc(db, "dataset", datasetId, 'tag', opt.tag.toString());
			images = query(images, where('tag', '==', _tagRef));
		}

		images = query(images, orderBy("date", "asc"), limit(2));

		await getDocs(images).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (!prev && doc.id != imageId) prev = doc.id;
			});
		});

		return prev;
	},

	getNext: async function(imageId, opt = {}) {
		const db 		= _firebase.firestore;
		let next 		= false;
		let item 		= await this.get(imageId);
		let images 		= collection(db, 'image');
		let datasetId	= item.dataset.path.toString().split("/").pop();
		let dsRef 		= doc(db, "dataset", datasetId);
		let order 		= "date";

		if (opt.order) order = opt.order;

		images = query(images, where("dataset", "==", dsRef), where("date", "<=", item.date));

		if (order == 'tag' && opt.tag) {
			let _tagRef = doc(db, "dataset", datasetId, 'tag', opt.tag.toString());
			images = query(images, where('tag', '==', _tagRef));
		}

		images = query(images, orderBy("date", "desc"), limit(2));

		await getDocs(images).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (!next && doc.id != imageId) next = doc.id;
			});
		});

		return next;
	},

	getAdjacent: async function(image, datasetType, opt = {}) {
		const db		= _firebase.firestore;
		let images 		= collection(db, 'image');
		let datasetId 	= image.dataset.id;
		let dsRef 		= doc(db, "dataset", datasetId);

		let imagesQuery = query(images, where("dataset", "==", dsRef));
		let prevQuery, nextQuery;

		if (opt.order === 'date' || opt.order === 'updatedAt') {
			let order = opt.order;
			let direction = opt.direction === 'desc' ? 'asc' : 'desc';
			prevQuery = query(imagesQuery, where(order, ">", image[order]), orderBy(order, direction), limit(1));
			nextQuery = query(imagesQuery, where(order, "<", image[order]), orderBy(order, opt.direction), limit(1));
		} 
		
		else if (opt.order === 'tag' && opt.tag) {
			let tagRef = doc(db, "dataset", datasetId, "tag", opt.tag);
			let field = datasetType == 'MULTICLASS' ? 'tag' : 'tagsContained';
			prevQuery = query(imagesQuery, where(field, '==', tagRef), where("date", ">", image.date), orderBy('date', 'asc'), limit(1));
			nextQuery = query(imagesQuery, where(field, '==', tagRef), where("date", "<", image.date), orderBy('date', 'desc'), limit(1));
		}

		let prev = await getDocs(prevQuery).then((querySnapshot) => { 
			if (querySnapshot.empty) return null;
			let doc		= querySnapshot.docs[0];
			let data 	= doc.data();
			data.id 	= doc.id;

			data.createdDate = helper.getTimestampDate(data.date, 'full');
			data.updatedDate = data["updatedAt"] 
				? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(data.date, 'full');

			return data;
		});

		let next = await getDocs(nextQuery).then((querySnapshot) => { 
			if (querySnapshot.empty) return null;
			let doc 	= querySnapshot.docs[0];
			let data 	= doc.data();
			data.id 	= doc.id;

			data.createdDate = helper.getTimestampDate(data.date, 'full');
			data.updatedDate = data["updatedAt"] 
				? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(data.date, 'full');

			return data;
		});

		return { prev, next };
	},

	getDimensions: async function(url) {
		return new Promise((resolve, reject) => {
			let img = new Image()
			img.crossOrigin = "anonymous"
			img.setAttribute('crossOrigin', 'anonymous');
			img.src = url
			img.onload = async (i) => { resolve({ width: i.target.width, height: i.target.height }) }
			img.onerror = reject
		})
	},

	updateCanvasIds: function(canvasList, tag, tags) {
		let canvasId = "MULTI-" + tag.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
		let isCanvasIdInUse = canvasList.some(obj => obj.id === canvasId);

		if (!isCanvasIdInUse) {
			const canvasObj = {
				id:     canvasId,
				stroke: tags[tag].color,
				name:   tag,
				type:   "path"
			};

			canvasList.push(canvasObj);
		}

		return canvasId;
	},

	saveSegmentationImage: async function (img, data, opt = {}) {
		let saveError = false;
		
		const masksData = { objects: [] };

		if (data.length) {
			const groupedMasks = data.reduce((masks, item) => {
				if (item.name) {
					masks[item.name] = masks[item.name] || [];
					masks[item.name].push(item);
				}
				return masks;
			}, {});

			const maskPromises = Object.entries(groupedMasks).map(async ([maskName, maskObjs]) => {
				const maskCanvas = new fabric.Canvas();
				maskCanvas.set({ width: img.width, height: img.height });
				
				await Promise.all(maskObjs.map(obj => {
					return new Promise(resolve => {
						const objJSON = obj.toObject([
							'path',
							'strokeWidth',
							'_isEraserPath',
							'eraserPath', 
							'globalCompositeOperation',
							'name',
							'stroke',
							'type',
							'uuid',
							'uuid_parent',
							'objid',
							'scaleX',
							'scaleY',
							'left',
							'top',
							'pathOffset'
						]);
						
						if (obj.type === 'path') {
							objJSON.scaleX = obj.scaleX || 1;
							objJSON.scaleY = obj.scaleY || 1;
							objJSON.left = obj.left || 0;
							objJSON.top = obj.top || 0;
							objJSON.strokeWidth = obj.strokeWidth;
							
							if (obj._isEraserPath || obj.globalCompositeOperation === 'destination-out') {
								objJSON._isEraserPath = true;
								objJSON.globalCompositeOperation = 'destination-out';
							}
						}
						
						fabric.util.enlivenObjects([objJSON], function(enlivenedObjects) {
							const clonedObj = enlivenedObjects[0];
							maskCanvas.add(clonedObj);
							resolve();
						}, 'fabric');
					});
				}));
				
				maskCanvas.getObjects().forEach(obj => {
					if (obj._isEraserPath || obj.eraserPath || obj.globalCompositeOperation === 'destination-out') {
						maskCanvas.bringToFront(obj);
					}
				});
				
				const canvasJSON = maskCanvas.toJSON([
					'path',
					'strokeWidth',
					'_isEraserPath',
					'eraserPath', 
					'globalCompositeOperation',
					'name',
					'stroke',
					'type',
					'uuid',
					'uuid_parent',
					'objid',
					'scaleX',
					'scaleY',
					'left',
					'top',
					'pathOffset'
				]);
				
				const maskSrc = await helper.convertPNGtoWebp(maskCanvas.toDataURL());
				
				const maskObj = {
					name: maskName,
					stroke: maskObjs[0].stroke,
					canvasJSON: JSON.stringify(canvasJSON)
				};
				
				const maskOpt = {
					configPath: 'gs://' + opt.projectId,
					imageName: opt.imageName,
					datasetId: opt.datasetId,
					maskName: maskName,
					maskSrc: maskSrc
				};

				const { uri, url } = await this.uploadMask(maskOpt);
				maskObj.uri = uri;
				maskObj.url = url;

				masksData.objects.push(maskObj);
			});

			await Promise.all(maskPromises);

			const resp = await this.updateMask(opt.imageId, masksData, 'masks');
			if (resp.error) saveError = true;
		} else {
			const resp = await this.updateMask(opt.imageId, {}, 'masks');
			if (resp.error) saveError = true;
		}

		await this.setTag(opt.imageId, opt.datasetId, opt.tagId);

		const multiObjs = [];
		const multiObjsContained = [];

		const tagsPromises = data.map(async mask => {
			if (mask.name) {
				const multiObjTag = doc(_firebase.firestore, "dataset", opt.datasetId, 'tag', mask.name);
				const newPathOpt = {
					tag: multiObjTag,
					name: mask.name || false,
					type: mask.elementType
				};
				multiObjs.push(newPathOpt);
				multiObjsContained.push(multiObjTag);
			}
		});

		await Promise.all(tagsPromises);
		await this.setTags(opt.imageId, multiObjs, multiObjsContained);

		return saveError;
	},

	upload: async function (opt = false) {
		const db = _firebase.firestore;

		const imagesRef		= collection(db, 'image');
		const datasetRef 	= doc(db, 'dataset', opt.datasetID);
		const tagRef 		= doc(db, 'dataset', opt.datasetID, 'tag', '0');
	
		const extension 	= opt.fileName.split('.').pop().toLowerCase();
		const configPath 	= opt.configPath;
		const childPath 	= `${opt.childPath}/${opt.fileName}`;
	
		const snapImage = await getDocs(query(imagesRef, where('dataset', '==', datasetRef), where('name', '==', opt.fileName)));
		if (snapImage.docs.length) return snapImage.docs[0].id;
	
		try {
			const originalBlob	= await opt.file.async('blob');
			const baseFileName 	= opt.fileName.replace(/\.[^/.]+$/, "");
	
			const reducedChildPath	= `${opt.childPath}/${baseFileName}-reduced.jpeg`;
			const lowResChildPath 	= `${opt.childPath}/${baseFileName}-lowResolution.jpeg`;
	
			const { originalUrl, reducedUrl, lowResUrl, previewUrl } = await this.processImages(originalBlob, configPath, childPath, reducedChildPath, lowResChildPath, extension);
	
			const newImageRef = doc(imagesRef);
			await setDoc(newImageRef, {
				dataset:    datasetRef,
				date:       Date.now(),
				imageData:  { originalImg: originalUrl, lowResImg: lowResUrl, previewImg: previewUrl, reducedImg: reducedUrl },
				name:       opt.fileName,
				set:        "PREDETERMINED",
				tag:        tagRef,
				updatedAt:  serverTimestamp(),
				uri:        `${configPath}/${childPath}`,
			});
			// Se retorna el id del documento recién creado
			return newImageRef.id;
		} catch (err) {
			console.error('Error uploading', opt.fileName, err);
		}
	},

	uploadAnnotations: async function (annotations, dsData) {
		Object.entries(annotations).forEach(async ([imgName, data]) => {
			const image = await this.getByName(dsData.id, imgName);
			if (image && image.id) {
				const tagRefs = await Promise.all(
					data.tags.map(async (tag) => {
						const tagRef = doc(_firebase.firestore, 'dataset', dsData.id, 'tag', tag.type);
						const docSnap = await getDoc(tagRef);
						if (!docSnap.exists()) await dataset.createTag(dsData.id, { tag: tag.type, name: tag.type, unclassified: false });
						return {
							labeled:	'inference',
							tag: 		tagRef,
							type: 		'rect',
							x: 			tag.data[0],
							y: 			tag.data[1],
							w: 			tag.data[2] - tag.data[0],
							h: 			tag.data[3] - tag.data[1],
						};
					})
				);
				await this.setTags(image.id, tagRefs, tagRefs.map(t => t.tag));
			}
		});
	},
	
	processImages: async function (blob, configPath, childPath, reducedChildPath, lowResChildPath, extension) {
		const image = await this.load(blob);
	
		const originalUploadRef = _firebase.getStorage(configPath, childPath);
		await uploadBytes(originalUploadRef, blob, { contentType: `image/${extension}` });
		const originalUrl = await getDownloadURL(originalUploadRef);
	
		const lowResBlob = await this.createCompressedBlob(image, 0.1);
		const lowResUploadRef = _firebase.getStorage(configPath, lowResChildPath);
		await uploadBytes(lowResUploadRef, lowResBlob, { contentType: 'image/jpeg' });
		const lowResUrl = await getDownloadURL(lowResUploadRef);
	
		let reducedUrl = "";
		if (extension !== 'jpg' && extension !== 'jpeg') {
			const reducedBlob = await this.createCompressedBlob(image, 0.8);
			const reducedUploadRef = _firebase.getStorage(configPath, reducedChildPath);
			await uploadBytes(reducedUploadRef, reducedBlob, { contentType: 'image/jpeg' });
			reducedUrl = await getDownloadURL(reducedUploadRef);
		}
	
		const previewBlob = await this.createCompressedBlob(image, 0.3, 300);
		const previewUrl = await this.blobToBase64(previewBlob);

		return { originalUrl, reducedUrl, lowResUrl, previewUrl };
	},
	
	createCompressedBlob: function (image, quality, width = image.width) {
		return new Promise((resolve) => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const scaleFactor = width / image.width;
			canvas.width = width;
			canvas.height = image.height * scaleFactor;
			ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
			canvas.toBlob(resolve, 'image/jpeg', quality);
		});
	},
	
	blobToBase64: function (blob) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	},

	getImageCount: async function(datasetId, filters = {}) {
		const db = _firebase.firestore;
		const datasetRef = doc(db, 'dataset', datasetId);
		let imagesQuery = collection(db, 'image');
		imagesQuery = query(imagesQuery, where('dataset', '==', datasetRef));
		
		if (filters.order && (filters.order === 'date' || filters.order === 'updatedAt')) {
			const direction = filters.direction || 'desc';
			imagesQuery = query(imagesQuery, orderBy(filters.order, direction));
		}
		
		if (filters.tagId) {
			if (filters.datasetType === 'MULTICLASS') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tag', '==', tagRef));
			} else if (filters.datasetType === 'MULTILABEL' || filters.datasetType === 'imageObjectDetection') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tagsContained', 'array-contains', tagRef));
			}
		}
		
		if (filters.set) {
			imagesQuery = query(imagesQuery, where('set', '==', filters.set.toUpperCase()));
		}
		
		const totalImages = await getCountFromServer(imagesQuery);
		return totalImages.data().count;
	},
	
	getImageByIndex: async function(datasetId, index, filters = {}) {
		if (index < 1) return null;
		
		const db = _firebase.firestore;
		const datasetRef = doc(db, 'dataset', datasetId);
		let imagesQuery = collection(db, 'image');
		imagesQuery = query(imagesQuery, where('dataset', '==', datasetRef));
		
		const order = filters.order || 'date';
		const direction = filters.direction || 'desc';
		
		if (filters.tagId) {
			if (filters.datasetType === 'MULTICLASS') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tag', '==', tagRef));
			} else if (filters.datasetType === 'MULTILABEL' || filters.datasetType === 'imageObjectDetection') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tagsContained', 'array-contains', tagRef));
			}
		}
		
		if (filters.set) {
			imagesQuery = query(imagesQuery, where('set', '==', filters.set.toUpperCase()));
		}
		
		imagesQuery = query(imagesQuery, orderBy(order, direction));
		
		if (index > 1) {
			imagesQuery = query(imagesQuery, limit(index));
			const snapshot = await getDocs(imagesQuery);
			
			if (snapshot.empty || snapshot.docs.length < index) return null;
			
			const doc = snapshot.docs[snapshot.docs.length - 1];
			const imageData = doc.data();
			imageData.id = doc.id;
			imageData.createdDate = helper.getTimestampDate(imageData.date, 'full');
			imageData.updatedDate = imageData.updatedAt 
				? helper.getTimestampDate(imageData.updatedAt.toDate(), 'full') 
				: helper.getTimestampDate(imageData.date, 'full');
			
			return imageData;
		} else {
			imagesQuery = query(imagesQuery, limit(1));
			const snapshot = await getDocs(imagesQuery);
			
			if (snapshot.empty) return null;
			
			const doc = snapshot.docs[0];
			const imageData = doc.data();
			imageData.id = doc.id;
			imageData.createdDate = helper.getTimestampDate(imageData.date, 'full');
			imageData.updatedDate = imageData.updatedAt 
				? helper.getTimestampDate(imageData.updatedAt.toDate(), 'full') 
				: helper.getTimestampDate(imageData.date, 'full');
			
			return imageData;
		}
	},
	
	getImagesAtIndexRange: async function(datasetId, startIndex, endIndex, filters = {}) {
		if (startIndex < 1 || endIndex < startIndex) return [];
		
		const db = _firebase.firestore;
		const datasetRef = doc(db, 'dataset', datasetId);
		let imagesQuery = collection(db, 'image');
		imagesQuery = query(imagesQuery, where('dataset', '==', datasetRef));
		
		const order = filters.order || 'date';
		const direction = filters.direction || 'desc';
		
		if (filters.tagId) {
			if (filters.datasetType === 'MULTICLASS') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tag', '==', tagRef));
			} else if (filters.datasetType === 'MULTILABEL' || filters.datasetType === 'imageObjectDetection') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tagsContained', 'array-contains', tagRef));
			}
		}
		
		if (filters.set) {
			imagesQuery = query(imagesQuery, where('set', '==', filters.set.toUpperCase()));
		}
		
		imagesQuery = query(imagesQuery, orderBy(order, direction), limit(endIndex));
		const snapshot = await getDocs(imagesQuery);
		
		if (snapshot.empty) return [];
		
		const images = [];
		const startIdx = Math.max(0, startIndex - 1);
		const endIdx = Math.min(snapshot.docs.length, endIndex);
		
		for (let i = startIdx; i < endIdx; i++) {
			const doc = snapshot.docs[i];
			const imageData = doc.data();
			imageData.id = doc.id;
			imageData.createdDate = helper.getTimestampDate(imageData.date, 'full');
			imageData.updatedDate = imageData.updatedAt 
				? helper.getTimestampDate(imageData.updatedAt.toDate(), 'full') 
				: helper.getTimestampDate(imageData.date, 'full');
			
			images.push(imageData);
		}
		
		return images;
	},
	
	getImageIndex: async function(imageId, datasetId, filters = {}) {
		const db = _firebase.firestore;
		const datasetRef = doc(db, 'dataset', datasetId);
		let imagesQuery = collection(db, 'image');
		imagesQuery = query(imagesQuery, where('dataset', '==', datasetRef));
		
		const order = filters.order || 'date';
		const direction = filters.direction || 'desc';
		
		if (filters.tagId) {
			if (filters.datasetType === 'MULTICLASS') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tag', '==', tagRef));
			} else if (filters.datasetType === 'MULTILABEL' || filters.datasetType === 'imageObjectDetection') {
				const tagRef = doc(db, 'dataset', datasetId, 'tag', filters.tagId);
				imagesQuery = query(imagesQuery, where('tagsContained', 'array-contains', tagRef));
			}
		}
		
		if (filters.set) {
			imagesQuery = query(imagesQuery, where('set', '==', filters.set.toUpperCase()));
		}
		
		imagesQuery = query(imagesQuery, orderBy(order, direction));
		const snapshot = await getDocs(imagesQuery);
		
		if (snapshot.empty) return -1;
		
		const index = snapshot.docs.findIndex(doc => doc.id === imageId);
		return index !== -1 ? index + 1 : -1;
	},
	
	directNavigateToIndex: async function(currentImageId, targetIndex, datasetId, filters = {}) {
		const totalCount = await this.getImageCount(datasetId, filters);
		
		if (targetIndex < 1 || targetIndex > totalCount) {
			return { success: false, error: 'Index out of bounds' };
		}
		
		const currentIndex = await this.getImageIndex(currentImageId, datasetId, filters);
		
		if (currentIndex === targetIndex) {
			return { success: true, image: await this.get(currentImageId), currentIndex: targetIndex, totalCount };
		}
		
		const targetImage = await this.getImageByIndex(datasetId, targetIndex, filters);
		
		if (!targetImage) {
			return { success: false, error: 'Failed to fetch target image' };
		}
		
		const prevNextOpt = { 
			prevLimit: 1, 
			nextLimit: 1, 
			order: filters.order || 'date', 
			direction: filters.direction || 'desc' 
		};
		
		const adjacentImages = await this.getAdjacent(targetImage, filters.datasetType || 'MULTICLASS', prevNextOpt);
		
		return {
			success: true,
			image: targetImage,
			prev: adjacentImages.prev,
			next: adjacentImages.next,
			currentIndex: targetIndex,
			totalCount
		};
	},
	
	loadAdjacentBatch: async function(currentImageId, datasetId, filters = {}, batchSize = 5) {
		const currentIndex = await this.getImageIndex(currentImageId, datasetId, filters);
		
		if (currentIndex === -1) {
			return { success: false, error: 'Current image not found' };
		}
		
		const totalCount = await this.getImageCount(datasetId, filters);
		const halfBatch = Math.floor(batchSize / 2);
		
		const startIndex = Math.max(1, currentIndex - halfBatch);
		const endIndex = Math.min(totalCount, currentIndex + halfBatch);
		
		const images = await this.getImagesAtIndexRange(datasetId, startIndex, endIndex, filters);
		const centerImageIndex = images.findIndex(img => img.id === currentImageId);
		
		const batch = {
			success: true,
			totalCount,
			currentIndex,
			images: images,
			currentImagePosition: centerImageIndex,
			startIndex,
			endIndex
		};
		
		return batch;
	},
	
	loadImageResources: async function(image, context = 'editor') {
		if (!image) return null;
		
		try {
			const gimageObj = await this.getStorageUrl(image.uri);
			if (!gimageObj || gimageObj.error) { 
				return null;
			}

			let imageUrl;
			if (context === 'navigator') {
				imageUrl = image.imageData.lowResImg || gimageObj.url;
			} else {
				imageUrl = image.imageData.reducedImg || gimageObj.url;
			}
			
			image.gimageObj = gimageObj;
			image.imageUrl = imageUrl;
			
			return image;
		} catch (error) {
			return null;
		}
	},

	getNavigationContext_o: async function(imageId, datasetId, filters = {}, cacheSize = 5) {
		const currentIndex = await this.getImageIndex(imageId, datasetId, filters);
		
		if (currentIndex === -1) {
			return { success: false, error: 'Current image not found' };
		}
		
		const totalCount = await this.getImageCount(datasetId, filters);

		const halfCache		= Math.floor(cacheSize / 2);
		const startIndex 	= Math.max(1, currentIndex - halfCache);
		const endIndex 		= Math.min(totalCount, currentIndex + halfCache);
		
		const images = await this.getImagesAtIndexRange(datasetId, startIndex, endIndex, filters);
		const centerImageIndex = images.findIndex(img => img.id === imageId);
		
		if (centerImageIndex === -1) {
			return { success: false, error: 'Current image not in result set' };
		}
		
		const centerImage = images[centerImageIndex];
		const prev = centerImageIndex > 0 ? images[centerImageIndex - 1] : null;
		const next = centerImageIndex < images.length - 1 ? images[centerImageIndex + 1] : null;
		
		const cacheStructure = [];
		for (let i = 0; i < cacheSize; i++) {
			const imageIndex = centerImageIndex - halfCache + i;
			cacheStructure.push(imageIndex >= 0 && imageIndex < images.length ? images[imageIndex] : false);
		}
		
		return {
			success: true,
			currentImage: centerImage,
			prev: prev,
			next: next,
			currentIndex,
			totalCount,
			cacheStructure,
			allImages: images
		};
	},

	getNavigationContext: async function(imageId, imagesIds) {
		const cacheSize 	= 5;
		const totalCount 	= imagesIds.length;
		const currentIndex	= imagesIds.indexOf(imageId);
	
		if (currentIndex === -1) {
			return { success: false, error: 'Current image not found' };
		}
	
		const halfCache		= Math.floor(cacheSize / 2);
		const startIndex 	= Math.max(0, currentIndex - halfCache);
		const endIndex 		= Math.min(totalCount - 1, currentIndex + halfCache);
	
		const images = await Promise.all(
			imagesIds.slice(startIndex, endIndex + 1).map(id => this.get(id))
		);
	
		const centerImageIndex = currentIndex - startIndex;
	
		const cacheStructure = Array.from({ length: cacheSize }, (_, i) => {
			const imageIndex = centerImageIndex - halfCache + i;
			return images[imageIndex] || false;
		});

		console.log("Cache structure:", cacheStructure);
	
		return {
			success: true,
			currentIndex,
			cacheStructure,
			prev: images[centerImageIndex - 1] || null,
			next: images[centerImageIndex + 1] || null
		};
	}
}

export default image;